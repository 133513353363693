import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';
import { useURLSearchQuery } from 'modules/Core/Hooks';
import { TableActions, TableAction } from 'modules/Core/Common';

const Actions = ({ id, category_tag, onAiEvaluate }) => {
  const { pathname } = useLocation();
  const { queryParams } = useURLSearchQuery();

  const isReadingOrListening =
    category_tag?.toLowerCase() === 'reading' ||
    category_tag?.toLowerCase() === 'listening';

  const submissionAnswersRoute = React.useMemo(() => {
    return `${pathname}/${id}?${decodeURIComponent(queryParams)}`.replace(
      '//',
      '/'
    );
  }, []);

  if (isReadingOrListening) return null;

  return (
    <TableActions>
      {!!onAiEvaluate && (
        <TableAction
          iconName="robot"
          tooltip={i18n.t('commons.buttons.actions-buttons.ai-evaluate')}
          isConfirm
          confirmSubtitle={i18n.t('modules.submissions.alerts.ai-evaluate')}
          onClick={() => onAiEvaluate(id)}
        />
      )}
      <TableAction
        iconName="edit"
        tooltip={i18n.t('commons.buttons.actions-buttons.edit')}
        href={submissionAnswersRoute}
      />
    </TableActions>
  );
};

Actions.propTypes = {
  testType: PropTypes.string,
  id: PropTypes.number,
  category_tag: PropTypes.string,
  onAiEvaluate: PropTypes.func,
};

export default Actions;
