import styled from 'styled-components';

export const Container = styled.div`
  padding: 3.2rem 0 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    margin: 2.4rem 5.8rem 0.8rem;
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.quartenary};
    font-size: 2rem;
  }

  p {
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.default};

    span {
      font-weight: bold;
      color: ${(props) => props.theme.config.colors.primary};
    }
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: ${(props) => props.theme.config.colors.primary};
    cursor: pointer;
    ${(props) => props.theme.config.typograph.mico};

    svg {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;

export const IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 0.6rem solid ${(props) => props.theme.config.colors.gray_200};
  border-top-color: ${(props) => props.theme.config.colors.primary};
  transform: rotate(45deg);

  svg {
    transform: rotate(-45deg);

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const Separator = styled.div`
  width: 39.1rem;
  height: 1px;
  background-color: ${(props) => props.theme.config.colors.gray_200};
  margin: 3.2rem auto 2.4rem;
`;

export const ButtonClose = styled.button`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;

  svg {
    width: 1.6rem;
    height: 1.6rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_400};
    }
  }
`;
