import PropTypes from 'prop-types';
import i18n from 'i18next';
import { capitalize } from 'lodash';

import {
  ItemsColumn,
  ItemLabel,
  ItemLink,
  Evaluator,
  BulletLabel,
} from 'modules/Core/Common';
import { AnswersHeader } from 'modules/Submissions/Common/Answers';
import { I18N_PREFIX } from 'modules/Core/Common/HeaderInfo/HeaderInfo.utils';

export function QuestionsHeader({
  loading,
  student,
  evaluator,
  id,
  date,
  category,
  status,
  duration,
  gradingRubric,
}) {
  return (
    <AnswersHeader
      loading={loading}
      student={student}
      id={id}
      date={date}
      dateLabel={i18n.t(`${I18N_PREFIX}.response-date`)}
      testCategory={category}
    >
      <ItemsColumn>
        <ItemLabel title={i18n.t(`${I18N_PREFIX}.category`)}>
          {capitalize(category)}
        </ItemLabel>
        <ItemLabel title={i18n.t(`${I18N_PREFIX}.status`)}>
          <BulletLabel variant={status ? 'primary' : 'orange'}>
            {status
              ? i18n.t(`${I18N_PREFIX}.completed`)
              : i18n.t(`${I18N_PREFIX}.waiting-evaluation`)}
          </BulletLabel>
        </ItemLabel>
        <ItemLabel title={i18n.t(`${I18N_PREFIX}.time-spent`)}>
          {duration}
        </ItemLabel>
      </ItemsColumn>
      {!!gradingRubric && (
        <ItemsColumn>
          <ItemLink
            icon="url"
            href={gradingRubric}
            title={i18n.t(`${I18N_PREFIX}.grading-rubric`)}
          />
        </ItemsColumn>
      )}
      <ItemsColumn>
        <Evaluator evaluator={evaluator} />
      </ItemsColumn>
    </AnswersHeader>
  );
}

QuestionsHeader.propTypes = {
  loading: PropTypes.bool,
  student: PropTypes.object,
  evaluator: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  category: PropTypes.string,
  status: PropTypes.bool,
  duration: PropTypes.string,
  gradingRubric: PropTypes.string,
};
