import styled, { keyframes } from 'styled-components';
import { Row } from 'modules/Core/Common';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedTr = styled.tr`
  animation: 0.2s ${slideDown} ease-in-out;

  td {
    padding: 1.4rem 2.4rem !important;
  }
`;

export const LicensesContainer = styled.td`
  text-align: left !important;
  background-color: ${(props) => props.theme.config.colors.secondary};

  label {
    color: ${(props) => props.theme.config.colors.light};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;

  select {
    width: 200px;
    height: 32px;
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};
    color: ${(props) => props.theme.config.colors.text_base};
    border: 1px solid ${(props) => props.theme.config.colors.gray_400};
    ${(props) => props.theme.config.typograph.xsmall};
    border-radius: 0.4rem;
    font-weight: bold;
    padding: 0 2rem 0 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    option:disabled {
      color: ${(props) => props.theme.config.colors.light};
    }
  }

  button {
    ${(props) => props.theme.config.typograph.xsmall};
    border-radius: 4px;
    height: 32px;
    padding: 0.8rem 1.5rem;
    margin-top: 3.2rem;
  }
`;

export const Select = styled.div`
  width: 200px;
  margin-right: 10px;

  .toggl-input {
    min-height: 32px;
    border-radius: 4px;

    div {
      color: ${(props) => props.theme.config.colors.text_base};
      ${(props) => props.theme.config.typograph.xsmall};
      font-weight: bold;
    }
  }
`;

export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
`;

export const LicenseSection = styled.ul`
  padding-right: ${(props) => props.theme.config.spacing.spacing_2};
  max-height: 18rem;
  overflow-y: auto;
  height: 15rem;
  position: relative;
  margin: 0 5.2rem;

  &::-webkit-scrollbar {
    background: ${(props) => props.theme.config.colors.primary_700};
    border-radius: 0.8rem;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.primary};
    border-radius: 0.8rem;
    width: 5px;
  }
`;

export const LicensesList = styled.ul`
  padding-right: ${(props) => props.theme.config.spacing.spacing_2};
`;

export const LicensesListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.primary_700};
  color: white;
  padding: 0.4rem 1.2rem 0.4rem 1.6rem;
  border-radius: 0.4rem;
  max-width: 35.8rem;

  & + li {
    margin-top: ${(props) => props.theme.config.spacing.spacing_1};
  }
`;

export const LicensesNumberItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.primary_700};
  color: white;
  padding: 0.4rem 1.2rem 0.4rem 1.6rem;
  border-radius: 0.4rem;
  width: 18rem;
  height: 3.2rem;

  & + li {
    margin-top: ${(props) => props.theme.config.spacing.spacing_1};
  }
`;

export const LicenseTitle = styled.h6`
  ${(props) => props.theme.config.typograph.xsmall};
  max-width: 26rem;
`;

export const LicenseActions = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  button {
    svg {
      width: 1.4rem;
    }
  }

  button + button {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;

export const LicenseRow = styled(Row)`
  position: relative;
  flex-wrap: inherit;

  &&& {
    justify-content: flex-start;
    float: none;
    padding: 1rem;
    z-index: auto;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: calc(50% - 0.6rem);
    transform: translate(-50%, -50%);
    width: calc(100% + 3.2rem);
    height: calc(100% + 4rem);
    background: ${(props) => props.theme.config.colors.secondary};
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
`;

export const LicenseCol = styled.div`
  display: inline-block;

  & + div {
    margin-left: ${(props) => props.theme.config.spacing.layout_5};
    width: 100%;
    max-width: fit-content;
  }
`;

export const NoTestsAvailable = styled.p`
  ${(props) => props.theme.config.typograph.xsmall};
  color: white;
`;
