import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../../AnswerSheet.styles';
import { getAlternativeByCondition } from './Summary.types';

const Summary = ({ alternatives }) => {
  return alternatives?.map((alternative, index) => (
    <S.AlternativeContainer
      key={alternative.alternative_id}
      rightSoft={alternative.right && alternative.chosen_by_user}
      wrongSoft={!alternative.right && alternative.chosen_by_user}
    >
      {getAlternativeByCondition(alternative, index)}
      <S.AlternativeText
        dangerouslySetInnerHTML={{
          __html: alternative?.alternative_description,
        }}
      />
    </S.AlternativeContainer>
  ));
};

Summary.propTypes = {
  alternatives: PropTypes.array,
};

export default Summary;
