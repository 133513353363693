import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Button } from 'modules/Core/Common';
import { Score } from 'modules/Submissions/Common';
import { SumsOverallAveragesPlaceholder } from './SumsOverallAveragesPlaceholder.component';
import { getMark, getMarkLabel } from 'modules/Submissions/Utils';
import { isObject } from 'modules/Utils';
import { UrlIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './SumsOverallAverages.styles';

export const SumsOverallAverages = ({
  loading,
  sumsOverall,
  averageOverall,
  averages,
  interviewLink,
}) => {
  const renderAveragesArray = useMemo(() => {
    if (!Array.isArray(averages)) return;

    return [`Overall: ${averageOverall || 'N/A'}`, ...averages].map((mark) => (
      <S.ScoreWrapper key={mark}>
        <Score score={getMark(mark)} />
        <span>{getMarkLabel(mark)}</span>
      </S.ScoreWrapper>
    ));
  }, [averageOverall, averages]);

  const renderAveragesObject = useMemo(() => {
    if (!isObject(averages)) return;

    return Object.keys(averages).map((objKey) => (
      <S.ScoreWrapper key={objKey}>
        <Score score={averages[objKey]} />
        <span>{objKey}</span>
      </S.ScoreWrapper>
    ));
  }, [averageOverall, averages]);

  const renderAverages = useMemo(() => {
    if (Array.isArray(averages)) {
      return renderAveragesArray;
    } else if (isObject(averages)) {
      return renderAveragesObject;
    } else {
      return '-';
    }
  }, [averageOverall, averages]);

  if (loading) return <SumsOverallAveragesPlaceholder />;

  return (
    <S.Container className="sums-overall-averages">
      <S.Sums>
        <h4>
          {i18n.t(
            'modules.submissions.common.header.sums-overall-averages.titles.sums'
          )}
          :
        </h4>
        <Score
          secondary
          score={sumsOverall || 'N/A'}
          className="score-overall"
        />
        <Button
          transparent
          medium
          onClick={() => window.open(interviewLink)}
          disabled={!interviewLink}
        >
          {i18n.t('commons.buttons.interview-link')}
          <UrlIcon className="right" />
        </Button>
      </S.Sums>
      <S.Averages>
        <h4>
          {i18n.t(
            'modules.submissions.common.header.sums-overall-averages.titles.averages'
          )}
        </h4>
        <S.Scores>{renderAverages}</S.Scores>
      </S.Averages>
    </S.Container>
  );
};

SumsOverallAverages.propTypes = {
  loading: PropTypes.bool,
  sumsOverall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  averageOverall: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  averages: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  interviewLink: PropTypes.string,
};
