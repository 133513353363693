import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Audio } from 'modules/Core/Common';

import * as S from './AnswerContainer.styles';

export const QuestionContainer = ({ data, noScroll }) => {
  const [tab, setTab] = useState('question');
  const changeTab = useCallback((tab) => setTab(tab), [tab]);

  return (
    <S.QuestionContainer>
      <S.CardHeader>
        <S.Title
          onClick={() => changeTab('question')}
          active={tab === 'question'}
        >
          {i18n.t(
            'modules.submissions.common.table.exercises-answers.question'
          )}
        </S.Title>
        <S.Title
          onClick={() => changeTab('passage')}
          active={tab === 'passage'}
        >
          {i18n.t('modules.submissions.common.table.exercises-answers.passage')}
        </S.Title>
      </S.CardHeader>

      {tab === 'question' && (
        <S.QuestionContent noScroll={noScroll}>
          {data?.question?.title_intro_custom && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data?.question?.title_intro_custom,
              }}
            />
          )}
          {data?.question?.text_intro_custom && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data?.question?.text_intro_custom,
              }}
            />
          )}
          {data?.question?.description && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data?.question?.description,
              }}
            />
          )}
        </S.QuestionContent>
      )}

      {tab === 'passage' && (
        <S.QuestionContent noScroll={noScroll}>
          {data?.passage?.passage_audio?.url && (
            <S.AudioContainer>
              <Audio url={data?.passage?.passage_audio?.url} />
            </S.AudioContainer>
          )}
          {data?.passage?.title && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data?.passage?.title,
              }}
            />
          )}
          {data?.passage?.passage_image?.url && (
            <S.Image src={data?.passage?.passage_image?.url} />
          )}
          {data?.passage?.simulation_text && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data?.passage?.simulation_text,
              }}
            />
          )}
          {data?.passage?.description && (
            <S.Text
              dangerouslySetInnerHTML={{
                __html: data?.passage?.description,
              }}
            />
          )}
        </S.QuestionContent>
      )}
    </S.QuestionContainer>
  );
};

QuestionContainer.propTypes = {
  data: PropTypes.object.isRequired,
  noScroll: PropTypes.bool,
};
