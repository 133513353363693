import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useURLSearchQuery } from 'modules/Core/Hooks';
import useSubmissionUrl from 'modules/Submissions/Hooks/useSubmissionUrl';
import { useCanChangeEvaluator } from 'modules/Submissions/Hooks';
import { DefaultTableActions, TableAction } from 'modules/Core/Common';
import { useHistory } from 'react-router-dom';

const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

import * as S from './styles';

const Actions = ({
  id,
  category,
  custom_test,
  custom_test_category,
  evaluators,
  evaluatorsList,
  onChangeEvaluator,
  onDelete,
  isAdmin,
  schedule_date,
  schedule_status,
  isDeleting,
  subject,
}) => {
  const { pathname } = useLocation();
  const evaluator = evaluators ? evaluators[evaluators.length - 1] : null;
  const { queryParams } = useURLSearchQuery();
  const history = useHistory();

  const { canChangeEvaluator, hasScheduleDate } = useCanChangeEvaluator();

  const { route: testSubmissionsTestRoute, testCategory } = useSubmissionUrl({
    category,
    custom_test_category,
    pathname,
    id,
    queryParams,
  });

  const isPractice = subject.includes('practice');

  return (
    <S.Container>
      <DefaultTableActions
        id={id}
        onDelete={!isPractice && onDelete}
        isDeleting={isDeleting}
        onView={() => history.push(testSubmissionsTestRoute)}
        subject={subject}
      >
        {isAdmin && !!evaluatorsList?.data?.length && (
          <>
            {canChangeEvaluator(testCategory, schedule_status) && (
              <TableAction
                tooltip={i18n.t(`${BUTTON_PREFIX}.change-evaluator`)}
                iconName="change"
                onClick={() =>
                  onChangeEvaluator({
                    id,
                    evaluatorId: evaluator?.id,
                    scheduleDate: schedule_date,
                    showScheduleDate: hasScheduleDate(
                      custom_test,
                      schedule_status,
                      testCategory
                    ),
                  })
                }
              />
            )}
          </>
        )}
      </DefaultTableActions>
    </S.Container>
  );
};

Actions.propTypes = {
  id: PropTypes.number,
  category: PropTypes.object,
  custom_test_category: PropTypes.object,
  evaluators: PropTypes.array,
  onChangeEvaluator: PropTypes.func,
  onDelete: PropTypes.func,
  isAdmin: PropTypes.bool,
  schedule_date: PropTypes.string,
  schedule_status: PropTypes.string,
  evaluatorsList: PropTypes.object,
  custom_test: PropTypes.object,
  isDeleting: PropTypes.bool,
  subject: PropTypes.string,
};

export default Actions;
