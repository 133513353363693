import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Modal, Icon, Button } from 'modules/Core/Common';
import { StudentCard } from './StudentCard';
import i18n from 'i18next';

import * as S from './ModalEndEvaluation.styles';

import { ModalEndEvaluationPlaceholder } from './ModalEndEvaluation.placeholder';
import emptyExercisesSvg from 'assets/layout/empty-data/empty-exercises.svg';
import { deletePropsFromQueryString } from 'modules/Utils';
import { useURLSearchQuery } from 'modules/Core/Hooks';
import { Selectors } from 'modules/Auth/AuthSlice';

export const ModalEndEvaluation = ({
  isOpen,
  onClose,
  nextExercise,
  previousExercise,
  onNextEvaluation,
  canSendToOtherEvaluator,
  evaluatorPosition,
  onChangeEvaluator,
  testType,
  loading,
}) => {
  const history = useHistory();
  const isAdmin = useSelector(Selectors.isAdmin);
  const isFirstEvaluator = evaluatorPosition == 1;
  const emptyExercises = !nextExercise && !previousExercise;
  const { queryParams: currentQueryParams } = useURLSearchQuery();

  const showSendToAnotherEvaluator = useMemo(() => {
    if (isAdmin) return !emptyExercises && canSendToOtherEvaluator;
    return (
      (!emptyExercises && canSendToOtherEvaluator) || canSendToOtherEvaluator
    );
  }, [isAdmin, emptyExercises, isFirstEvaluator, canSendToOtherEvaluator]);

  const backToList = () => {
    const newQueryParams = deletePropsFromQueryString(currentQueryParams, [
      'page',
      'paginates_per',
    ])
      .replace('page_submission', 'page')
      .replace('paginates_per_submission', 'paginates_per');
    history.replace(`/submissions/${testType}-tests?${newQueryParams}`);
    location.reload();
  };

  return (
    <Modal
      width={emptyExercises ? '436px' : '556px'}
      isOpen={isOpen}
      setIsOpen={onClose}
      background="#FFFFFF"
      offCloseOnOverlayClick={loading}
    >
      {loading ? (
        <ModalEndEvaluationPlaceholder />
      ) : (
        <>
          <S.Container>
            <S.Message>
              {emptyExercises && !isFirstEvaluator ? (
                <S.EmptyExercises>
                  <img src={emptyExercisesSvg} alt="Empty exercises" />
                  <h2>
                    {i18n.t('modules.submissions.common.modal.congratulations')}
                  </h2>
                  <p>
                    {i18n.t('modules.submissions.common.modal.more-students')}
                  </p>
                </S.EmptyExercises>
              ) : (
                <>
                  <S.IconContainer>
                    <Icon fontSize="3.2rem" name="check" />
                  </S.IconContainer>
                  <div>
                    <h2>
                      {i18n.t('modules.submissions.common.modal.finished')}
                    </h2>
                    <p>
                      {!canSendToOtherEvaluator
                        ? i18n.t('modules.submissions.common.modal.exit')
                        : i18n.t('modules.submissions.common.modal.assign')}
                    </p>
                  </div>
                </>
              )}
            </S.Message>
            {showSendToAnotherEvaluator && (
              <S.SendToAnotherEvaluator>
                <span>Would you like to assign to another evaluator?</span>
                <Button
                  type="button"
                  small
                  transparent
                  onClick={onChangeEvaluator}
                >
                  <Icon name="evaluate" />
                  {i18n.t('modules.submissions.common.modal.send-now')}
                </Button>
              </S.SendToAnotherEvaluator>
            )}
            <S.Content>
              {nextExercise ? (
                <S.StudentCardContainer>
                  <StudentCard
                    exercise={nextExercise}
                    isNext
                    onButtonClick={onNextEvaluation}
                  />
                  <S.ButtonBack onClick={backToList}>
                    <Icon fontSize="1.2rem" name="chevron-left" left />
                    Back to student list
                  </S.ButtonBack>
                </S.StudentCardContainer>
              ) : (
                <S.ActionsContainer>
                  <S.Actions>
                    <Button type="button" large onClick={onClose}>
                      {i18n.t('modules.submissions.common.modal.finish')}
                    </Button>
                  </S.Actions>
                </S.ActionsContainer>
              )}
            </S.Content>
          </S.Container>
          <S.ButtonClose type="button" onClick={onClose}>
            <Icon fontSize="1.6rem" className="close" name="close" />
          </S.ButtonClose>
        </>
      )}
    </Modal>
  );
};

ModalEndEvaluation.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onNextEvaluation: PropTypes.func,
  onPreviousEvaluation: PropTypes.func,
  nextExercise: PropTypes.any,
  previousExercise: PropTypes.any,
  canSendToOtherEvaluator: PropTypes.bool,
  evaluatorPosition: PropTypes.number,
  onChangeEvaluator: PropTypes.func,
  testType: PropTypes.string,
  loading: PropTypes.bool,
};
