import styled from 'styled-components';

export const Container = styled.div`
  padding: 3.2rem;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-left: 2.4rem;
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.quartenary};
    font-size: 2rem;
  }

  p {
    margin-left: 2.4rem;
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.default};
  }
`;

export const IconContainer = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${(props) => props.theme.config.colors.green_100};

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.success};
    }
  }

  .skeleton-element {
    width: 6.4rem;
    max-width: 6.4rem;
    height: 6.4rem;
  }
`;
export const Content = styled.div`
  margin-top: 2.4rem;
  display: flex;
  justify-content: space-between;

  .skeleton-element {
    height: inherit;
    border-radius: 0.6rem;
  }
`;

export const Separator = styled.div`
  width: 20.2rem;
  height: 1px;
  background-color: ${(props) => props.theme.config.colors.gray_200};
  margin: 1.6rem auto 0.8rem;
`;

export const ActionsContainer = styled.div`
  display: grid;
  gap: 3.2rem;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_300};
  padding-top: 3.2rem;

  p {
    grid-column: span 2;
    text-align: center;
    max-width: 285px;
    margin: 0 auto;
    color: ${(props) => props.theme.config.colors.text_base};
  }
`;

export const Actions = styled.div`
  grid-column: span 2;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.8rem;

      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }

    & + button {
      margin-left: 3.2rem;

      svg {
        margin-right: 0rem;
        margin-left: 0.8rem;

        path {
          fill: ${(props) => props.theme.config.colors.light};
        }
      }
    }
  }
`;

export const SendToAnotherEvaluator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.config.colors.primary_100};

  svg {
    width: 1.4rem;
    margin-right: 0.4rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }

  span {
    ${(props) => props.theme.config.typograph.default};
    font-size: 1.4rem;
    color: ${(props) => props.theme.config.colors.text_base};
  }

  button {
    min-width: fit-content;
  }
`;

export const StudentCardContainer = styled.div`
  width: 100%;
`;

export const ButtonBack = styled.button`
  color: ${(props) => props.theme.config.colors.gray_400};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.theme.config.spacing.spacing_4} auto 0;
  min-width: 15rem;
  min-height: 2.4rem;
  background: none;
  border: none;
  outline: none;

  svg {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
    text-align: center;
    display: none;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }

    @keyframes slide-right {
      to {
        transform: translateX(0);
      }
    }
  }

  &:hover {
    color: ${(props) => props.theme.config.colors.primary};
  }

  &:hover svg {
    display: block;
    min-width: max-content;
    animation: 0.4s slide-right 0s forwards;
    transform: translateX(100%);
  }
`;

export const EmptyExercises = styled.div`
  text-align: center;

  h2 {
    margin-top: 2.4rem;
    margin-bottom: 1.6rem;
  }
`;

export const ButtonClose = styled.button`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;

  svg {
    width: 1.6rem;
    height: 1.6rem;

    path {
      fill: ${(props) => props.theme.config.colors.gray_400};
    }
  }
`;
