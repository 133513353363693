import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../../AnswerSheet.styles';
import { addKeyOnInitial } from '../QuestionTypes.utils';
import { getAlternativeByCondition } from './QuestionTypes.base';

const Matching = ({ alternatives }) => {
  return alternatives?.map((alternative, index) => (
    <S.AlternativeContainer
      key={alternative.alternative_id}
      right={alternative.right}
      wrong={!alternative.right}
    >
      {getAlternativeByCondition(alternative, index)}
      <S.AlternativeText
        dangerouslySetInnerHTML={{
          __html: addKeyOnInitial(
            alternative?.alternative_description,
            alternative?.alternative_chosen_by_user
          ),
        }}
      />
    </S.AlternativeContainer>
  ));
};

Matching.propTypes = {
  alternatives: PropTypes.array,
};

export default Matching;
