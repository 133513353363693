import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';

import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';

import { Actions, Async } from 'modules/Submissions/SubmissionsSlice';

const INITIAL_STATE = {
  id: '',
  evaluatorId: '',
  scheduleDate: '',
  showScheduleDate: false,
};

export const useChangeEvaluator = ({ testType }) => {
  const [changeEvaluatorObj, setChangeEvaluatorObj] = useState(INITIAL_STATE);
  const [isChangeEvaluatorModalOpen, setIsChangeEvaluatorModalOpen] =
    useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (!isChangeEvaluatorModalOpen) setChangeEvaluatorObj(INITIAL_STATE);
  }, [isChangeEvaluatorModalOpen]);

  useEffect(() => {
    return () => dispatch(Actions.cleanState());
  }, [location.pathname]);

  const handleOpenChangeEvaluatorModal = (
    testSubmission,
    isEvaluator,
    callback
  ) => {
    if (isEvaluator) {
      return handleChangeEvaluatorRandomly(
        {
          id: testSubmission?.id,
          scheduleDate: testSubmission?.scheduleDate,
          showScheduleDate: testSubmission?.showScheduleDate,
        },
        callback
      );
    }
    setChangeEvaluatorObj({
      ...changeEvaluatorObj,
      id: testSubmission?.id,
      evaluatorId: testSubmission?.evaluatorId,
      scheduleDate: testSubmission?.scheduleDate,
      showScheduleDate: testSubmission?.showScheduleDate,
    });
    setIsChangeEvaluatorModalOpen(true);
  };

  const handleChangeEvaluatorRandomly = (testSubmission, callback) => {
    const action = Async.changeEvaluator({
      testType,
      data: testSubmission,
      onSuccess: (response) => {
        const updatedTestSubmissionObj = response.data.content;
        Toast(
          i18n.t('success-messages.successfully-toast.changed-evaluator'),
          'success'
        );
        setIsChangeEvaluatorModalOpen(false);
        typeof callback === 'function' && callback();
        dispatch(Actions.updateSubmissionOnList(updatedTestSubmissionObj));
      },
      onError: (e) =>
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.change-evaluator')
        )(e),
    });

    dispatch(action);
  };

  const handleChangeEvaluator = (evaluatorId, scheduleDate, callback) => {
    const action = Async.changeEvaluator({
      testType,
      data: {
        ...changeEvaluatorObj,
        evaluatorId,
        scheduleDate,
      },
      onSuccess: (response) => {
        const updatedTestSubmissionObj = response.data.content;
        Toast(
          i18n.t('success-messages.successfully-toast.changed-evaluator'),
          'success'
        );
        setIsChangeEvaluatorModalOpen(false);
        dispatch(Actions.updateSubmissionOnList(updatedTestSubmissionObj));
        typeof callback === 'function' && callback();
      },
      onError: (e) =>
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.change-evaluator')
        )(e),
    });

    dispatch(action);
  };

  const autoChangeEvaluator = (id, callback) => {
    const action = Async.autoChangeEvaluator({
      id,
      onSuccess: (response) => {
        const updatedTestSubmissionObj = response.data.content;
        Toast(
          i18n.t('success-messages.successfully-toast.changed-evaluator'),
          'success'
        );
        dispatch(Actions.updateSubmissionOnList(updatedTestSubmissionObj));
        typeof callback === 'function' && callback();
      },
      onError: (e) =>
        defaultErrorToast(
          i18n.t('errors.error-sorry-an-error-occurred-during.change-evaluator')
        )(e),
    });

    dispatch(action);
  };

  return {
    changeEvaluatorObj,
    isChangeEvaluatorModalOpen,
    setIsChangeEvaluatorModalOpen,
    handleOpenChangeEvaluatorModal,
    handleChangeEvaluator,
    autoChangeEvaluator,
  };
};
