import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  display: flex;
  background: ${(props) => props.theme.config.colors.light};
  margin-bottom: ${(props) => props.theme.config.spacing.layout_4};
  border-radius: 0.6rem;
  flex-direction: column;
`;

export const ExerciseInfo = styled.div`
  display: flex;

  @media ${device.tabletAndMobile} {
    flex-direction: column;
  }
`;

export const ScoresDevice = styled.div`
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
`;

export const ScoresDeviceButton = styled.button`
  margin-right: 1rem;
  border: 0;
  white-space: nowrap;
  padding: 0.8rem 1.6rem;
  background-color: ${(props) =>
    props.active ? props.theme.config.colors.primary_100 : 'transparent'};
  border-radius: 3rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${(props) =>
    props.active
      ? props.theme.config.colors.primary
      : props.theme.config.colors.text_base};
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  svg {
    margin-right: 0.8rem;

    path {
      fill: ${(props) =>
        props.active
          ? props.theme.config.colors.primary
          : props.theme.config.colors.text_base};
    }
  }

  &:hover {
    color: ${(props) => props.theme.config.colors.primary};

    svg {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;

export const ScoresDeviceInfo = styled.div`
  padding: 1.6rem 2.4rem;
  background-color: ${(props) => props.theme.config.colors.primary_100};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-size: 2rem;
  }
`;

export const ScoresDetails = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${(props) => props.theme.config.colors.text_base};

  li {
    ${(props) => props.theme.config.typograph.mico};
    display: flex;
    align-items: center;

    div {
      margin-left: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      border: 0.2rem solid ${(props) => props.theme.config.colors.primary};
      color: ${(props) => props.theme.config.colors.primary};
      font-size: 1.2rem;
      font-weight: bold;
    }

    & + li {
      border-left: 1px solid ${(props) => props.theme.config.colors.primary_300};
      margin-left: 3.2rem;
      padding-left: 3.2rem;

      @media ${device.mobile} {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }

  @media ${device.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
    padding-left: 0;
    padding-top: 1.6rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const DeviceDetails = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: ${(props) => props.theme.config.colors.text_base};

  span {
    ${(props) => props.theme.config.typograph.xsmall};
    font-weight: bold;
  }

  li {
    border-right: 1px solid ${(props) => props.theme.config.colors.primary_300};
    margin-right: 3.2rem;
    padding-right: 3.2rem;

    @media ${device.mobile} {
      border-right: none;
      margin-right: 0;
      padding-right: 0;
    }
  }

  @media ${device.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.6rem;
    padding-left: 0;
    padding-top: 1.6rem;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const User = styled.div`
  padding: 2.4rem 4rem 2.4rem 2.4rem;

  div {
    img {
      min-width: 48px;
    }
    span {
      color: ${(props) => props.theme.config.colors.text_base};
      width: max-content;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }

      &:first-child {
        ${(props) => props.theme.config.typograph.default};
        font-weight: bold;
      }

      &:not(:first-of-type) {
        left: 0;
      }

      & + span {
        margin-top: 0.8rem;
      }
    }
  }

  @media ${device.mobile} {
    width: 100%;
    padding-right: 2.4rem;

    div {
      span {
        max-width: 26rem;
      }
    }
  }
`;

export const Information = styled.div`
  display: flex;
  padding: 2.4rem 2.4rem 2.4rem 4rem;
  width: 100%;
  background: ${(props) => props.theme.config.colors.gray_100};

  .send-to-other-evaluator {
    align-self: flex-end;
    margin-left: auto;
    height: 4.8rem;
  }

  @media ${device.mobile} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.2rem 0;
    padding-left: 2.4rem;

    .send-to-other-evaluator {
      grid-column: span 2;
      margin-left: 0;
    }
  }
`;

export const Item = styled.div`
  h6 {
    margin-bottom: 0.4rem;
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
  }

  p {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
  }

  & + div:not(.categories) {
    border-left: 1px solid ${(props) => props.theme.config.colors.gray_300};
    margin-left: 3.2rem;
    padding-left: 3.2rem;
  }

  &.evaluator {
    > div {
      margin: 0 auto;

      @media ${device.mobile} {
        margin: 0;
      }
    }

    @media ${device.mobile} {
      padding-left: 2.4rem;
    }
  }

  &.grading-rubric {
    align-self: center;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  button {
    white-space: nowrap;
  }

  @media ${device.mobile} {
    height: 100%;
    padding: 0 2.4rem;

    &:first-child {
      border-left: 1px solid ${(props) => props.theme.config.colors.gray_300};
    }

    & + div:not(.categories) {
      margin-left: 0;
      padding-left: 2.4rem;
    }

    &.grading-rubric {
      display: flex;
      align-items: center;
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 33rem;

  h6 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.text_base};
    white-space: nowrap;
    margin-right: 1.6rem;
  }

  @media ${device.mobile} {
    flex-direction: column;
    grid-column: 1 / 3;
    width: 100%;

    h6 {
      margin-right: 0;
      margin-bottom: 1.6rem;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;

  .skeleton-element {
    ${(props) =>
      props.secondary &&
      css`
        background-color: ${(props) => props.theme.config.colors.gray_300};
      `};
  }

  .skeleton-element[type='round'] {
    width: 4.8rem;
    height: 4.8rem;
    max-width: 4.8rem;
  }

  .skeleton-element[type='string'] {
    display: block;
    width: 220px;
  }

  .skeleton-element[type='medium'] {
    display: block;
    width: 220px;
    height: 2.4rem;
  }

  .skeleton-container {
    & + .skeleton-container {
      margin-top: ${(props) => props.theme.config.spacing.spacing_2};
    }
  }
`;
