import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from 'modules/Core/Common';
import { Actions } from 'modules/Submissions/Common/Answers';
import i18n from 'i18next';

const filteredAdditional = (additional, data) => {
  if (!data?.[0]) return additional;

  return additional.filter((column) => {
    if (column.key.includes('status')) {
      return column.render(data[0]) !== 'noProperty';
    } else {
      return true;
    }
  });
};

const getColumns = (additional, toggleButton, data) => [
  toggleButton && {
    title: '',
    sort: false,
    key: 'toggle',
    render: toggleButton,
  },

  ...filteredAdditional(additional, data),

  {
    title: i18n.t('modules.submissions.common.table.columns.actions'),
    key: 'actions',
    sort: false,
    render: Actions,
  },
];

export const AnswersTable = ({
  type,
  data,
  actions,
  thSpacing,
  tdSpacing,
  onNextPage,
  toggleChild,
  toggleButton,
  firstItemSize,
  toggledChildren,
  toggleProfileDropdownId,
  additionalColumns,
  customQueryParams,
  onToggleClick,
}) => (
  <TablePagination
    data={data[type]}
    tdSpacing={tdSpacing}
    thSpacing={thSpacing}
    additionalData={actions}
    toggleChild={toggleChild}
    firstItemSize={firstItemSize}
    toggledChildren={toggledChildren}
    toggleChildDataMapField="id"
    toggleProfileDropdownId={toggleProfileDropdownId}
    columns={getColumns(additionalColumns, toggleButton, data[type])}
    pagination={{
      total: data.total,
      perPage: data.perPage,
      page: data.page,
      customQueryParams,
      search: customQueryParams?.search,
      onNextPage,
      onToggleClick: onToggleClick,
    }}
  />
);

AnswersTable.defaultProps = {
  additionalColumns: [],
};

AnswersTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  actions: PropTypes.object,
  toggleChild: PropTypes.number,
  onNextPage: PropTypes.func,
  toggleButton: PropTypes.func,
  tdSpacing: PropTypes.string,
  thSpacing: PropTypes.string,
  firstItemSize: PropTypes.string,
  toggledChildren: PropTypes.element,
  toggleProfileDropdownId: PropTypes.number,
  additionalColumns: PropTypes.array,
  customQueryParams: PropTypes.object,
  onToggleClick: PropTypes.func,
};
