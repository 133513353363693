import { createSlice } from '@reduxjs/toolkit';
import {
  request,
  generateCancelToken,
  cancelRequests,
  isCancel,
  ejectCancelInterceptor,
} from 'modules/Api/HttpClient';
import {
  DISCOUNTS_URL,
  PLANS_URL,
  CREATORS_URL,
  GROUPS_URL,
  DISCOUNTS_CREATE_URL,
  DISCOUNTS_UPDATE_URL,
  DISCOUNTS_DELETE_URL,
} from 'modules/Api/Routes';

let cancelToken;

const initialState = {
  loading: false,
  loadingEditDiscount: false,
  error: null,
  data: {
    page: 1,
    perPage: 10,
    search: '',
    error: null,
    sort: {
      sortType: null,
      sortBy: null,
    },
  },
  plans: {
    data: [],
    loading: false,
  },
  creators: {
    data: [],
    loading: false,
  },
  groups: {
    data: [],
    loading: false,
  },
};

const discountsSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    cancelRequests: () => {
      cancelToken?.cancel();
      cancelRequests();
    },
    cleanState: () => ({ ...initialState }),
    changeSort: (state, action) => {
      const newSort = action.payload;
      state.data.sort.sortType =
        state.data.sort.sortBy === newSort && state.data.sort.sortType === 'ASC'
          ? 'DESC'
          : 'ASC';
      state.data.sort.sortBy = action.payload;
    },
    requestDiscounts: (state) => {
      state.loading = true;
      state.error = null;
    },
    requestPlans: (state) => {
      state.plans.loading = true;
      state.plans.data = [];
    },
    requestCreators: (state) => {
      state.creators.loading = true;
      state.creators.data = [];
    },
    requestGroups: (state) => {
      state.groups.loading = true;
      state.groups.data = [];
    },
    requestUpdateDiscount: (state, action) => {
      state.loadingEditDiscount = true;
      state.data = {
        ...state.data,
        error: null,
        discounts: state.data.discounts?.map((item) => {
          if (item?.id === action.payload?.id) return action.payload;
          return item;
        }),
        total: state.data.total,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingUpdateDiscount: (state, action) => {
      state.loadingEditDiscount = action.payload;
    },
    changeDiscountsPage: (state, action) => {
      state.data.page = action.payload;
    },
    changePerPage: (state, action) => {
      state.data.perPage = action.payload;
      state.data.page = 1;
    },
    receiveDiscountsList: (state, action) => {
      state.loading = false;
      state.data = {
        ...state.data,
        discounts: action.payload.discounts,
        total: action.payload.total_items,
      };
    },
    receivePlansList: (state, action) => {
      state.plans.loading = false;
      state.plans.data = action.payload.plans;
    },
    receiveCreatorsList: (state, action) => {
      state.creators.loading = false;
      state.creators.data = action.payload.users;
    },
    receiveGroupsList: (state, action) => {
      state.groups.loading = false;
      state.groups.data = action.payload.groups;
    },
    receiveUpdateDiscountError: (state, action) => {
      state.loadingEditDiscount = false;
      state.data = action.payload;
    },
    clearDiscountsList: (state) => {
      state.data = {
        page: 1,
        perPage: 10,
        search: '',
        sort: {
          sortType: null,
          sortBy: null,
        },
      };
    },
    receiveDiscountsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    receiveDiscountPurchasesError: (state, action) => {
      state.discountPurchases.loading = false;
      state.discountPurchases.error = action.payload;
    },
    receiveDiscountsInformationError: (state, action) => {
      state.discountsInformation.loading = false;
      state.discountsInformation.error = action.payload;
    },
    changeDiscountsFilters: (state, action) => {
      state.data.search = action.payload.search;
      state.data.page = isNaN(action?.payload?.page)
        ? 1
        : Number(action?.payload?.page);
      state.data.perPage = isNaN(action?.payload?.paginates_per)
        ? state.data.perPage
        : Number(action?.payload?.paginates_per);
    },
    clearDiscountsFilters: (state) => {
      state.data.search = '';
      state.data.page = 1;
      state.data.perPage = 10;
      state.data.sort.sortType = null;
      state.data.sort.sortBy = null;
    },
  },
});

const Actions = discountsSlice.actions;

const Selectors = {
  fetchListData: (state) => state.discounts,
  discountsLoading: ({ discounts: { loading } }) => ({ loading }),
};

const Async = {
  fetchDiscountsList: () => async (dispatch, getState) => {
    const {
      discounts: {
        data: {
          page,
          perPage,
          search,
          sort: { sortType, sortBy },
        },
      },
    } = getState();

    ejectCancelInterceptor();
    cancelToken?.cancel();
    cancelToken = generateCancelToken();

    let action;

    dispatch(Actions.requestDiscounts());

    try {
      const response = await request({
        cancelToken: cancelToken.token,
        method: 'GET',
        url: DISCOUNTS_URL,
        params: {
          page,
          paginates_per: perPage,
          search,
          sort: sortType,
          sort_by: sortBy,
        },
      });

      action = Actions.receiveDiscountsList(response.data.content);
    } catch (e) {
      if (!isCancel(e)) {
        action = Actions.receiveDiscountsError(e.message);
      }
    }

    action && dispatch(action);
  },

  fetchPlansList:
    ({ onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestPlans());

      try {
        const response = await request({
          method: 'GET',
          url: PLANS_URL,
        });

        action = Actions.receivePlansList(response.data.content);
      } catch (e) {
        onError();
      }

      action && dispatch(action);
    },

  fetchCreatorsList:
    ({ onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestCreators());

      try {
        const response = await request({
          method: 'GET',
          url: CREATORS_URL,
        });

        action = Actions.receiveCreatorsList(response.data.content);
      } catch (e) {
        onError();
      }

      action && dispatch(action);
    },

  fetchGroupsList:
    ({ onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestGroups());

      try {
        const response = await request({
          method: 'GET',
          url: GROUPS_URL,
        });

        action = Actions.receiveGroupsList(response.data.content);
      } catch (e) {
        onError();
      }

      action && dispatch(action);
    },

  createDiscount:
    ({ data, onSuccess, onError }) =>
    async (dispatch, getState) => {
      const state = getState();

      dispatch(Actions.requestUpdateDiscount(data));

      try {
        const eventObj = {
          name: data?.name,
          promocode: data?.promocode,
          plan_id: data?.plan?.id,
          expiration_date: data?.expiration_date,
          discount: data?.discount,
          amount: data?.amount,
          active: data?.active,
          display_on_student: data?.display_on_student,
          recurrent_discount: data?.recurrent_discount,
          creator_id: data?.creator?.id,
          group_ids: data?.groups,
          onboarding_only: data?.onboarding_only,
        };

        await request({
          method: 'POST',
          url: `${DISCOUNTS_CREATE_URL}`,
          data: eventObj,
        });

        onSuccess();
      } catch (e) {
        dispatch(
          Actions.receiveUpdateDiscountError({
            ...state.discounts.data,
            error: e.message,
          })
        );
        onError(e);
      }

      dispatch(Actions.setLoadingUpdateDiscount(false));
    },

  updateDiscount:
    ({ data, onSuccess, onError }) =>
    async (dispatch, getState) => {
      const state = getState();

      dispatch(Actions.requestUpdateDiscount(data));

      try {
        const eventObj = {
          name: data?.name,
          id: data?.id,
          promocode: data?.promocode,
          plan_id: data?.plan?.id,
          expiration_date: data?.expiration_date,
          discount: data?.discount,
          amount: data?.amount,
          active: data?.active,
          display_on_student: data?.display_on_student,
          recurrent_discount: data?.recurrent_discount,
          onboarding_only: data?.onboarding_only,
          creator_id: data?.creator?.id,
          group_ids: data?.groups,
        };

        await request({
          method: 'PUT',
          url: `${DISCOUNTS_UPDATE_URL}`,
          data: eventObj,
        });

        onSuccess();
      } catch (e) {
        dispatch(
          Actions.receiveUpdateDiscountError({
            ...state.discounts.data,
            error: e.message,
          })
        );
        onError(e);
      }

      dispatch(Actions.setLoadingUpdateDiscount(false));
    },

  deleteDiscount:
    ({ id, onSuccess, onError }) =>
    async (dispatch) => {
      let action;

      dispatch(Actions.requestDiscounts());

      try {
        const response = await request({
          method: 'DELETE',
          url: `${DISCOUNTS_DELETE_URL}?id=${id}`,
        });

        onSuccess(response);
        action = Async.fetchDiscountsList();
      } catch (e) {
        action = Actions.setLoading(false);
        onError();
      }

      action && dispatch(action);
    },
};

const { reducer } = discountsSlice;

export { reducer, Actions, Async, Selectors };
