import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'modules/Core/Common';
import { useTheme } from 'styled-components';
import { PendingIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './Score.styles';

export const Score = ({ score, ...props }) => {
  const isPending = score === 'Pending';
  const theme = useTheme();

  return (
    <Tooltip
      placement="bottom"
      overlay={score}
      disabled={!isPending}
      overlayInnerStyle={{
        backgroundColor: theme.config.colors.primary,
        borderRadius: '4px',
        padding: '8px 16px',
        fontSize: '1.6rem',
      }}
    >
      <S.Score score={score} {...props}>
        <>
          {isPending ? (
            <PendingIcon color={theme.config.colors.text_base} />
          ) : (
            score
          )}
        </>
      </S.Score>
    </Tooltip>
  );
};

Score.propTypes = {
  score: PropTypes.any,
};
