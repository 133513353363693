import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  background: ${(props) => props.theme.config.colors.light};
  display: grid;
  grid-template-columns: 230px auto;
  border-radius: 0.6rem;

  h4 {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
    margin-bottom: 2.4rem;
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const Sums = styled.div`
  background: ${(props) => props.theme.config.colors.gray_100};
  padding: 4rem 0;
  text-align: center;

  .score-overall {
    margin: 0 auto;
  }

  button {
    margin: 0 auto;
    margin-top: 2.4rem;
  }

  svg {
    width: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;

    path {
      fill: ${(props) => props.theme.config.colors.primary};
    }
  }
`;

export const Averages = styled.div`
  padding: 4rem 0 4rem 8rem;

  @media ${device.mobile} {
    padding: 4rem;
  }
`;

export const Scores = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 240px));
  gap: 2.4rem 4rem;

  @media ${device.mobile} {
    grid-template-columns: auto;
  }
`;

export const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;

  &.skeleton-wrapper {
    display: block;

    span:first-child {
      margin-left: 0;
    }
  }

  span {
    color: ${(props) => props.theme.config.colors.text_base};
    margin-left: 1.6rem;
  }
`;
