import styled, { css } from 'styled-components';
import { device } from 'modules/Utils';

export const CommentsContainer = styled.div`
  display: block;
  margin-top: 4rem;

  label {
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

export const CommentsBlock = styled.div`
  flex-basis: 40rem;

  ${({ block }) =>
    !!block &&
    css`
      flex-basis: 100%;
    `}
`;

export const EmptyAnswerContainer = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row-reverse;

  label:first-child {
    font-weight: 500;
    color: ${(props) => props.theme.config.colors.text_base};
    margin-left: ${(props) => props.theme.config.spacing.spacing_3};
  }
`;

export const MarkSelect = styled.div`
  width: 100px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  padding: ${(props) => props.theme.config.spacing.spacing_3};
  border: 1px solid ${(props) => props.theme.config.colors.text_base};
  border-radius: 0.6rem;
  resize: none;
`;

export const CommentsPlaceholder = styled.div`
  margin-top: 2rem;
  span {
    height: 14rem;
  }
`;
