import React from 'react';
import PropTypes from 'prop-types';
import * as S from './SubmissionsTotalizers.styles';
import { TableEmptyDataIcon } from '@edusynch/edusynch-svg-icons';

const SubmissionsTotalizersComponent = ({ Icon, value, label }) => (
  <S.Totalizer>
    <S.IconBox>
      <Icon />
    </S.IconBox>
    <div>
      <S.Label>{label}:</S.Label>
      <S.Value>{value || <TableEmptyDataIcon />}</S.Value>
    </div>
  </S.Totalizer>
);

SubmissionsTotalizersComponent.propTypes = {
  Icon: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.any,
};

export default SubmissionsTotalizersComponent;
