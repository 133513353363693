import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Question from 'modules/Simulation/AnswerSheet/QuestionTypes';
import { QuestionContainer } from 'modules/Simulation/AnswerSheet/Common';
import { types } from 'modules/Simulation/AnswerSheet/QuestionTypes/Types';
import { getQuestionByType } from 'modules/Simulation/AnswerSheet/QuestionTypes/QuestionTypes.utils';
import { ChildInformationPlaceholder } from './ChildInformationPlaceholder.component';

import * as S from './ChildInformation.styles';

const questionsTypesWithoutDefaultAnswerTitle = [
  types.TABLE_2_COLUMN_TYPE_1,
  types.TABLE_2_COLUMN_TYPE_2,
  types.TABLE_3_COLUMN_TYPE_1,
  types.TABLE_3_COLUMN_TYPE_2,
  types.COMPLETION_COMPREHENSION,
  types.CONVERSATION_TALKS,
];

const ChildInformation = ({ loading, data, columnsSize }) => {
  const alternatives = useMemo(() => {
    const alternativesArray =
      data?.question?.question_details?.alternatives ||
      data?.question?.alternatives;

    if (!data || !alternativesArray) return [];

    return alternativesArray.map((alternative) => ({
      ...alternative,
      alternative_id: alternative.alternative_id || alternative.id,
      alternative_description:
        alternative.alternative_description ||
        alternative.description ||
        alternative.title ||
        alternative.alternative_title,
      right: alternative.alternative_right || alternative.right,
      chosen_by_user:
        data.selected_alternative_ids?.includes(
          alternative.alternative_id || alternative.id
        ) || false,
    }));
  }, [data]);

  const hasDefaultAnswerTitle = useMemo(() => {
    const questionType = data?.question?.type_description;
    return !questionsTypesWithoutDefaultAnswerTitle.includes(
      getQuestionByType(questionType)
    );
  }, [data?.question?.type_description]);

  const renderContent = () => (
    <>
      <QuestionContainer data={data} />
      <S.AnswerContainer>
        {hasDefaultAnswerTitle && <S.AnswerTitle>Answer</S.AnswerTitle>}
        <Question
          type={data?.question?.type_description}
          category={data?.category_name}
          alternatives={alternatives}
          speakingAnswer={data?.speaking_answer?.url}
          writingAnswer={data?.writing_answer}
        />
      </S.AnswerContainer>
    </>
  );

  return (
    <S.AnimatedTr>
      <S.ContainerTd colSpan={columnsSize}>
        <S.InfoRow $loading={loading}>
          {loading ? <ChildInformationPlaceholder /> : renderContent()}
        </S.InfoRow>
      </S.ContainerTd>
    </S.AnimatedTr>
  );
};

ChildInformation.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  columnsSize: PropTypes.number,
};

export default ChildInformation;
