import React from 'react';
import PropTypes from 'prop-types';

import { Title } from 'modules/Core/Common';

const AnswerText = ({ writingAnswer }) => (
  <Title
    tag="p"
    fontWeight="normal"
    size="xsmall"
    dangerouslySetInnerHTML={{
      __html: writingAnswer,
    }}
  />
);

AnswerText.propTypes = {
  writingAnswer: PropTypes.string,
};

export default AnswerText;
