import PropTypes from 'prop-types';
import { Stack, Skeleton } from '@mui/material';

import * as S from './SubmissionsTotalizers.styles';

export const SubmissionsTotalizersPlaceholder = ({ type }) => {
  return (
    <S.Container>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Skeleton variant="circular" width="40px" height="40px" />
        <Stack>
          <Skeleton variant="text" width="100px" />
          <Skeleton variant="text" width="100px" height="32px" />
        </Stack>
      </Stack>
      {type !== 'practice' && (
        <>
          <S.Divisor />
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Skeleton variant="circular" width="40px" height="40px" />
            <Stack>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="100px" height="32px" />
            </Stack>
          </Stack>
          <S.Divisor />
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Skeleton variant="circular" width="40px" height="40px" />
            <Stack>
              <Skeleton variant="text" width="100px" />
              <Skeleton variant="text" width="100px" height="32px" />
            </Stack>
          </Stack>
        </>
      )}
    </S.Container>
  );
};

SubmissionsTotalizersPlaceholder.propTypes = {
  type: PropTypes.string,
};
