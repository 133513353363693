import React from 'react';

import { Skeleton } from 'modules/Core/Common';

import * as S from './ModalEndEvaluation.styles';

export const ModalEndEvaluationPlaceholder = () => {
  return (
    <S.Container>
      <S.Message>
        <S.IconContainer>
          <Skeleton type="round" />
        </S.IconContainer>
        <div style={{ width: '30rem', marginLeft: '2.4rem' }}>
          <Skeleton type="medium" />
          <Skeleton
            type="string"
            style={{ width: '90%', marginTop: '0.8rem' }}
          />
        </div>
      </S.Message>
      <S.Content>
        <div style={{ width: '100%', height: '25rem' }}>
          <Skeleton type="full" style={{ height: '25rem' }} />
        </div>
      </S.Content>
    </S.Container>
  );
};
