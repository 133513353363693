import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { LabelWithBackground, TableEmptyData } from 'modules/Core/Common';

import {
  STATUS_CORRECT,
  STATUS_EVALUATED,
  STATUS_INCORRECT,
  STATUS_SKIPPED,
  STATUS_PARTIAL,
  STATUS_PENDING,
} from './AnswerStatus.utils';

export const AnswerStatus = ({ status }) => {
  const formatLabel = (status) => {
    if (STATUS_EVALUATED.includes(status)) return STATUS_EVALUATED[0];
    if (STATUS_INCORRECT.includes(status)) return STATUS_INCORRECT[0];
    if (STATUS_CORRECT.includes(status)) return STATUS_CORRECT[0];
    if (STATUS_PARTIAL.includes(status)) return STATUS_PARTIAL[0];
    if (STATUS_SKIPPED.includes(status)) return STATUS_SKIPPED[0];
    if (STATUS_PENDING.includes(status)) return STATUS_PENDING[0];
    return '';
  };

  const label = useMemo(() => formatLabel(status), [status]);

  if (!label) {
    return <TableEmptyData />;
  }

  return (
    <LabelWithBackground
      label={label}
      secondary={STATUS_EVALUATED.includes(status)}
      tertiary={STATUS_INCORRECT.includes(status)}
      nonary={STATUS_CORRECT.includes(status)}
      decenary={STATUS_PARTIAL.includes(status)}
      duodenary={STATUS_SKIPPED.includes(status)}
    />
  );
};

AnswerStatus.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
