import styled, { css } from 'styled-components';

const getType = (props) => {
  if (props?.primary) {
    return css`
      border: 3px solid ${(props) => props.theme.config.colors.primary};
      background: ${(props) => props.theme.config.colors.primary_100};
      color: ${(props) => props.theme.config.colors.primary};
    `;
  } else if (props?.secondary) {
    return css`
      border: 3px solid ${(props) => props.theme.config.colors.green};
      background: ${(props) => props.theme.config.colors.green_100};
      color: ${(props) => props.theme.config.colors.green};
    `;
  } else if (props?.tertiary) {
    return css`
      border: 3px solid ${(props) => props.theme.config.colors.tertiary};
      background: ${(props) => props.theme.config.colors.tertiary_100};
      color: ${(props) => props.theme.config.colors.tertiary};
    `;
  } else if (props?.score === 'Pending') {
    return css`
      border: ${(props) => props.theme.config.colors.gray_100};
      background: ${(props) => props.theme.config.colors.gray_100};
      color: ${(props) => props.theme.config.colors.text_base};
    `;
  }

  return css`
    border: 3px solid ${(props) => props.theme.config.colors.primary};
    background: ${(props) => props.theme.config.colors.primary_100};
    color: ${(props) => props.theme.config.colors.primary};
  `;
};

export const Score = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  ${(props) => props.theme.config.typograph.default};
  font-weight: bold;
  ${(props) => getType(props)};
`;
