import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-radius: 6px;
  padding: 2.4rem 3.2rem;
  min-height: 6rem;
  background: ${(props) => props.theme.config.colors.light};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_6};

  @media ${device.mobile} {
    gap: 1.6rem;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 1.6rem;
  background: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 50%;

  svg path {
    fill: ${(props) => props.theme.config.colors.primary};
  }
`;

const Divisor = styled.span`
  display: block;
  width: 1px;
  margin: 0 8rem;
  background: ${(props) => props.theme.config.colors.gray_300};

  @media ${device.desktopSmall} {
    margin: 0 6rem;
  }

  @media ${device.tablet} {
    margin: 0 2.4rem;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

const Totalizer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  margin-right: ${(props) => props.theme.config.spacing.spacing_1};
  font-size: 1.2rem;
  display: block;
  line-height: normal;
`;

const Value = styled.span`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  font-weight: 700;
  font-size: 2rem;
  line-height: normal;
`;

export { Label, Value, Divisor, Totalizer, Container, IconBox };
