import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Modal, Icon } from 'modules/Core/Common';

import * as S from './ModalNextAnswer.styles';

export const ModalNextAnswer = ({ isOpen, onClose, onNextQuestion }) => {
  const [seconds, setSeconds] = useState(5);
  const timer = useRef(null);

  useEffect(() => {
    if (isOpen) {
      startTimer();
    }

    return () => clearInterval(timer.current);
  }, [isOpen]);

  useEffect(() => {
    if (seconds === 0) {
      stopTimer();
      onClose();
      onNextQuestion();
    }
  }, [seconds]);

  const startTimer = () => {
    timer.current = setInterval(() => {
      setSeconds((currentSeconds) => currentSeconds - 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timer.current);
    setSeconds(5);
  };

  return (
    <Modal
      width="455px"
      isOpen={isOpen}
      setIsOpen={onClose}
      background="#FFFFFF"
    >
      <>
        <S.Container>
          <S.IconContainer>
            <Icon fontSize="2.4rem" name="check" />
          </S.IconContainer>
          <h2>{i18n.t('modules.submissions.common.modal.been-submitte')}</h2>
          <p>
            {i18n.t('modules.submissions.common.modal.you-will-be-redirected')}{' '}
            <span>{seconds}s</span>{' '}
            {i18n.t('modules.submissions.common.modal.to-the-next-question')}
          </p>
          <S.Separator />
          <button
            type="button"
            onClick={() => {
              onNextQuestion();
              stopTimer();
              onClose();
            }}
          >
            {i18n.t('modules.submissions.common.modal.go-to-next-question')}
            <Icon fontSize="1.2rem" name="chevron-right" right />
          </button>
        </S.Container>
        <S.ButtonClose
          type="button"
          onClick={() => {
            onClose();
            stopTimer();
          }}
        >
          <Icon fontSize="1.6rem" className="close" name="close" />
        </S.ButtonClose>
      </>
    </Modal>
  );
};

ModalNextAnswer.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onNextQuestion: PropTypes.func,
};
