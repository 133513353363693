import { capitalize } from 'lodash';

export const EVENT_MESSAGES = {
  MOVE_FOCUS: !window.location.pathname.startsWith('/antifraud/plugin/log/')
    ? 'User moved focus away from the testing window. This could indicate an attempt to open new windows, tabs, or other programs.'
    : 'Moved focus away from testing window',
  MULTIPLE_CAMS: 'Multiple cameras detected',
  RESET_MULTIPLE_FACES: 'Reset multiple faces detected',
  RESET_NO_FACE_DETECTED: 'Reset no face detected',
  MULTIPLE_DISPLAYS: 'Multiple screens detected',
  MULTIPLE_FACES: 'Multiple people detected on camera',
  NEW_TAB: 'User attempted to open new tabs and/or new windows',
  MULTIPLE_FACES_DETECTED: 'Multiple people detected on camera',
  NO_FACE_DETECTED: 'No user detected',
  NEW_TAB_WINDOW:
    'User opened a new window or tab, minimized the browser, or opened another program.',
  NEW_ANON_TAB_WINDOW:
    'User attempted to open new incognito tabs and/or new windows',
  START_STREAMING: 'Video streaming started',
  STOP_STREAMING: 'Video streaming stopped',
  MORE_THAN_ONE_CAM: 'Multiple cameras detected',
  MULTIPLE_DISPLAYS_DETECTED: 'Multiple screens detected',
  CLOSED_WINDOW: 'Closed browser or tab/refreshed the page via browser',
  CLOSED_WINDOW_OR_TAB: 'Closed browser or tab/refreshed the page via keyboard',
  FINISH_SIMULATION: '{{custom}}Exam completed successfully',
  START_SIMULATION: 'Exam started',
  TEST_PAUSED: 'Exam paused',
  TEST_RESUMED: 'Exam resumed',
  OFFLINE: 'Lost connection',
  ONLINE: 'Connection established',
  ONLINE_SERVER: 'Connection established with server',
  OFFLINE_SERVER: 'Connection lost with server',
  SIMULATION_TIMEOUT: 'Simulation Timed Out',
  SIMULATION_EXPIRED: 'Simulation Expired',
  REFRESH_PAGE: 'Refreshed the page via browser',
  ROOMSCAN_RESUMED: 'Room Scan restarted',
  ROOMSCAN_FAILED_MORE_THAN_TWICE_AND_RESUMED:
    '2 or more errors with Room Scan, Room Scan resumed',
  ROOMSCAN_STARTED: 'Random room scan started',
  ROOMSCAN_LOCKED: 'User offline when Room Scan started',
  ROOMSCAN_UNLOCKED: 'User reconnected, Room Scan can start again',
  SUSPENDED_CAMERA: 'User/some error suspended the camera',
  GAZE_DETECTION: 'User looked away from screen for at least 3 seconds',
  COUNTDOWN_INFO: 'User was offline for more than 3 minutes and timed out',
  SESSION_FAILED_INFO: 'Session not saved properly',
  ERROR_CHOOSE_WINDOW: 'Error with Share Screen',
  ERROR_FINISH_SESSION: 'Error finalizing session',
  ERROR_APPLY_PHOTOS: 'Error with photos',
  ERROR_APPLY_VIDEO: 'Error with video',
  OPEN_DEVTOOLS: 'Opened the dev tools',
};

const getEventMessage = (event) => {
  return EVENT_MESSAGES[event?.type]?.replace(
    /{{custom}}/,
    `${capitalize(event?.category)} ` || ''
  );
};

export default getEventMessage;
