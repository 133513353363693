import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Gallery, Row, Col } from 'modules/Core/Common';
import { AnswersContainer } from 'modules/Submissions/Common/Answers';
import { format } from 'modules/Utils';
import { useAuth } from 'modules/Auth/Hooks';
import { ImageIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

import * as S from './ScreenArchives.styles';

export const ScreenArchives = ({ loading, photos }) => {
  const { userTimeFormat, userDateFormat } = useAuth();

  const parsedPhotos = useMemo(() => {
    if (!photos) return [];

    return photos.map((photo) => ({
      original: photo?.photo?.url,
      thumbnail: photo?.photo?.thumb?.url,
      description: format(
        photo?.created_at,
        `${userDateFormat} ${userTimeFormat}`,
        'parse'
      ),
    }));
  }, [photos]);

  if (!photos.length) {
    return null;
  }

  return (
    <AnswersContainer
      IconName={ImageIcon}
      title={i18n.t('modules.submissions.common.screen-archive.header.title')}
      style={{ marginTop: '2.4rem' }}
    >
      <S.Container>
        <Row center="xs" middle="xs">
          <Col xs={12} lg={6}>
            <Gallery
              photos={parsedPhotos}
              showPlayButton={false}
              showNav={true}
              useBrowserFullscreen={false}
              loading={loading}
              lazyLoad
              emptyDataPadding="0"
            />
          </Col>
        </Row>
      </S.Container>
    </AnswersContainer>
  );
};

ScreenArchives.propTypes = {
  loading: PropTypes.bool,
  photos: PropTypes.array,
};
