import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Marks = ({ text }) => <MarksItem>{text}</MarksItem>;

Marks.propTypes = {
  text: PropTypes.string,
};

const MarksItem = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  color: ${(props) => props.theme.config.colors.primary};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_3};
  background: ${(props) => props.theme.config.colors.primary_100};
  position: relative;
  z-index: 1;
  border-radius: 4px;
  display: inline-block;
  padding: 0.6rem 0.8rem;
`;

export { Marks };
