import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { useDispatch } from 'react-redux';
import { useTheme as useMUITheme, useMediaQuery } from '@mui/material';
import { Button, DragAndDrop, Toast } from 'modules/Core/Common';
import Placeholder from 'assets/avatar/placeholder.png';
import { defaultErrorToast } from 'modules/Utils';
import { CloseIcon, CheckIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

import 'react-image-crop/dist/ReactCrop.css';
import './styles.css';
import * as S from './styles';

const IMAGES_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/x-icon',
];

const UserAvatarInputTitle = ({ title, size, hideTitle }) => {
  if (hideTitle) return null;

  const customTitle =
    title ||
    (size
      ? i18n.t('commons.avatar-profile.edit-image')
      : i18n.t('commons.avatar-profile.title'));

  return <S.Label size={size}>{customTitle}</S.Label>;
};

UserAvatarInputTitle.propTypes = {
  hideTitle: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
};

const UserAvatarInput = ({
  user,
  onChange,
  Async,
  setFileSelected,
  size,
  hideBtn,
  hideTitle,
  large,
  title,
}) => {
  const avatarInput = useRef(null);
  const [avatarPreviewAsBlob, setAvatarPreviewAsBlob] = useState(null);
  const [originalAvatarURL, setOriginalAvatarURL] = useState(null);
  const [imageAsFile, setImageAsFile] = useState(null);
  const [invalidImageType, setInvalidImageType] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const currentImageRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    aspect: 1,
    width: 50,
    height: 50,
    x: 25,
    y: 25,
  });
  const muiTheme = useMUITheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.only('xs'));

  const dispatch = useDispatch();

  useEffect(() => {
    const avatar = user?.avatar?.url;
    const validOriginalAvatar = typeof avatar === 'string' && avatar !== '';

    if (validOriginalAvatar && !avatar?.includes('default_avatar')) {
      setAvatarPreviewAsBlob(avatar);
      setOriginalAvatarURL(avatar);
    }
  }, [user?.avatar]);

  const handleDroppedImage = useCallback(
    (event) => {
      setAvatarPreviewAsBlob(URL.createObjectURL(event.dataTransfer.files[0]));
      setImageAsFile(event.dataTransfer.files[0]);
      setToggleModal(true);
      setFileSelected(true);
    },
    [avatarPreviewAsBlob]
  );

  const handleChangeImage = useCallback(
    (event) => {
      if (!event.target.files.length) return null;

      if (!IMAGES_TYPES.includes(event.target.files[0].type)) {
        setInvalidImageType(true);
      } else {
        setAvatarPreviewAsBlob(URL.createObjectURL(event.target.files[0]));
        setImageAsFile(event.target.files[0]);
        setInvalidImageType(false);
        setToggleModal(true);
        setFileSelected(true);
      }
    },
    [avatarPreviewAsBlob]
  );

  const handleChooseImage = useCallback((event) => {
    event.preventDefault();
    avatarInput.current.click();
  });

  const onImageLoaded = useCallback((image) => {
    currentImageRef.current = image;
  });

  const resetAvatarPreviewAsBlob = () => {
    URL.revokeObjectURL(avatarPreviewAsBlob);
    setAvatarPreviewAsBlob(null);
  };

  const handleCropCancel = useCallback(
    (event) => {
      event.preventDefault();

      avatarInput.current.value = null;

      onChange(null);
      setImageAsFile(null);
      resetAvatarPreviewAsBlob();
      setToggleModal(false);
      setFileSelected(false);

      if (originalAvatarURL) setAvatarPreviewAsBlob(originalAvatarURL);
    },
    [avatarPreviewAsBlob]
  );

  const handleRemoveImage = useCallback(
    (event) => {
      event.preventDefault();

      const resetAvatar = () => {
        avatarInput.current.value = null;
        onChange(null);
        setImageAsFile(null);
        resetAvatarPreviewAsBlob();
        setFileSelected(false);
      };

      const removeAvatarFromS3 = () => {
        const action = Async.removeAvatar({
          id: user.id,
          onSuccess: () => {
            Toast(
              i18n.t('success-messages.successfully-toast.removed-avatar'),
              'success'
            );
            resetAvatar();
          },
          onError: (e) =>
            defaultErrorToast(
              i18n.t(
                'errors.error-sorry-an-error-occurred-during.remove-avatar'
              )
            )(e),
        });
        dispatch(action);
      };

      const isStoragedOnS3 = originalAvatarURL?.includes('s3.amazonaws');

      if (isStoragedOnS3) {
        const isConfirmed = window.confirm(
          i18n.t('commons.filter.cancel-alert.are-you-sure')
        );
        if (isConfirmed) {
          removeAvatarFromS3();
        }
        //silent
      } else {
        resetAvatar();
      }
    },
    [avatarInput, originalAvatarURL]
  );

  const handleCropSave = useCallback((event) => {
    event.preventDefault();

    const image = currentImageRef.current;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      (blob) => {
        const imageFile = new File([blob], imageAsFile.name, {
          lastModified: Date.now(),
          type: imageAsFile.type,
        });

        avatarInput.current.value = null;

        onChange(imageFile);
        setAvatarPreviewAsBlob(URL.createObjectURL(imageFile));
        setToggleModal(false);
        setImageAsFile(null);
        setOriginalAvatarURL(null);
        setFileSelected(false);
      },
      'image/jpeg',
      1
    );
  });

  return (
    <>
      <UserAvatarInputTitle size={size} title={title} hideTitle={hideTitle} />

      <S.AvatarContainer size={size} className="avatar-container">
        {avatarPreviewAsBlob ? (
          <S.ImagePreviewContainer className="image-preview-container">
            <img src={avatarPreviewAsBlob} alt="avatar preview" />

            <S.ImagePreviewButton onClick={handleRemoveImage}>
              <CloseIcon type="small" />
            </S.ImagePreviewButton>
          </S.ImagePreviewContainer>
        ) : (
          <DragAndDrop
            large={large}
            handleDroppedImage={handleDroppedImage}
            style={isMobile ? { width: '100%' } : undefined}
          >
            <S.Avatar
              className="avatar"
              size={size}
              onClick={handleChooseImage}
            >
              <S.AvatarPlaceholder src={Placeholder} />
              {invalidImageType && (
                <S.InvalidType>
                  <CloseIcon />
                </S.InvalidType>
              )}
              <S.AvatarText>
                {i18n.t('commons.avatar-profile.drag-image')}
              </S.AvatarText>
            </S.Avatar>
          </DragAndDrop>
        )}
        {!hideBtn && (
          <S.ButtonContainer size={size}>
            <Button small onClick={handleChooseImage}>
              {i18n.t('commons.avatar-profile.button-upload')}
            </Button>
            <S.LimitText invalidImageType={invalidImageType}>
              {invalidImageType
                ? i18n.t('commons.avatar-profile.invalid-type')
                : i18n.t('commons.avatar-profile.limit-image')}
            </S.LimitText>
          </S.ButtonContainer>
        )}
      </S.AvatarContainer>

      {toggleModal && (
        <S.CropModalContainer className="crop-modal-container">
          <S.CropContent className="crop-content">
            <ReactCrop
              src={avatarPreviewAsBlob}
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onImageLoaded={onImageLoaded}
              disabled={false}
            />
          </S.CropContent>

          <S.CropActionsContainer className="btn-container">
            <S.CropButtonContainer
              className="crop-button"
              onClick={handleCropCancel}
            >
              <S.CropButton className="crop-button-cancel" type="cancel">
                <CloseIcon type="small" />
              </S.CropButton>
              <S.CropText>{i18n.t('commons.buttons.cancel-button')}</S.CropText>
            </S.CropButtonContainer>

            <S.CropButtonContainer
              className="crop-button"
              onClick={handleCropSave}
            >
              <S.CropButton className="crop-button-save" type="save">
                <CheckIcon type="small" />
              </S.CropButton>
              <S.CropText>
                {i18n.t('commons.buttons.save-crop-button')}
              </S.CropText>
            </S.CropButtonContainer>
          </S.CropActionsContainer>
        </S.CropModalContainer>
      )}

      <S.Input
        ref={avatarInput}
        type="file"
        id="avatar_input"
        accept="image/*"
        onChange={(event) => handleChangeImage(event)}
      />
    </>
  );
};

UserAvatarInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  setFileSelected: PropTypes.func.isRequired,
  user: PropTypes.object,
  Async: PropTypes.object,
  size: PropTypes.string,
  hideBtn: PropTypes.bool,
  hideTitle: PropTypes.bool,
  large: PropTypes.bool,
  title: PropTypes.string,
};

export default UserAvatarInput;
