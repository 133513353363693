import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

import { Tooltip } from 'modules/Core/Common';
import * as STATUS from './StatusLabel.utils';
import * as S from './StatusLabel.styles';
import i18n from 'i18next';

export const StatusLabel = ({ status, scheduleStatus, totalEvaluation }) => {
  const theme = useTheme();

  const renderStatus = useMemo(() => {
    switch (scheduleStatus || status) {
      case STATUS.PENDING:
        return i18n.t('modules.submissions.status.pending');
      case STATUS.COMPLETE:
      case STATUS.DONE:
        return i18n.t('modules.submissions.status.completed');
      case STATUS.SCHEDULED:
        return i18n.t('modules.submissions.status.scheduled');
      case STATUS.CANCELED:
        return i18n.t('modules.submissions.status.canceled');
      case STATUS.MISSED:
        return i18n.t('modules.submissions.status.missed');
      case STATUS.SKIPPED:
        return i18n.t('modules.submissions.status.skipped');
      case STATUS.PARTIAL:
        return i18n.t('modules.submissions.status.partial');
      default:
        return 'N/A';
    }
  }, [status, scheduleStatus]);

  if (
    [
      i18n.t('modules.submissions.status.pending'),
      i18n.t('modules.submissions.status.partial'),
    ].includes(renderStatus) &&
    totalEvaluation
  ) {
    return (
      <Tooltip
        placement="bottom"
        overlay={`${totalEvaluation.evaluated}/${totalEvaluation.total} Evaluated`}
        overlayInnerStyle={{
          backgroundColor: theme.config.colors.primary,
          borderRadius: '4px',
          padding: '8px 16px',
          fontSize: '1.6rem',
        }}
      >
        <S.Label status={renderStatus}>{renderStatus}</S.Label>
      </Tooltip>
    );
  }

  return <S.Label status={renderStatus}>{renderStatus}</S.Label>;
};

StatusLabel.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  scheduleStatus: PropTypes.oneOfType([PropTypes.string]),
  totalEvaluation: PropTypes.object,
};
