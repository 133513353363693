import { useSelector } from 'react-redux';
import { Selectors as AuthSelectors } from 'modules/Auth/AuthSlice';

const ALLOWED_STATUS_CHANGE_EVALUATOR = ['DONE', 'SCHEDULED', 'MISSED'];
const CATEGORY_SPEAKING = 'speaking';

export function useCanChangeEvaluator() {
  const { user } = useSelector(AuthSelectors.selectAuthData);
  const isSuperAdmin = useSelector(AuthSelectors.isSuperAdmin);

  const canChangeEvaluator = (testCategory, scheduleStatus) => {
    if (!isSuperAdmin && !user?.can_cancel_interview) return false;

    if (
      (!['reading', 'listening'].includes(testCategory) && !scheduleStatus) ||
      (scheduleStatus &&
        ALLOWED_STATUS_CHANGE_EVALUATOR.includes(scheduleStatus))
    ) {
      return true;
    }

    return false;
  };

  const hasScheduleDate = (
    submissionCustomTest,
    scheduleStatus,
    testCategory
  ) => {
    return (
      isSuperAdmin &&
      submissionCustomTest?.base_test?.toUpperCase()?.includes('IELTS') &&
      ['SCHEDULED', 'MISSED'].includes(scheduleStatus) &&
      testCategory === CATEGORY_SPEAKING
    );
  };

  return {
    canChangeEvaluator,
    hasScheduleDate,
  };
}
