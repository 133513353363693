import styled from 'styled-components';

export const Container = styled.div`
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.6rem;
  border-left: ${(props) =>
    props.tooltipVisible && `1px solid ${props.theme.config.colors.gray_300}`};
  position: absolute;
  top: 1px;
  right: 0;
  z-index: ${(props) => (props.tooltipVisible ? '1' : '0')};

  svg {
    background: ${(props) =>
      props.validate
        ? props.theme.config.colors.green
        : props.theme.config.colors.tertiary};
    cursor: pointer;
    border-radius: 25px;
    height: 1.6rem;
    width: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    margin: 0 auto;

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;

export const BarStrong = styled.div`
  background: ${(props) => props.theme.config.colors.gray_200};
  margin: 10px 0 ${(props) => props.theme.config.spacing.spacing_2} 0;
  border-radius: 19px;
  height: 3px;
  width: 100%;
`;

export const LineStrong = styled.div`
  background: ${(props) =>
    props.validate
      ? props.theme.config.colors.green
      : props.theme.config.colors.tertiary};
  border-radius: 19px;
  height: 3px;
  width: ${(props) => (props.validate ? '100%' : '10%')};
`;

export const CheckStrongContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.config.colors.text_base};
  width: 200px;

  ul {
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_2};
    li {
      display: flex;
      align-items: center;
      line-height: ${(props) => props.theme.config.spacing.spacing_4};

      svg {
        width: 12px;
        left: 0;
        margin: 0 3px 0 0;
      }

      :first-child {
        font-weight: 700;
        line-height: ${(props) => props.theme.config.spacing.spacing_5};
        & svg {
          background: ${(props) =>
            props.validate
              ? props.theme.config.colors.green
              : props.theme.config.colors.tertiary};
          margin: 0 9px 0 6px;
          border-radius: 25px;
          font-size: 4px;
          height: 12px;
          width: 12px;
          path {
            fill: ${(props) => props.theme.config.colors.light};
          }
        }
      }
    }
  }
`;
