import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Button, Icon } from 'modules/Core/Common';
import { Selectors } from 'modules/Auth/AuthSlice';
import i18n from 'i18next';

export const GoToAntifraudButton = ({ testType, antifraudId, ...props }) => {
  const { push } = useHistory();
  const permissions = useSelector(Selectors.getPermissions);
  const [antifraudPermission, setAntifraudPermission] = useState(false);

  useEffect(() => {
    permissions?.antifrauds && setAntifraudPermission(true);
  }, [permissions]);

  if (!antifraudId || !antifraudPermission) return null;

  return (
    <Button
      large
      transparent
      onClick={() => push(`/antifraud/${testType}-tests/log/${antifraudId}`)}
      {...props}
    >
      {i18n.t('common-words.e-proctoring')}
      <Icon name="chevron-right" color="#0075ea" fontSize="1.2rem" right />
    </Button>
  );
};

GoToAntifraudButton.defaultProps = {
  testType: 'base',
};

GoToAntifraudButton.propTypes = {
  testType: PropTypes.oneOf(['base', 'custom']),
  antifraudId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
