import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Skeleton } from 'modules/Core/Common';
import { Score } from 'modules/Submissions/Common';
import { getMark, getMarkLabel } from 'modules/Submissions/Utils';
import { isObject } from 'modules/Utils';

import * as S from './Marks.styles';

export const Marks = ({ loading, marks }) => {
  const renderPlaceholder = (
    <S.MarkPlaceholder>
      <h6>Score:</h6>
      <Skeleton type="round" style={{ width: '5rem' }} />
    </S.MarkPlaceholder>
  );

  const renderMark = useMemo(() => {
    if (!marks)
      return (
        <S.MarkContainer>
          <h6>
            Score: <span>Not marked yet.</span>
          </h6>
        </S.MarkContainer>
      );

    return (
      <S.MarkContainer>
        <h6>Score:</h6>
        <Score score={marks} />
      </S.MarkContainer>
    );
  }, [marks]);

  const renderMarksArray = useMemo(() => {
    return (
      <S.MarkContainer>
        <h6>Score:</h6>
        <S.MarksContainer>
          {Array.isArray(marks) &&
            marks?.map((markObj) => (
              <S.MarkWrapper key={markObj}>
                <Score score={getMark(markObj)} />
                <span>{getMarkLabel(markObj)}</span>
              </S.MarkWrapper>
            ))}
        </S.MarksContainer>
      </S.MarkContainer>
    );
  }, [marks]);

  const renderMarksObject = useMemo(() => {
    if (!marks) return null;
    return (
      <S.MarkContainer>
        <h6>Score:</h6>
        <S.MarksContainer>
          {Object.keys(marks)?.map((objKey) => (
            <S.MarkWrapper key={objKey}>
              <Score score={marks[objKey]} />
              <span>{objKey}</span>
            </S.MarkWrapper>
          ))}
        </S.MarksContainer>
      </S.MarkContainer>
    );
  }, [marks]);

  const renderMarks = useMemo(() => {
    if (Array.isArray(marks)) {
      return renderMarksArray;
    } else if (isObject(marks)) {
      return renderMarksObject;
    } else {
      return renderMark;
    }
  }, [marks]);

  return <S.Container>{loading ? renderPlaceholder : renderMarks}</S.Container>;
};

Marks.defaultProps = {
  marks: '',
};

Marks.propTypes = {
  loading: PropTypes.bool,
  marks: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};
