import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 6rem;
`;

export const MarkTotal = styled.div`
  background-color: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 4px;
  color: ${(props) => props.theme.config.colors.primary};
  padding: 0.8rem 1.6rem;
  width: fit-content;
`;
