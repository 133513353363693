import { useState, useEffect } from 'react';

import { useURLSearchQuery } from 'modules/Core/Hooks';

export const INITIAL_SUBMISSIONS_FILTERS_STATE = {
  test_id: '',
  test_tag: '',
  group_id: '',
  evaluator_id: '',
  status_exercise: '',
  show_certification: 'ALL',
};

export const useSubmissionsFilters = () => {
  const [submissionFilters, setSubmissionFilters] = useState(
    INITIAL_SUBMISSIONS_FILTERS_STATE
  );
  const { queryObject } = useURLSearchQuery();

  useEffect(() => {
    setSubmissionFilters({
      ...submissionFilters,
      evaluator_id: queryObject?.evaluator_id || '',
      status_exercise: queryObject?.status_exercise || '',
      test_id: queryObject?.test_id || '',
      group_id: queryObject?.group_id || '',
      test_tag: queryObject?.test_tag || '',
      show_certification: queryObject?.show_certification,
    });
  }, []);

  const updateSubmissionFilter = (submissionFilter) => {
    const { filterName } = submissionFilter;

    if (filterName) {
      setSubmissionFilters({
        ...submissionFilters,
        [filterName]: submissionFilter.id,
      });
    } else {
      setSubmissionFilters({
        ...submissionFilters,
        ...submissionFilter,
      });
    }
  };

  const clearSubmissionFilter = (submissionFilter) => {
    const { filterName } = submissionFilter;
    setSubmissionFilters({
      ...submissionFilters,
      [filterName]: null,
    });
  };

  const clearAllSubmissionFilters = () => {
    setSubmissionFilters({
      ...INITIAL_SUBMISSIONS_FILTERS_STATE,
    });
  };

  return {
    submissionFilters,
    updateSubmissionFilter,
    clearSubmissionFilter,
    clearAllSubmissionFilters,
  };
};
