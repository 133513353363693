import styled from 'styled-components';

export const Container = styled.div`
  padding: 4rem 0;
`;

export const ProgressBar = styled.div`
  margin: 0 16px 0 8px;
  display: flex;
  flex: 1;
  object-fit: cover;
`;
