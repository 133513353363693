import React from 'react';
import PropTypes from 'prop-types';
import { Audio } from 'modules/Core/Common/Audio';

const AnswerAudio = ({ speakingAnswer }) => <Audio url={speakingAnswer} />;

AnswerAudio.propTypes = {
  speakingAnswer: PropTypes.string,
};

export default AnswerAudio;
