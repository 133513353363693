import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Row, Col, MUICheckbox, MUIRadioGroup } from 'modules/Core/Common';
import * as S from './LicenseRequirements.styles';

export const LicenseRequirements = ({ data, onChange }) => {
  return (
    <S.Container>
      <h2>{i18n.t('modules.licenses.license-forms.requirements.title')}</h2>
      <Row>
        <Col xs={12} sm={6} md={3}>
          <MUICheckbox
            checked={data?.photoStudent}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.photo-student'
            )}
            onChange={({ target }) => onChange('photoStudent', target.checked)}
          />
          <S.GroupBoolean>
            <MUICheckbox
              checked={data?.photoUploadId}
              label={i18n.t(
                'modules.licenses.license-forms.requirements.photo-upload'
              )}
              onChange={({ target }) =>
                onChange('photoUploadId', target.checked)
              }
            />
            {data?.photoUploadId && (
              <S.RadioGroup>
                <MUIRadioGroup
                  value={data?.photoUploadType}
                  data={[
                    {
                      value: 'ID',
                      label: i18n.t(
                        'modules.licenses.license-forms.requirements.id'
                      ),
                    },
                    {
                      value: 'PASSPORT',
                      label: i18n.t(
                        'modules.licenses.license-forms.requirements.passport'
                      ),
                    },
                  ]}
                  onChange={({ target }) =>
                    onChange('photoUploadType', target.value)
                  }
                />
              </S.RadioGroup>
            )}
          </S.GroupBoolean>
          <MUICheckbox
            checked={data?.screenShare}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.screen-share'
            )}
            onChange={({ target }) => onChange('screenShare', target.checked)}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <MUICheckbox
            checked={data?.takePictureNextId}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.take-picture'
            )}
            onChange={({ target }) =>
              onChange('takePictureNextId', target.checked)
            }
          />
          <MUICheckbox
            checked={data?.roomScan360}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.room-scan'
            )}
            onChange={({ target }) => onChange('roomScan360', target.checked)}
          />
          <MUICheckbox
            checked={data?.roomScanRandom}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.random-room-scan'
            )}
            onChange={({ target }) =>
              onChange('roomScanRandom', target.checked)
            }
          />
          <MUICheckbox
            checked={data?.enableUserScreenRecording}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.screen_recording'
            )}
            onChange={({ target }) =>
              onChange('enableUserScreenRecording', target.checked)
            }
          />
          <MUICheckbox
            checked={data?.enableUserVideoRecording}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.video_recording'
            )}
            onChange={({ target }) =>
              onChange('enableUserVideoRecording', target.checked)
            }
          />
          <MUICheckbox
            checked={data?.enableUserAudioRecording}
            label={i18n.t(
              'modules.licenses.license-forms.requirements.audio_recording'
            )}
            onChange={({ target }) =>
              onChange('enableUserAudioRecording', target.checked)
            }
          />
        </Col>
      </Row>
    </S.Container>
  );
};

LicenseRequirements.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};
