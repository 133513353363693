import React from 'react';

import { Skeleton } from 'modules/Core/Common';

import * as S from './Answers.styles';

export const AnswersPlaceholder = () => {
  return (
    <S.AnswersContainer>
      <S.AnswersContainerTitle>
        <S.AnswersTitle style={{ width: '500px' }}>
          <Skeleton type="round" roundLarge isUser style={{ width: '75%' }} />
        </S.AnswersTitle>
      </S.AnswersContainerTitle>
      <S.AnswerContent>
        <Skeleton type="string" style={{ width: '9rem' }} />
        <Skeleton
          type="string"
          style={{ marginTop: '0.8rem', width: '40rem', maxWidth: '100%' }}
        />
        <Skeleton
          type="string"
          style={{ marginTop: '2.4rem', width: '9rem' }}
        />
        <Skeleton
          type="string"
          style={{ marginTop: '0.8rem', width: '40rem', maxWidth: '100%' }}
        />
        <Skeleton
          type="string"
          style={{ marginTop: '3.2rem', width: '40rem', maxWidth: '100%' }}
        />
        <Skeleton
          type="string"
          style={{ marginTop: '2.4rem', width: '40rem', maxWidth: '100%' }}
        />
        <Skeleton
          type="string"
          style={{ marginTop: '2.4rem', width: '40rem', maxWidth: '100%' }}
        />
        <Skeleton
          type="string"
          style={{ marginTop: '2.4rem', width: '15rem' }}
        />
        <Skeleton type="large" style={{ marginTop: '1.6rem', width: '100%' }} />
      </S.AnswerContent>
    </S.AnswersContainer>
  );
};
