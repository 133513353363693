import React from 'react';
import PropTypes from 'prop-types';
import { addMinutes } from 'date-fns';

import { Button, Icon } from 'modules/Core/Common';
import { format } from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';
import i18n from 'i18next';

import * as S from './ChildInformation.styles';

const ChildInformation = ({ data, columnsSize, onCancelInterviewClick }) => {
  const {
    id,
    total_answers_evaluated,
    total_answers_speaking,
    total_answers_writing,
    schedule_status,
    created_at,
  } = data;

  const { userTimeFormatSeconds, userDateFormat } = useAuth();

  const renderEvaluatedResponsesTotals = React.useMemo(() => {
    const totalAnswersEvaluated = total_answers_evaluated || 0;
    const totalAnswersSpeaking = total_answers_speaking || 0;
    const totalAnswersWriting = total_answers_writing || 0;
    const totalAnswersSpeakingWriting =
      totalAnswersSpeaking + totalAnswersWriting;

    return `${totalAnswersEvaluated}/${totalAnswersSpeakingWriting}`;
  }, [total_answers_evaluated, total_answers_speaking, total_answers_writing]);

  return (
    <S.AnimatedTr>
      <S.ContainerTd colSpan={columnsSize}>
        <S.InfoRow>
          <S.Text>
            <Icon name="submission" highlight />
            {i18n.t('modules.submissions.common.table.columns.submissions-id')}
            <S.Results highlight>{id}</S.Results>
          </S.Text>

          <S.Text>
            <Icon name="time" highlight />
            {i18n.t(
              'modules.submissions.common.table.columns.evaluated-response'
            )}
            <S.Results highlight>{renderEvaluatedResponsesTotals}</S.Results>
          </S.Text>

          <S.Text>
            <Icon name="calendar" highlight />
            {i18n.t('modules.submissions.common.table.columns.submission-date')}
            <S.Results highlight>
              <>
                {format(
                  addMinutes(
                    new Date(created_at),
                    new Date(created_at).getTimezoneOffset()
                  ),
                  userDateFormat
                )}
                <S.HourLabel>
                  {format(
                    addMinutes(
                      new Date(created_at),
                      new Date(created_at).getTimezoneOffset()
                    ),
                    userTimeFormatSeconds
                  )}
                </S.HourLabel>
              </>
            </S.Results>
          </S.Text>

          {schedule_status === 'SCHEDULED' && (
            <S.CancelInterview>
              <Button medium onClick={() => onCancelInterviewClick(id)}>
                {i18n.t('commons.buttons.cancel-interview-button')}
              </Button>
            </S.CancelInterview>
          )}
        </S.InfoRow>
      </S.ContainerTd>
    </S.AnimatedTr>
  );
};

ChildInformation.propTypes = {
  data: PropTypes.object.isRequired,
  columnsSize: PropTypes.number.isRequired,
  onCancelInterviewClick: PropTypes.func.isRequired,
};

export default ChildInformation;
