export const CEFR_NAME = 'CEFR';
export const MCER_NAME = 'MCER';
export const TOEIC_LR_NAME = 'TOEIC_LR';
export const A2Flyers_NAME = 'A2_FLYERS';
export const PLACEMENT_NAME = 'PLACEMENT';
export const PLACEMENT_TEST_NAME = 'PLACEMENT_TEST';

export const CATEGORY_FULL = 'full';
export const CATEGORY_READING = 'reading';
export const CATEGORY_LISTENING = 'listening';
export const CATEGORY_SPEAKING = 'speaking';
export const CATEGORY_WRITING = 'writing';
