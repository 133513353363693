import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Box } from '@mui/material';
import { Can } from 'hoc/Ability';
import { TableActions, TableAction } from 'modules/Core/Common';
import { EmailsSent, EmailsSentNumber } from './styles';
import { SendEmailIcon, SendingEmailIcon } from '@edusynch/edusynch-svg-icons';
import { STUDENTS } from 'modules/Users/Utils';
import { useActivationEmailBlocked } from 'modules/Users/Hooks/useActivationEmailBlocked';

const BUTTON_PREFIX = 'commons.buttons.actions-buttons';

const Actions = ({
  id,
  email,
  activation_mail_amount_sent,
  activation_mail_sent_at,
  onCopyEmail,
  onEdit,
  onNewPassword,
  onDelete,
  onOpenSubscription,
  subject,
  isDeleting,
  subscription,
  onSendEmail,
  isSendingEmail,
  whitelabel,
}) => {
  const { isActivationEmailBlocked, formattedDate } = useActivationEmailBlocked(
    activation_mail_sent_at
  );

  const isStudents = subject === STUDENTS;
  const showNumberEmailsSent =
    !isSendingEmail && activation_mail_amount_sent && !isActivationEmailBlocked;
  const showBlockedEmail = !isSendingEmail && isActivationEmailBlocked;

  return (
    <TableActions>
      {isStudents && (
        <>
          {email && (
            <Box sx={{ ml: 0.5 }}>
              <TableAction
                disabled={showBlockedEmail}
                tooltip={
                  <div>
                    <span>
                      {isSendingEmail
                        ? i18n.t(`${BUTTON_PREFIX}.sending-email`)
                        : showBlockedEmail
                        ? i18n.t(`${BUTTON_PREFIX}.blocked-email`)
                        : i18n.t(`${BUTTON_PREFIX}.send-email`)}
                    </span>
                    {!isSendingEmail && activation_mail_amount_sent && (
                      <EmailsSent>
                        {i18n.t(`${BUTTON_PREFIX}.emails-sent`)}:{' '}
                        {activation_mail_amount_sent}
                      </EmailsSent>
                    )}
                    {!isSendingEmail && showBlockedEmail && (
                      <EmailsSent>
                        {i18n.t(`${BUTTON_PREFIX}.available-at`)}{' '}
                        {formattedDate}
                      </EmailsSent>
                    )}
                  </div>
                }
                isSendingEmail={isSendingEmail}
                onClick={() => !showBlockedEmail && onSendEmail(email)}
              >
                {showNumberEmailsSent ? (
                  <EmailsSentNumber>
                    {activation_mail_amount_sent}
                  </EmailsSentNumber>
                ) : null}
                {isSendingEmail ? (
                  <SendingEmailIcon width="14px" height="14px" />
                ) : (
                  <SendEmailIcon width="14px" height="14px" />
                )}
              </TableAction>
            </Box>
          )}

          {!whitelabel?.plus_edition && (
            <TableAction
              tooltip={i18n.t(`${BUTTON_PREFIX}.subscription`)}
              iconName="pricing"
              onClick={() =>
                onOpenSubscription({
                  ...(!subscription && { student_id: id }),
                  ...subscription,
                })
              }
            />
          )}
        </>
      )}

      {onCopyEmail && email && (
        <TableAction
          tooltip={i18n.t(`${BUTTON_PREFIX}.copy-email`)}
          iconName="email"
          onClick={() => onCopyEmail(email)}
        />
      )}

      <Can I="update" a={subject}>
        <TableAction
          tooltip={i18n.t(`${BUTTON_PREFIX}.edit`)}
          iconName="edit"
          onClick={() => onEdit(id)}
          data-cy="edit-btn"
        />
      </Can>

      {onNewPassword && (
        <TableAction
          tooltip={i18n.t(`${BUTTON_PREFIX}.new-password`)}
          iconName="key"
          onClick={() => onNewPassword(id)}
        />
      )}

      <Can I="destroy" a={subject}>
        <TableAction
          tooltip={i18n.t(`${BUTTON_PREFIX}.delete`)}
          iconName="delete"
          isConfirm
          isLoading={isDeleting}
          onClick={() => onDelete(id)}
          data-cy="delete-btn"
        />
      </Can>
    </TableActions>
  );
};

Actions.propTypes = {
  id: PropTypes.number,
  activation_mail_amount_sent: PropTypes.number,
  activation_mail_sent_at: PropTypes.string,
  onEdit: PropTypes.func,
  onNewPassword: PropTypes.func,
  onDelete: PropTypes.func,
  onSendEmail: PropTypes.func,
  email: PropTypes.string,
  subject: PropTypes.string,
  onCopyEmail: PropTypes.func,
  onOpenSubscription: PropTypes.func,
  isDeleting: PropTypes.bool,
  subscription: PropTypes.object,
  isSendingEmail: PropTypes.bool,
  whitelabel: PropTypes.object,
};

export default Actions;
