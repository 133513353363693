import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  top: 0;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.light};
  z-index: 1;
  height: 6rem;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px 6px 0 0;

  h1 {
    ${(props) => props.theme.config.typograph.quartenary};
    margin: 2.4rem 0 2.4rem 3.2rem;
  }

  @media ${device.mobile} {
    h1 {
      ${(props) => props.theme.config.typograph.xsmall};
      font-weight: bold;
      margin: 1.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      max-width: 10.4rem;

      div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  button {
    & + button {
      margin-left: 2.4rem;
    }
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1.8rem 2rem;
  width: 60px;
  text-align: center;
  background-color: ${(props) => props.theme.config.colors.gray_100};
  border: none;
  outline: none;
  border-radius: 0 8px 0 0;

  svg path {
    fill: ${(props) => props.theme.config.colors.text_base};
  }
`;
