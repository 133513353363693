import styled from 'styled-components';
import { shade } from 'polished';
import { device } from 'modules/Utils';

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  padding: 0 1.5em;
  margin-left: 2.4rem;
  background-color: ${(props) =>
    props.canEvaluate
      ? props.theme.config.colors.gray_300
      : props.theme.config.colors.primary};
  color: ${(props) => props.theme.config.colors.light};
  border: none;
  border-radius: 3.5rem;
  cursor: pointer;
  outline: none;
  transition: background-color 0.2s ease;
  height: 48px;
  ${(props) => props.theme.config.typograph.default};

  &:focus,
  &:active,
  &:hover {
    background-color: ${(props) =>
      props.canEvaluate ? shade(0.2, '#C6C5CE') : shade(0.2, '#0075EA')};
  }

  @media ${device.tablet} {
    ${(props) => props.theme.config.typograph.xsmall};
    padding: 1.3rem 1em;
  }

  svg {
    width: 1.4rem;
    margin-right: 0.7rem;

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;

export const Subtitle = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  text-align: center;
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};
`;

export const GradesStrengthsAndRecomendations = styled.div`
  padding: 3.2rem;
  margin-bottom: 20px;
  background: ${(props) => props.theme.config.colors.gray_100};

  .strengths-and-recommendations {
    margin-top: 1.6rem;
  }
`;

export const Container = styled.div`
  padding: 4rem 12rem;

  @media ${device.tablet} {
    padding: 4rem 0;
  }

  @media ${device.mobile} {
    padding: 4rem 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;

  button {
    &:first-child {
      margin-right: 1.6rem;
    }
  }
`;

export const RightSide = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

export const OtherGrades = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  display: flex;
  align-items: center;
`;

export const RLGrade = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  display: flex;
  align-items: center;
  padding-left: 2.4rem;

  strong {
    margin-right: 1.6rem;
  }

  span {
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
    background-color: ${(props) => props.theme.config.colors.primary_200};
    color: ${(props) => props.theme.config.colors.primary};
  }
`;
