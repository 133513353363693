import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Toast, ResponseDataHandle } from 'modules/Core/Common';
import { AnswersTable, ToggleButton } from 'modules/Submissions/Common/Answers';

export const AnswersList = ({
  additionalColumns,
  columnsPlaceholder,
  data,
  loading,
  error,
  onNextPage,
  onChangePerPage,
  clearError,
  toggleId,
  childInformation,
  handleToggle,
  isAdmin,
  onAiEvaluate,
}) => {
  useEffect(() => {
    if (error) Toast(error, 'info');
    return () => clearError();
  }, [error]);

  const actions = () => ({
    handleToggle: handleToggle,
    toggledId: toggleId,
    isAdmin,
    onAiEvaluate,
  });

  return (
    <ResponseDataHandle
      data={data}
      loading={loading}
      iconType="submission"
      columns={columnsPlaceholder}
      render={() => (
        <AnswersTable
          data={data}
          actions={actions()}
          firstItemSize={'4rem'}
          onNextPage={onNextPage}
          type="submissionAnswers"
          toggleChild={toggleId}
          tdSpacing={'1.4rem 1.2rem'}
          thSpacing={'1.4rem 1.2rem'}
          toggleButton={ToggleButton}
          toggledChildren={childInformation}
          additionalColumns={additionalColumns}
          onToggleClick={onChangePerPage}
        />
      )}
    />
  );
};

AnswersList.propTypes = {
  additionalColumns: PropTypes.array,
  columnsPlaceholder: PropTypes.array,
  data: PropTypes.shape({
    total: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
    search: PropTypes.string,
    answers: PropTypes.array,
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
  onNextPage: PropTypes.func,
  onChangePerPage: PropTypes.func,
  clearError: PropTypes.func,
  toggleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleToggle: PropTypes.func,
  isAdmin: PropTypes.bool,
  childInformation: PropTypes.element,
  onAiEvaluate: PropTypes.func,
};
