const getQuestionByType = (type) => {
  if (!type) return;
  return type.replaceAll(' ', '_').toLowerCase();
};

const getLetterByOrder = (question_numer, index) => {
  if (question_numer) return question_numer;

  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  return letters[index];
};

const replaceKeyByWord = (text, word) => {
  return text.replaceAll(
    '%ANSWER%',
    `<span class="highlight">${word || '-'}</span>`
  );
};

const replaceKeyByWordAndNumber = (text, word, questionNumber) => {
  return text.replaceAll(
    '%ANSWER%',
    `<span class="question-number">${questionNumber}</span> <span class="highlight">${
      word || '-'
    }</span>`
  );
};

const addKeyOnInitial = (text, word) => {
  return `<span class="highlight">${word || '-'}</span> ${text}`;
};

export {
  getQuestionByType,
  getLetterByOrder,
  replaceKeyByWord,
  addKeyOnInitial,
  replaceKeyByWordAndNumber,
};
