import { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Formik } from 'formik';
import { FormHelperText } from '@mui/material';
import { parseISO } from 'date-fns';

import {
  Modal,
  Button,
  Icon,
  Title,
  SearchDropdown,
  MUIDateTimePicker,
} from 'modules/Core/Common';
import { UserAvatar } from 'modules/Core/Common';
import { useDate } from 'modules/Core/Hooks/useDate';
import { isMinuteZeroOrThirty } from 'modules/Utils/Date';
import ChangeSelectedEvaluatorSchema from './ChangeSelectedEvaluatorSchema';

import * as S from './ChangeSelectedEvaluatorModal.styles';

const notEqualToZeroOrThirty = (timeValue, clockType) =>
  clockType === 'minutes' && timeValue !== 0 && timeValue !== 30;

export const ChangeSelectedEvaluatorModal = ({
  isOpen,
  onClose,
  evaluators,
  loading,
  onSubmit,
  hasScheduleDate,
}) => {
  const { date: minDate } = useDate();

  const evaluatorsWithAvatar = useMemo(() => {
    return evaluators?.map((evaluator) => ({
      ...evaluator,
      customOption: <UserAvatar {...evaluator} />,
      additionalInfo: evaluator?.evaluator_associations?.map(
        (item) => item?.evaluatable_name
      ),
    }));
  }, [evaluators]);

  return (
    <Modal width="480px" isOpen={isOpen} setIsOpen={onClose} overflow="unset">
      <Formik
        initialValues={{ evaluatorId: '', scheduleDate: minDate }}
        validationSchema={ChangeSelectedEvaluatorSchema}
        onSubmit={(data) =>
          onSubmit({
            ...data,
            scheduleDate: hasScheduleDate ? data.scheduleDate : undefined,
          })
        }
      >
        {({ values, setFieldValue, errors, handleSubmit }) => (
          <S.Form onSubmit={handleSubmit} noValidate>
            <S.FormBody>
              <Title tag="h2" size="quartenary">
                {i18n.t('commons.change-evaluator.change-evaluator')}
              </Title>
              <S.EvaluatorTitle>
                {i18n.t('commons.change-evaluator.evaluator')}
              </S.EvaluatorTitle>
              <SearchDropdown
                placeholder={i18n.t(
                  'commons.change-evaluator.change-evaluator'
                )}
                options={evaluatorsWithAvatar}
                value={values?.evaluatorId ? [values.evaluatorId] : []}
                loading={loading}
                onChangeSelect={(id) => setFieldValue('evaluatorId', id)}
                onChangeUnselect={() => setFieldValue('evaluatorId', null)}
              />
              {!!errors?.evaluatorId && (
                <FormHelperText
                  error
                  sx={{
                    fontSize: '12px',
                    ml: 2,
                  }}
                >
                  {i18n.t('commons.change-evaluator.evaluator-is-required')}
                </FormHelperText>
              )}

              {hasScheduleDate && (
                <S.ScheduleDate>
                  <S.ScheduleTitle>
                    {i18n.t('commons.change-evaluator.schedule')}
                  </S.ScheduleTitle>
                  <MUIDateTimePicker
                    date={values.scheduleDate}
                    onChange={(date) => setFieldValue('scheduleDate', date)}
                    minDate={minDate}
                    inputProps={{ readOnly: true }}
                    shouldDisableTime={notEqualToZeroOrThirty}
                  />
                  {!isMinuteZeroOrThirty(parseISO(values.scheduleDate)) && (
                    <FormHelperText
                      error
                      sx={{
                        fontSize: '12px',
                        ml: 2,
                      }}
                    >
                      {i18n.t('commons.change-evaluator.invalid-schedule-date')}
                    </FormHelperText>
                  )}
                </S.ScheduleDate>
              )}

              <S.Footer>
                <Button
                  large
                  type="submit"
                  data-test="submit"
                  hasLoading
                  loading={loading}
                  disabled={
                    loading ||
                    !!errors?.evaluatorId ||
                    (hasScheduleDate &&
                      !isMinuteZeroOrThirty(parseISO(values.scheduleDate)))
                  }
                >
                  {i18n.t('commons.change-evaluator.save-change')}
                </Button>
              </S.Footer>

              <S.ButtonClose type="button" onClick={onClose}>
                <Icon fontSize="16px" name="close" />
              </S.ButtonClose>
            </S.FormBody>
          </S.Form>
        )}
      </Formik>
    </Modal>
  );
};

ChangeSelectedEvaluatorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  evaluators: PropTypes.array.isRequired,
  hasScheduleDate: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
