import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Row, Col, Switch, Skeleton } from 'modules/Core/Common';
import {
  getEnabledTestFieldNameByTestTag,
  getHideTestFieldNameByTestTag,
} from 'modules/Users/Students/Utils';

import {
  TestsListHeader,
  TestsListHeaderTitle,
  TestsListHeaderActions,
  TestsList,
  TestsListItem,
  TestsListItemLabel,
  TestsListItemActions,
  SkeletonContainerTitle,
  SkeletonContainerRow,
} from './styles';

const TestPlaceholder = ({ marginTop }) => (
  <>
    <SkeletonContainerTitle marginTop={marginTop}>
      <Skeleton type="string" />
      <Skeleton type="string" />
    </SkeletonContainerTitle>
    <SkeletonContainerRow>
      <Skeleton type="string" />
    </SkeletonContainerRow>
  </>
);

TestPlaceholder.propTypes = {
  marginTop: PropTypes.bool,
};

const UserTestConfiguration = ({
  student,
  customTests = [],
  customTestsLoading,
  baseTests = [],
  onTestChange,
  onCustomTestChange,
}) => {
  const [selectedCustomTests, setSelectedCustomTests] = useState([]);
  const [baseTestsToRender, setBaseTestsToRender] = useState(baseTests);
  const [customTestsToRender, setCustomTestsToRender] = useState(customTests);

  useEffect(() => {
    baseTests?.length && setBaseTestsToRender(baseTests);
  }, [baseTests]);

  useEffect(() => {
    if (customTests?.length === 0) {
      setCustomTestsToRender([]);
    } else if (customTests?.length) {
      setCustomTestsToRender(customTests);
    }
  }, [customTests]);

  useEffect(() => {
    // if it is initial state
    if (!selectedCustomTests.length && student?.customTests.length > 0) {
      const studentCustomTests = student.customTests
        ?.filter((customTest) => {
          if (Object.prototype.hasOwnProperty.call(customTest, 'enable_test')) {
            return customTest?.enable_test;
          }
          return true;
        })
        .map((customTest) => {
          return {
            custom_test_id: customTest.id,
            whitelabel_id: customTest.whitelabel_id,
          };
        });
      setSelectedCustomTests(studentCustomTests);
    }
  }, [student.customTests]);

  useEffect(() => {
    if (selectedCustomTests.length > 0) {
      const parsedCustomTests = selectedCustomTests.map((customTest) => ({
        custom_test_id: customTest.custom_test_id,
        whitelabel_id: customTest.whitelabel_id,
      }));
      onCustomTestChange(parsedCustomTests);
    } else {
      onCustomTestChange([]);
    }
  }, [selectedCustomTests]);

  function handleTestChange(event) {
    const testName = event.target.name;
    const testNameHideToSetFalse = testName.replace(
      i18n.t(
        'modules.users.students.student-form.test-configuration.columns.enabled'
      ),
      i18n.t(
        'modules.users.students.student-form.test-configuration.columns.hide'
      )
    );

    if (
      testName.includes(
        i18n.t(
          'modules.users.students.student-form.test-configuration.columns.enabled'
        )
      )
    ) {
      onTestChange(testName, !student[testName]);
      onTestChange(testNameHideToSetFalse, false);
    } else {
      onTestChange(testNameHideToSetFalse, !student[testNameHideToSetFalse]);
    }
  }

  function handleCustomTestChange(event) {
    const customTestId = event.target.value;
    const customTest = customTestsToRender.find(
      (test) => +test.id === +customTestId
    );
    const customTestExists = selectedCustomTests.find(
      (selectedTest) => selectedTest.custom_test_id === Number(customTestId)
    );

    const checkIfCustomTestIsOnList = (id) =>
      customTestsToRender.find((item) => item.id === id);

    if (customTestExists) {
      setSelectedCustomTests(
        selectedCustomTests.filter(
          (selectedTest) => selectedTest.custom_test_id !== Number(customTestId)
        )
      );
    } else {
      setSelectedCustomTests([
        ...selectedCustomTests.filter((selectedCustomTest) =>
          checkIfCustomTestIsOnList(selectedCustomTest.custom_test_id)
        ),
        {
          custom_test_id: customTest.id,
          whitelabel_id: customTest.whitelabel_id || customTest.whitelabel?.id,
        },
      ]);
    }
  }

  function isCustomTestSelected(testId) {
    return selectedCustomTests.some(
      (customTest) =>
        +customTest.custom_test_id === +testId || +customTest.id === +testId
    );
  }
  console.log(baseTestsToRender);
  return (
    <Row>
      <Col xs={12}>
        {!baseTestsToRender?.length ? (
          <TestPlaceholder />
        ) : (
          <>
            <TestsListHeader>
              <TestsListHeaderTitle size="xsmall" tag="h3">
                {i18n.t(
                  'modules.users.students.student-form.test-configuration.columns.tests'
                )}
              </TestsListHeaderTitle>
              <TestsListHeaderActions>
                <TestsListHeaderTitle size="xsmall" tag="h3">
                  {i18n.t(
                    'modules.users.students.student-form.test-configuration.columns.enabled'
                  )}
                </TestsListHeaderTitle>
                <TestsListHeaderTitle size="xsmall" tag="h3">
                  {i18n.t(
                    'modules.users.students.student-form.test-configuration.columns.hide'
                  )}
                </TestsListHeaderTitle>
              </TestsListHeaderActions>
            </TestsListHeader>
            <TestsList>
              {baseTestsToRender?.map((test) => (
                <TestsListItem hasHideSwitch key={test.id}>
                  <TestsListItemLabel>{test.name}</TestsListItemLabel>
                  <TestsListItemActions hasHideSwitch>
                    <Switch
                      id={getEnabledTestFieldNameByTestTag(test.tag)}
                      name={getEnabledTestFieldNameByTestTag(test.tag)}
                      active={
                        student[getEnabledTestFieldNameByTestTag(test.tag)]
                      }
                      onChange={(event) => handleTestChange(event)}
                    />
                    {!student[getEnabledTestFieldNameByTestTag(test.tag)] && (
                      <Switch
                        id={getHideTestFieldNameByTestTag(test.tag)}
                        name={getHideTestFieldNameByTestTag(test.tag)}
                        active={
                          student[getHideTestFieldNameByTestTag(test.tag)]
                        }
                        onChange={(event) => handleTestChange(event)}
                      />
                    )}
                  </TestsListItemActions>
                </TestsListItem>
              ))}
            </TestsList>
          </>
        )}

        {customTestsLoading ? (
          <TestPlaceholder marginTop />
        ) : (
          !!customTestsToRender?.length && (
            <>
              <TestsListHeader
                style={{ marginTop: '32px' }}
                className="custom-tests-header"
              >
                <TestsListHeaderTitle size="default" tag="h2">
                  {i18n.t(
                    'modules.users.students.student-form.test-configuration.columns.custom-tests'
                  )}
                </TestsListHeaderTitle>
                <TestsListHeaderActions>
                  <TestsListHeaderTitle size="xsmall" tag="h2">
                    {i18n.t(
                      'modules.users.students.student-form.test-configuration.columns.enabled'
                    )}
                  </TestsListHeaderTitle>
                </TestsListHeaderActions>
              </TestsListHeader>
              <TestsList>
                {customTestsToRender.map((test) => (
                  <TestsListItem key={test.id}>
                    {test.name}
                    <TestsListItemActions
                      style={{ justifyContent: 'flex-end' }}
                    >
                      <Switch
                        name="customTests"
                        value={test.id}
                        active={isCustomTestSelected(test.id)}
                        onChange={(event) => handleCustomTestChange(event)}
                      />
                    </TestsListItemActions>
                  </TestsListItem>
                ))}
              </TestsList>
            </>
          )
        )}
      </Col>
    </Row>
  );
};

UserTestConfiguration.propTypes = {
  student: PropTypes.object.isRequired,
  customTests: PropTypes.array.isRequired,
  customTestsLoading: PropTypes.bool,
  baseTests: PropTypes.array,
  onTestChange: PropTypes.func.isRequired,
  onCustomTestChange: PropTypes.func.isRequired,
};

export default UserTestConfiguration;
