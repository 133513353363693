import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  h6 {
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
    align-self: center;
  }
`;

export const Strengths = styled.div`
  flex: 1;
  display: grid;
  gap: 1.6rem;
`;

export const Recommendations = styled.div`
  flex: 1;
  display: grid;
  gap: 1.6rem;

  @media ${device.mobile} {
    margin-top: 1.6rem;
  }
`;

export const BooleanContainer = styled.div`
  display: flex;
  align-items: center;

  label:first-child {
    flex-shrink: 0;
  }

  label:last-child {
    padding-bottom: 0;
    margin-left: 1.6rem;
  }
`;
