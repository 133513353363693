import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../../AnswerSheet.styles';
import { getAlternativeByCondition } from './QuestionTypes.base';

const MultipleChoice = ({ alternatives }) => (
  <>
    {alternatives?.map((alternative, index) => (
      <S.AlternativeContainer
        key={alternative.alternative_id}
        correct={alternative.right && alternative.chosen_by_user}
        wrongChosen={!alternative.right && alternative.chosen_by_user}
        rightNotChosen={alternative.right && !alternative.chosen_by_user}
      >
        {getAlternativeByCondition(alternative, index)}
        <S.AlternativeText
          dangerouslySetInnerHTML={{
            __html: alternative?.alternative_description,
          }}
        />
      </S.AlternativeContainer>
    ))}
  </>
);

MultipleChoice.propTypes = {
  alternatives: PropTypes.array,
};

export default MultipleChoice;
