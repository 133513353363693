import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 1rem;

  button {
    svg {
      margin-right: 8px;

      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;
