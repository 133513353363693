import styled from 'styled-components';
import {
  getColorFromStatus,
  getBackgroundColorFromStatus,
} from 'modules/Submissions/Common/StatusLabel/StatusLabel.styles';

export const StudentCard = styled.div`
  padding: 2.6rem 2.6rem 1rem;
  border-radius: 0.6rem;
  width: 100%;
  border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  transition: box-shadow 0.2s;
  cursor: pointer;

  img {
    width: 4rem;
    height: 4rem;
  }

  span[data-test='avatar-user-name'] {
    margin-left: 0.8rem;
    color: ${(props) => props.theme.config.colors.text_base};
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.config.colors.gray_400};
    cursor: pointer;
    ${(props) => props.theme.config.typograph.mico};

    svg {
      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }

  &:hover {
    button {
      &:not([disabled]) {
        color: ${(props) => props.theme.config.colors.primary};

        svg {
          path {
            fill: ${(props) => props.theme.config.colors.primary};
          }
        }
      }
    }
  }

  button {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${(props) => props.theme.config.colors.gray_400};

    svg {
      path {
        fill: ${(props) => props.theme.config.colors.gray_400};
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const Empty = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 130px;
  margin: auto;
  ${(props) => props.theme.config.typograph.text_base};
  color: ${(props) => props.theme.config.colors.gray_400};

  div {
    background: ${(props) => props.theme.config.colors.primary_100};
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.4rem;

    svg {
      width: 2.2rem;

      path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    }
  }
`;

export const StudentAvatar = styled.div`
  height: 4rem;
`;

export const StudentCardBody = styled.div`
  margin-top: 1.6rem;
  display: flex;

  > div {
    width: 50%;
  }

  h6 {
    ${(props) => props.theme.config.typograph.mico};
    color: ${(props) => props.theme.config.colors.gray_400};
    font-weight: 700;
    margin-bottom: 0.4rem;
  }

  p {
    margin: 0;
    ${(props) => props.theme.config.typograph.xsmall};
  }
`;

export const Category = styled.div`
  p {
    color: ${(props) => props.theme.config.colors.text_base};
  }
`;

export const Date = styled.div`
  p {
    color: ${(props) => props.theme.config.colors.text_base};
  }

  span {
    color: ${(props) => props.theme.config.colors.gray_400};
  }
`;

export const Evaluated = styled.div`
  margin-top: 1.6rem;
`;

export const EvaluatedLabel = styled.span`
  ${(props) => props.theme.config.typograph.mico};
  display: inline-flex;
  align-items: center;
  padding: 0.4rem 0.8rem;
  background: ${(props) => getBackgroundColorFromStatus(props)};
  color: ${(props) => getColorFromStatus(props)};

  svg {
    width: 0.8rem;
    height: 0.8rem;

    path {
      fill: ${(props) => getColorFromStatus(props)};
    }
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.config.colors.gray_200};
  margin: 1.6rem auto 0.8rem;
`;
