import React from 'react';
import PropTypes from 'prop-types';

import {
  StudentAnswers,
  AnswersContainer,
  ParagraphComponent,
  CommonTextComponent,
  AnswersPlaceholder,
} from 'modules/Submissions/Common/Answers';
import { EditIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

const AnswersWritingContainer = ({ answers, loading, ...props }) => {
  const {
    passage,
    question,
    writing_answer: writingAnswer,
    test_type: testType,
  } = answers;

  if (loading) return <AnswersPlaceholder />;

  return (
    <AnswersContainer
      IconName={EditIcon}
      title="Writing"
      testType={testType}
      wordCount={answers?.writing_answer_size}
      timeSpent={answers?.duration}
      {...props}
    >
      <>
        <CommonTextComponent
          title={i18n.t(
            'modules.submissions.common.exercises-answers.writing.items.passages'
          )}
          text={passage?.title || ''}
        />
        <ParagraphComponent paragraphs={passage?.paragraphs || []} />
        {question?.title_intro_custom && (
          <CommonTextComponent
            title={i18n.t(
              'modules.submissions.common.exercises-answers.writing.items.title-intro'
            )}
            text={question?.title_intro_custom || ''}
          />
        )}
        <CommonTextComponent
          title={i18n.t(
            'modules.submissions.common.exercises-answers.writing.items.question'
          )}
          text={question?.description || ''}
        />
        {question?.text_intro_custom && (
          <CommonTextComponent
            title={i18n.t(
              'modules.submissions.common.exercises-answers.writing.items.text-intro'
            )}
            text={question?.text_intro_custom || ''}
          />
        )}
        <StudentAnswers text={writingAnswer} />
      </>
    </AnswersContainer>
  );
};

AnswersWritingContainer.propTypes = {
  answers: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default AnswersWritingContainer;
