import styled from 'styled-components';

export const AnswersContainer = styled.div`
  position: relative;
  display: block;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${(props) => props.theme.config.colors.light};
`;

export const AnswersContainerTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 2rem;
  background-color: ${(props) => props.theme.config.colors.gray_100};
  min-height: 8rem;
`;

export const AnswersTitle = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.config.typograph.quartenary};
  color: ${(props) => props.theme.config.colors.text_base};
  margin: 0;
`;

export const AnswerContent = styled.div`
  display: block;
  padding: 2.4rem 2.4rem 4rem;
  background-color: ${(props) => props.theme.config.colors.light};
`;

export const Marks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: 1rem;
  }

  span {
    width: 5rem;
    max-width: 5rem;
    height: 5rem;
  }
`;

export const Comments = styled.div`
  div {
    margin-top: 2rem;
  }

  span {
    height: 12rem;
  }
`;
