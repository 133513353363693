const types = {
  MULTIPLE_CHOICE: 'mc',
  MULTIPLE_CHOICE_1: 'mc1',
  MULTIPLE_CHOICE_2: 'mc2',
  MULTIPLE_CHOICE_3: 'mc3',
  SUMMARY_COMPLETION: 'summary_completion',
  MATCHING: 'matching',
  INSERT: 'insert',
  SUMMARY: 'summary',
  SUB_CLIP: 'sub_clip',
  SHORT_ANSWER: 'short_answer',
  INCOMPLETE_SENTENCES: 'incomplete_sentences',
  INDEPENDENT: 'independent',
  PHOTOGRAPHS: 'photographs',
  QUESTION_RESPONSE: 'question_response',
  COMPLETION_COMPREHENSION: 'completion_comprehension',
  CONVERSATIONS_TALKS: 'conversations_talks',
  MATCHING_HEADINGS: 'matching_headings',
  SENTENCE_COMPLETION: 'sentence_completion',
  DIAGRAM_OPEN_INPUT: 'diagram_open_input',
  DIAGRAM_CLOSED_INPUT: 'diagram_closed_input',
  CHOOSE_PARAGRAPH: 'choose_paragraph',
  TABLE_2_COLUMN_TYPE_1: 'table_2_column_type_1',
  TABLE_2_COLUMN_TYPE_2: 'table_2_column_type_2',
  TABLE_3_COLUMN_TYPE_1: 'table_3_column_type_1',
  TABLE_3_COLUMN_TYPE_2: 'table_3_column_type_2',
  TABLE_2: 'table_2',
  TABLE_3: 'table_3',
  FORM_1: 'form_1',
  FORM_2: 'form_2',
  FORM_3: 'form_3',
  IELTS_WRITING_TASK_1: 'ielts_writing_task_1',
  IELTS_WRITING_TASK_2: 'ielts_writing_task_2',
  READ_AND_SELECT: 'read_and_select',
  INTEGRATED: 'integrated',
  INTEGRATED_1: 'integrated_1',
  INTEGRATED_2: 'integrated_2',
  PART_1: 'part_1',
  PART_2: 'part_2',
  PART_3: 'part_3',
  LISTEN_AND_SELECT: 'listen_and_select',
  EXTENDED_LISTENING: 'extended_listening',
  GAPPED_SENTENCES: 'gapped_sentences',
  EXTENDED_READING: 'extended_reading',
  OPEN_GAP_FILL: 'open_gap_fill',
};

export { types };
