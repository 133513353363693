import React from 'react';
import PropTypes from 'prop-types';
import { getQuestionByType } from './QuestionTypes.utils';
import { types } from './Types';

import {
  MultipleChoice,
  SummaryCompletion,
  Matching,
  ChooseParagraph,
  Summary,
  ShortAnswer,
  Table,
  AnswerAudio,
  CompletionComprehension,
  DiagramClosedInput,
  AnswerText,
  GappedSentences,
  OpenGapFill,
} from './QuestionsComponents';

const Question = ({ type, category, ...props }) => {
  const questions = {
    [types.MULTIPLE_CHOICE]: MultipleChoice,
    [types.MULTIPLE_CHOICE_1]: MultipleChoice,
    [types.MULTIPLE_CHOICE_2]: MultipleChoice,
    [types.MULTIPLE_CHOICE_3]: MultipleChoice,
    [types.SUMMARY_COMPLETION]: SummaryCompletion,
    [types.SUMMARY]: Summary,
    [types.MATCHING]: Matching,
    [types.INSERT]: SummaryCompletion,
    [types.SUB_CLIP]: MultipleChoice,
    [types.SHORT_ANSWER]: ShortAnswer,
    [types.INCOMPLETE_SENTENCES]: MultipleChoice,
    [types.PHOTOGRAPHS]: MultipleChoice,
    [types.QUESTION_RESPONSE]: MultipleChoice,
    [types.COMPLETION_COMPREHENSION]: CompletionComprehension,
    [types.CONVERSATIONS_TALKS]: CompletionComprehension,
    [types.MATCHING_HEADINGS]: Matching,
    [types.SENTENCE_COMPLETION]: SummaryCompletion,
    [types.CHOOSE_PARAGRAPH]: ChooseParagraph,
    [types.TABLE_2_COLUMN_TYPE_1]: Table,
    [types.TABLE_2_COLUMN_TYPE_2]: Table,
    [types.TABLE_3_COLUMN_TYPE_1]: Table,
    [types.TABLE_3_COLUMN_TYPE_2]: Table,
    [types.DIAGRAM_OPEN_INPUT]: ShortAnswer,
    [types.DIAGRAM_CLOSED_INPUT]: DiagramClosedInput,
    [types.TABLE_2]: Table,
    [types.TABLE_3]: Table,
    [types.INDEPENDENT]:
      category?.toLowerCase() === 'writing' ? AnswerText : AnswerAudio,
    [types.FORM_1]: AnswerAudio,
    [types.FORM_2]: AnswerAudio,
    [types.FORM_3]: AnswerAudio,
    [types.IELTS_WRITING_TASK_1]: AnswerText,
    [types.IELTS_WRITING_TASK_2]: AnswerText,
    [types.READ_AND_SELECT]: MultipleChoice,
    [types.LISTEN_AND_SELECT]: CompletionComprehension,
    [types.EXTENDED_LISTENING]: CompletionComprehension,
    [types.GAPPED_SENTENCES]: GappedSentences,
    [types.EXTENDED_READING]: CompletionComprehension,
    [types.OPEN_GAP_FILL]: OpenGapFill,
    [types.INTEGRATED]:
      category?.toLowerCase() === 'writing' ? AnswerText : AnswerAudio,
    [types.INTEGRATED_1]: AnswerAudio,
    [types.INTEGRATED_2]: AnswerAudio,
    [types.PART_1]:
      category?.toLowerCase() === 'writing' ? AnswerText : AnswerAudio,
    [types.PART_2]:
      category?.toLowerCase() === 'writing' ? AnswerText : AnswerAudio,
    [types.PART_3]:
      category?.toLowerCase() === 'writing' ? AnswerText : AnswerAudio,
  };

  const Component = questions[getQuestionByType(type)];

  if (!Component) {
    return <h6>{"There's no component"}</h6>;
  }

  return <Component {...props} />;
};

Question.propTypes = {
  type: PropTypes.string,
  category: PropTypes.string,
};

export default Question;
