import i18n from 'i18next';

const columnsPlaceholder = [
  {
    title: i18n.t('modules.submissions.common.table.columns.session-id'),
    key: 'string',
  },
  {
    title: i18n.t('modules.submissions.common.table.columns.student'),
    key: 'string',
  },
  {
    title: i18n.t('modules.submissions.common.table.columns.category'),
    key: 'string',
  },
  {
    title: i18n.t('modules.submissions.common.table.columns.test'),
    key: 'string',
  },
  {
    title: i18n.t('modules.submissions.common.table.columns.submission-date'),
    key: 'data',
  },
  {
    title: i18n.t('modules.submissions.common.table.columns.actions'),
    key: 'medium',
  },
];

export default columnsPlaceholder;
