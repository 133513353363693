import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Flex = styled.div`
  display: flex;
  align-items: center;

  @media ${device.mobile} {
    margin: ${(props) => (props.mobileMargin ? '0.4rem 0' : '0')};
  }
`;

export const AnswerProgress = styled(Flex)`
  padding: 8px;
  background: ${(props) => props.theme.config.colors.primary_100};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 8rem;
  left: 8rem;
  right: 0;
  z-index: 6;
  justify-content: center;
  min-height: 5rem;

  @media ${device.mobile} {
    left: 0;
    top: 6rem;
    flex-direction: column;
  }
`;

export const Icon = styled(Flex)`
  justify-content: center;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.done
      ? props.theme.config.colors.green_100
      : props.theme.config.colors.primary_200};
  border-radius: 50%;

  svg {
    width: 0.8rem;

    path {
      fill: ${(props) =>
        props.done
          ? props.theme.config.colors.green
          : props.theme.config.colors.primary};
    }
  }
`;

export const Question = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  margin: 0 1.6rem 0 0.8rem;
  color: ${(props) => props.theme.config.colors.text_base};

  @media ${device.mobile} {
    margin: 0 0rem 0 0.8rem;
  }
`;

export const User = styled.div`
  margin-left: 0.8rem;
  margin-right: 5rem;

  strong {
    display: block;
    font-weight: bold;
    font-size: 1.4rem;
    color: ${(props) => props.theme.config.colors.primary};
  }

  span {
    display: block;
    margin-top: 4px;
    font-size: 1.2rem;
    color: ${(props) => props.theme.config.colors.text_base};
  }

  @media ${device.mobile} {
    margin-right: 0;
  }
`;

export const Progress = styled.div`
  width: 12.8rem;
  height: 1rem;
  border: 1px solid ${(props) => props.theme.config.colors.gray_300};
  border-radius: 1rem;

  div {
    width: ${(props) => props.width}%;
    height: 0.8rem;
    border-radius: 0.8rem;
    background: ${(props) => props.theme.config.colors.green_400};
  }

  @media ${device.mobile} {
    margin: 0.4rem 0;
  }
`;
