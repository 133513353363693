import React, { createRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { TableEmptyData } from 'modules/Core/Common';
import { UserAvatar } from 'modules/Users/Common';
import { CheckIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './SubmissionEvaluators.styles';

export const SubmissionEvaluators = ({
  id,
  exercise_evaluators,
  onShowDropdown,
}) => {
  const [evaluatorActive, setEvaluatorActive] = useState(null);
  const cardRef = createRef(null);

  const handleShowEvaluatorDropdown = (
    exerciseId,
    showDropdown,
    evaluatorId
  ) => {
    setEvaluatorActive(showDropdown ? evaluatorId : null);
    onShowDropdown(exerciseId, showDropdown);
  };

  const compareDates = (a, b) => {
    const date1 = new Date(a?.created_at);
    const date2 = new Date(b?.created_at);
    return date1 - date2;
  };

  const sortedEvaluators = useMemo(
    () =>
      exercise_evaluators?.length
        ? [...exercise_evaluators].sort(compareDates)
        : [],
    [exercise_evaluators]
  );

  if (!sortedEvaluators || !sortedEvaluators?.length) return <TableEmptyData />;

  return (
    <S.Container>
      {sortedEvaluators?.map((exerciseEvaluator) => (
        <S.Evaluator key={exerciseEvaluator?.evaluator?.id}>
          {exerciseEvaluator?.completed && (
            <S.Completed>
              <CheckIcon />
            </S.Completed>
          )}
          <UserAvatar
            id={id}
            name={`${exerciseEvaluator?.evaluator?.name}`}
            email={exerciseEvaluator?.evaluator?.email}
            completedReview={exerciseEvaluator?.completed}
            avatar={exerciseEvaluator?.evaluator?.avatar?.thumb?.url}
            onShowDropdown={(id, showDropdown) =>
              handleShowEvaluatorDropdown(
                id,
                showDropdown,
                exerciseEvaluator?.evaluator?.id
              )
            }
            cardRef={cardRef}
            photoStyles={{
              opacity: evaluatorActive
                ? evaluatorActive === exerciseEvaluator?.evaluator?.id
                  ? 1
                  : '0.56'
                : 1,
            }}
          >
            <UserAvatar.Photo showCursorPointer />
            {sortedEvaluators?.length < 2 && <UserAvatar.Name abridged />}
            <UserAvatar.Card />
          </UserAvatar>
        </S.Evaluator>
      ))}
    </S.Container>
  );
};

SubmissionEvaluators.propTypes = {
  id: PropTypes.number,
  exercise_evaluators: PropTypes.array,
  onShowDropdown: PropTypes.func,
};
