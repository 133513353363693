import { useMemo } from 'react';
import i18n from 'i18next';
import { Stack, Divider } from '@mui/material';
import { Title } from 'modules/Core/Common';
import { QuestionsInfo as BaseQuestionsInfo } from 'modules/Submissions/Common';
import { useHeaderInfo } from '../../hooks';
import { CATEGORY_FULL, CATEGORY_READING, CATEGORY_LISTENING } from 'constants';

export function QuestionsInfo() {
  const { scoreInfo, testCategory } = useHeaderInfo();

  const readingInfo = useMemo(() => {
    const readingScore = scoreInfo?.find(
      (score) => score?.tag?.toLowerCase() === CATEGORY_READING
    );

    return readingScore;
  }, [scoreInfo]);

  const listeningInfo = useMemo(() => {
    const listeningScore = scoreInfo?.find(
      (score) => score?.tag?.toLowerCase() === CATEGORY_LISTENING
    );

    return listeningScore;
  }, [scoreInfo]);

  const isFullTest = testCategory === CATEGORY_FULL;

  return (
    <Stack
      sx={{
        gap: '1.6rem',
        margin: {
          xs: '2.4rem',
          sm: '2.4rem 3.2rem',
        },
      }}
    >
      <Title as="p" size="default" style={{ fontSize: '1.6rem' }}>
        {i18n.t('commons.questions-info.title')}
      </Title>
      {isFullTest ? (
        <Stack
          sx={{
            flexDirection: 'row',
            gap: {
              xs: '2.4rem',
              sm: '5.6rem',
            },
          }}
        >
          <BaseQuestionsInfo
            title={i18n.t('commons.questions-info.reading')}
            correct={readingInfo?.correct || 0}
            incorrect={readingInfo?.incorrect || 0}
            skipped={readingInfo?.skipped || 0}
            perc_correct={readingInfo?.perc_correct || 0}
          />
          <Divider orientation="vertical" flexItem />
          <BaseQuestionsInfo
            title={i18n.t('commons.questions-info.listening')}
            correct={listeningInfo?.correct || 0}
            incorrect={listeningInfo?.incorrect || 0}
            skipped={listeningInfo?.skipped || 0}
            perc_correct={listeningInfo?.perc_correct || 0}
          />
        </Stack>
      ) : (
        <BaseQuestionsInfo
          correct={readingInfo?.correct || listeningInfo?.correct || 0}
          incorrect={readingInfo?.incorrect || listeningInfo?.incorrect || 0}
          skipped={readingInfo?.skipped || listeningInfo?.skipped || 0}
          perc_correct={
            readingInfo?.perc_correct || listeningInfo?.perc_correct || 0
          }
        />
      )}
    </Stack>
  );
}
