import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './InputControl.styles';
import {
  Input,
  Icon,
  ValidationError,
  Password,
  PasswordStrength,
} from 'modules/Core/Common';
import Tooltip from 'rc-tooltip';
import { AlertCircleSecondIcon } from '@edusynch/edusynch-svg-icons';

const InputControl = ({
  inputType,
  inputID,
  inputName,
  iconName,
  rightIconName,
  onRightIconClick,
  errors,
  dataTest,
  touched,
  inputRef,
  hasStrenghtPassword,
  containerElement,
  onChange,
  hidden,
  borders,
  floatingPlaceholder,
  placeholder,
  disabled,
  value,
  onValidate,
  ...props
}) => {
  const [passwordToValidate, setPasswordToValidate] = useState('');
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [visibleFloatingPlaceholder, setVisibleFloatingPlaceholder] = useState(
    value && floatingPlaceholder
  );

  const onCloseTooltip = () => {
    setVisibleTooltip(false);
  };

  const handleOnDigite = (value) => setPasswordToValidate(value);

  const handleBlur = () => {
    setVisibleTooltip(false);
  };

  const handleFocus = () => {
    floatingPlaceholder && setVisibleFloatingPlaceholder(true);
  };

  return (
    <S.InputControl
      hidden={hidden}
      inputErrors={errors}
      inputTouched={touched}
      input={inputType}
      inputStrenghtPassword={hasStrenghtPassword && visibleTooltip}
      disabled={disabled}
      onBlur={handleBlur}
      borders={borders}
    >
      {floatingPlaceholder && placeholder && (
        <S.FloatingPlaceholder
          isDisabled={disabled}
          show={visibleFloatingPlaceholder}
        >
          <span>{placeholder}</span>
        </S.FloatingPlaceholder>
      )}
      {inputType == 'password' ? (
        <>
          <Password
            id={inputID}
            name={inputName}
            touched={touched}
            errors={errors}
            onChange={(e) => {
              e.target.value && handleOnDigite(e.target.value);
              onChange && onChange(e);
              setVisibleTooltip(true);
            }}
            className={iconName ? '' : 'input-default-password'}
            dataTest={dataTest}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            {...props}
          />
          {iconName ? (
            <Icon name={iconName} className="input-error-icon" type="small" />
          ) : null}
          {errors && touched && (
            <Tooltip
              overlayClassName="tooltip-error"
              placement="bottomRight"
              getTooltipContainer={() => containerElement}
              overlay={<ValidationError>{errors}</ValidationError>}
            >
              {/* New alert error */}
              <AlertCircleSecondIcon id="ErrorPassword" type="small" />
            </Tooltip>
          )}
          {hasStrenghtPassword && visibleTooltip && (
            <PasswordStrength
              password={passwordToValidate}
              containerElement={containerElement}
              handleClose={onCloseTooltip}
              onValidate={onValidate}
            />
          )}
        </>
      ) : null || inputType !== 'password' ? (
        <>
          <Input
            inputRef={inputRef}
            type={inputType}
            id={inputID}
            name={inputName}
            placeholder={!visibleFloatingPlaceholder ? placeholder : ''}
            errors={errors}
            touched={touched}
            className={iconName ? '' : 'input-default'}
            data-test={dataTest}
            data-testid={dataTest}
            onChange={onChange}
            onFocus={handleFocus}
            value={value}
            disabled={disabled}
            {...props}
          />
          {iconName ? (
            <Icon
              name={iconName}
              className="input-error-icon"
              size={16}
              color="#acabb7"
            />
          ) : null}
          {!!rightIconName && (
            <S.RightIcon onClick={onRightIconClick}>
              <Icon
                name={rightIconName}
                className="input-error-icon"
                size={16}
              />
            </S.RightIcon>
          )}
          {errors && touched && (
            <Tooltip
              overlayClassName="tooltip-error"
              placement="bottom"
              getTooltipContainer={() => containerElement}
              overlay={<ValidationError>{errors}</ValidationError>}
            >
              {/* New alert error */}
              <AlertCircleSecondIcon id="ErrorInput" type="small" />
            </Tooltip>
          )}
        </>
      ) : null}
    </S.InputControl>
  );
};

InputControl.propTypes = {
  inputType: PropTypes.string,
  inputID: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  iconName: PropTypes.string,
  rightIconName: PropTypes.string,
  onRightIconClick: PropTypes.func,
  errors: PropTypes.string,
  touched: PropTypes.bool,
  inputRef: PropTypes.any,
  hasStrenghtPassword: PropTypes.bool,
  onChange: PropTypes.func,
  customPlaceholder: PropTypes.string,
  containerElement: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  hidden: PropTypes.bool,
  borders: PropTypes.bool,
  disabled: PropTypes.bool,
  floatingPlaceholder: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onValidate: PropTypes.func,
};

export default InputControl;
