import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3.2rem;
  border-radius: 6px;

  label {
    max-width: 25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media ${device.desktopSmall} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);

    label {
      max-width: 35rem;
    }
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;
