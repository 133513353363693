import styled, { css, keyframes } from 'styled-components';
import { Row } from 'modules/Core/Common';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedTr = styled.tr`
  animation: 0.3s ${slideDown} cubic-bezier(0.32, 2, 0.55, 0.27);
`;

export const ContainerTd = styled.td`
  padding: 28px 58px !important;
  text-align: left !important;
  background: ${(props) => props.theme.config.colors.gray_100};

  label {
    color: ${(props) => props.theme.config.colors.light};
  }
`;

export const InfoRow = styled(Row)`
  display: ${(props) => (props.$loading ? 'flex' : 'grid')};
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8rem;
  position: relative;
  z-index: 1;
  padding: 1rem;
  float: none !important;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: calc(50% - 0.8rem);
    transform: translate(-50%, -50%);
    width: calc(100% + 10rem);
    height: calc(100% + 5rem);
    background: ${(props) => props.theme.config.colors.gray_100};
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
`;

export const QuestionPassage = styled.div``;

export const Tabs = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.config.colors.primary_100};
  margin-bottom: 2.4rem;
`;

export const Tab = styled.button.attrs({
  type: 'button',
})`
  background: transparent;
  border: none;
  ${(props) => props.theme.config.typograph.default};
  font-weight: bold;
  color: ${(props) => props.theme.config.colors.text_base};
  padding-bottom: 0.8rem;

  & + button {
    margin-left: 3.2rem;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${(props) => props.theme.config.colors.primary};
      border-bottom: 4px solid ${(props) => props.theme.config.colors.primary};
    `};
`;

export const Question = styled.div``;

export const Passage = styled.div``;

export const Answer = styled.div`
  h4 {
    ${(props) => props.theme.config.typograph.default};
    font-weight: bold;
    color: ${(props) => props.theme.config.colors.text_base};
    margin-bottom: 1.6rem;
  }
`;

export const AnswerContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  max-height: 260px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_400};
    border-radius: 20px;
    width: 5px;
  }
`;

export const AnswerTitle = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  font-weight: 700;
  margin-bottom: 4rem;
`;

export const LoadingContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: none;
  }
`;
