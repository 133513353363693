import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import {
  Player,
  Video,
  Ui,
  Controls,
  MuteControl,
  PlaybackControl,
  FullscreenControl,
  CurrentTime,
  ScrubberControl,
  SettingsControl,
} from '@vime/react';

import { Gallery, Row, Col } from 'modules/Core/Common';
import { AnswersContainer } from 'modules/Submissions/Common/Answers';
import { format } from 'modules/Utils';
import { useAuth } from 'modules/Auth/Hooks';
import { FilmingCameraIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './VideoArchives.styles';

export const VideoArchives = ({ loading, videos }) => {
  const { userTimeFormat, userDateFormat } = useAuth();

  const parsedVideos = useMemo(() => {
    if (!videos) return [];

    return videos.map((video) => ({
      original: video?.video?.url,
      time: video?.video?.time,
      thumbnail: video?.thumb,
      description: format(
        video?.created_at,
        `${userDateFormat} ${userTimeFormat}`,
        'parse'
      ),
      renderItem: () => (
        <>
          <Player style={{ '--vm-player-theme': '#0075ea' }}>
            <Ui>
              <Video crossOrigin>
                <source data-src={video?.video?.url} type="video/mp4" />
                <track kind="captions" srcLang="en" label="English" />
              </Video>

              <Controls pin="bottomLeft">
                <PlaybackControl tooltipDirection="right" pin="bottomLeft" />
                <CurrentTime />

                <S.ProgressBar>
                  <ScrubberControl />
                </S.ProgressBar>

                <FullscreenControl pin="bottomRight" />
                <MuteControl pin="bottomRight" />
                <SettingsControl />
              </Controls>
            </Ui>
          </Player>
        </>
      ),
    }));
  }, [videos]);

  if (!videos.length) {
    return null;
  }

  return (
    <AnswersContainer
      IconName={FilmingCameraIcon}
      title={i18n.t('modules.submissions.common.video-archive.header.title')}
      style={{ marginTop: '2.4rem' }}
    >
      <S.Container>
        <Row center="xs" middle="xs">
          <Col xs={12} lg={6}>
            <Gallery
              photos={parsedVideos}
              showPlayButton={false}
              showNav={true}
              noFullScreen
              useBrowserFullscreen={false}
              loading={loading}
              lazyLoad
              emptyDataPadding="0"
            />
          </Col>
        </Row>
      </S.Container>
    </AnswersContainer>
  );
};

VideoArchives.propTypes = {
  loading: PropTypes.bool,
  videos: PropTypes.array,
};
