import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { Button, Row, Col, Icon } from 'modules/Core/Common';
import {
  Grades,
  Marks,
  CommentsContainer,
  StrengthsAndRecommendations,
} from 'modules/Submissions/Common';
import { AnswersContainer } from 'modules/Submissions/Common/Answers';
import { Comments } from 'modules/Submissions/Common/Comments/Comments.component';
import { GradesAndCommentsPlaceholder } from './GradesAndComments.placeholder';
import { useAuth } from 'modules/Auth/Hooks';
import { ResultsIcon } from '@edusynch/edusynch-svg-icons';
import * as S from './GradesAndComments.styles';

export const GradesAndComments = ({
  testType,
  gradesSubtitle,
  loading,
  loadingGradesAndComments,
  isEvaluator,
  isSuperAdmin,
  grades,
  onGradeSelect,
  strengthsAndRecommendations,
  onChangeStrength,
  onChangeRecommendation,
  marks,
  emptyAnswer,
  onEmptyAnswersChange,
  availableMarks,
  onMarkChange,
  comments,
  onCommentsChange,
  onCancel,
  onSave,
}) => {
  const { canEvaluate } = useAuth();
  const [isEditing, setIsEditing] = useState(isEvaluator);
  const { questionType } = useParams();

  const otherGrades = {
    reading: availableMarks?.reading || availableMarks?.Reading,
    listening: availableMarks?.listening || availableMarks?.Listening,
  };
  const QUESTION_TYPES_TO_SHOW_OHTER_GRADES = ['full'];

  const getAnsweredGrades = (grades) =>
    grades
      ? Object.keys(grades)?.filter(
          (item) =>
            grades[item]?.grade_mark !== null && grades[item]?.grade_mark !== -1
        )
      : [];

  const disableEmptyAnswers = useMemo(() => {
    if (grades) {
      const answeredGrades = getAnsweredGrades(grades);
      return answeredGrades?.length > 0;
    }
    return false;
  }, [grades]);

  const disableSaveButton = useMemo(() => {
    if (grades) {
      const answeredGrades = getAnsweredGrades(grades);
      return !answeredGrades?.length && !emptyAnswer;
    }
    return false;
  }, [grades, emptyAnswer]);

  useEffect(() => {
    if (emptyAnswer) {
      const answeredGrades = getAnsweredGrades(grades);
      answeredGrades?.length && onEmptyAnswersChange(false);
    }
  }, [emptyAnswer, grades]);

  if (loading) return <GradesAndCommentsPlaceholder />;

  return (
    <AnswersContainer
      IconName={ResultsIcon}
      title={i18n.t('modules.submissions.common.header.titles.grades-comments')}
      fullWidth
      rightSideComponent={
        <S.RightSide>
          {(otherGrades?.reading || otherGrades?.listening) &&
            QUESTION_TYPES_TO_SHOW_OHTER_GRADES.includes(questionType) && (
              <S.OtherGrades>
                <span>Other Grades</span>
                {otherGrades?.reading && (
                  <S.RLGrade>
                    <strong>{i18n.t('common-words.reading')}</strong>
                    <span>{otherGrades?.reading}</span>
                  </S.RLGrade>
                )}
                {otherGrades?.listening && (
                  <S.RLGrade>
                    <strong>{i18n.t('common-words.listening')}</strong>
                    <span>{otherGrades?.listening}</span>
                  </S.RLGrade>
                )}
              </S.OtherGrades>
            )}
          {canEvaluate && !loading && (
            <S.Button
              onClick={() => setIsEditing(!isEditing)}
              canEvaluate={isEditing}
            >
              <Icon name="edit" />
              {isEditing
                ? i18n.t('commons.buttons.cancel-button')
                : i18n.t('commons.buttons.edit-score-buttons')}
            </S.Button>
          )}
        </S.RightSide>
      }
      style={{ marginTop: '2.4rem' }}
    >
      <S.Container>
        {isEditing ? (
          <>
            <S.Subtitle>{gradesSubtitle || 'Score Subtitle'}</S.Subtitle>
            {(grades || strengthsAndRecommendations) && (
              <S.GradesStrengthsAndRecomendations>
                <Grades
                  grades={grades}
                  loading={loading}
                  onSelect={onGradeSelect}
                />
                {!!strengthsAndRecommendations && (
                  <StrengthsAndRecommendations
                    strengthsAndRecommendations={strengthsAndRecommendations}
                    onChangeStrength={onChangeStrength}
                    onChangeRecommendation={onChangeRecommendation}
                  />
                )}
              </S.GradesStrengthsAndRecomendations>
            )}
            <Row center="lg" middle="lg">
              <Col xs={12} lg={12}>
                <CommentsContainer
                  testType={testType}
                  comments={comments}
                  emptyAnswer={emptyAnswer}
                  disableEmptyAnswers={disableEmptyAnswers}
                  availableMarks={availableMarks}
                  mark={marks}
                  isEvaluator={isEvaluator}
                  isSuperAdmin={isSuperAdmin}
                  onEmptyAnswersChange={({ target }) =>
                    onEmptyAnswersChange(target?.checked)
                  }
                  onCommentsChange={onCommentsChange}
                  onMarkChange={onMarkChange}
                />
              </Col>
            </Row>
            <S.Actions>
              <Button
                large
                transparent
                onClick={() => {
                  canEvaluate && setIsEditing(false);
                  isEvaluator && onCancel();
                }}
                disabled={loading}
              >
                {i18n.t('commons.buttons.cancel-button')}
              </Button>
              <Button
                large
                onClick={onSave}
                disabled={loadingGradesAndComments || disableSaveButton}
                hasLoading={loadingGradesAndComments}
                loading={loadingGradesAndComments}
              >
                {i18n.t('commons.buttons.save-button')}
              </Button>
            </S.Actions>
          </>
        ) : (
          <>
            <S.Subtitle>Score Subtitle</S.Subtitle>
            <Marks marks={marks} loading={loading} />
            <Comments
              isEvaluator={isEvaluator}
              isSuperAdmin={isSuperAdmin}
              comments={comments}
              loading={loading}
              onCommentsChange={() => {}}
            >
              <Comments.TextArea />
            </Comments>
          </>
        )}
      </S.Container>
    </AnswersContainer>
  );
};

GradesAndComments.propTypes = {
  testType: PropTypes.string,
  gradesSubtitle: PropTypes.string,
  loading: PropTypes.bool,
  loadingGradesAndComments: PropTypes.bool,
  isEvaluator: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  grades: PropTypes.object,
  onGradeSelect: PropTypes.func,
  strengthsAndRecommendations: PropTypes.object,
  onChangeStrength: PropTypes.func,
  onChangeRecommendation: PropTypes.func,
  marks: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  photos: PropTypes.array,
  emptyAnswer: PropTypes.bool,
  onEmptyAnswersChange: PropTypes.func,
  availableMarks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onMarkChange: PropTypes.func,
  comments: PropTypes.string,
  onCommentsChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
};
