import { Icon } from 'modules/Core/Common';
import * as S from '../../AnswerSheet.styles';
import { getLetterByOrder } from '../QuestionTypes.utils';

const getAlternativeByCondition = (alternative, index) => {
  const userSelectedCorrectAnswer =
    alternative?.right && alternative?.chosen_by_user;
  const userSelectedWrongAnswer =
    !alternative?.right && alternative?.chosen_by_user;
  const corretAnswerNotSelectedByUser =
    alternative?.right && !alternative?.chosen_by_user;

  if (userSelectedCorrectAnswer) {
    return (
      <S.Alternative>
        <Icon name="check" />
      </S.Alternative>
    );
  } else if (userSelectedWrongAnswer) {
    return (
      <S.Alternative>
        <Icon name="close" />
      </S.Alternative>
    );
  } else if (corretAnswerNotSelectedByUser) {
    return (
      <S.Alternative>
        {getLetterByOrder(alternative?.question_number, index)}
      </S.Alternative>
    );
  }

  return (
    <S.Alternative>
      {getLetterByOrder(alternative?.question_number, index)}
    </S.Alternative>
  );
};

export { getAlternativeByCondition };
