import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'modules/Core/Common/Grid';
import { Audio } from 'modules/Core/Common/Audio';
import { ParagraphComponent } from './AnswersParagraph.component';
import * as S from './Answers.styles';
import i18n from 'i18next';

const CommonAudioComponent = ({ title, text, url, image, paragraphs }) => {
  return (
    <S.CommonContainer>
      <S.AnswerAudioContainer>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <S.CommonTitle>{title}:</S.CommonTitle>
            <S.CommonText dangerouslySetInnerHTML={{ __html: text }} />
            <ParagraphComponent paragraphs={paragraphs} />
            {image ? (
              <a
                href={image}
                title={i18n.t(
                  'modules.submissions.common.header.titles.passage-image'
                )}
                target={'_blank'}
                rel="noreferrer"
              >
                <S.CommonImage src={image} />
              </a>
            ) : null}
          </Col>
          {url && (
            <Col xs={12} sm={6} md={6} lg={6}>
              <Audio url={url} />
            </Col>
          )}
        </Row>
      </S.AnswerAudioContainer>
    </S.CommonContainer>
  );
};

CommonAudioComponent.defaultProps = {
  paragraphs: [],
};

CommonAudioComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  paragraphs: PropTypes.array.isRequired,
  url: PropTypes.string,
  image: PropTypes.string,
};

export { CommonAudioComponent };
