import React from 'react';
import PropTypes from 'prop-types';
import { Button, Title, Loader } from 'modules/Core/Common';
import { CloseIcon } from '@edusynch/edusynch-svg-icons';

import * as S from './styles';

export const ModalHeader = ({
  title,
  loading,
  disabled,
  btnCloseClick,
  submitButtonText,
  onSubmit,
  noHasSubmitButton,
}) => {
  return (
    <S.Container>
      <Title size="secondary">{title}</Title>
      <S.Actions>
        {!noHasSubmitButton && (
          <Button
            medium
            type="submit"
            data-test="submit"
            data-cy="submit"
            disabled={disabled || loading}
            onClick={onSubmit}
          >
            {loading ? (
              <Loader width="24px" borderWidth="2px" text="Please wait..." />
            ) : (
              <div>{submitButtonText}</div>
            )}
          </Button>
        )}
        <S.ButtonClose type="button" onClick={btnCloseClick}>
          <CloseIcon width={18} height={18} />
        </S.ButtonClose>
      </S.Actions>
    </S.Container>
  );
};

ModalHeader.defaultProps = {
  submitButtonText: 'Submit',
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  btnCloseClick: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func,
  noHasSubmitButton: PropTypes.bool,
};
