import styled from 'styled-components';
import { Form as Formik } from 'formik';
import { Title } from 'modules/Core/Common';
import { device } from 'modules/Utils/Devices';

export const FormHeader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.config.colors.light};
  z-index: 1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  h1 {
    ${(props) => props.theme.config.typograph.quartenary};
    margin: 20px 0 20px 32px;
  }
`;

export const FormHeaderActions = styled.div`
  display: flex;
  align-items: center;

  button {
    & + button {
      margin-left: ${(props) => props.theme.config.spacing.spacing_4};
    }
  }
`;

export const ButtonClose = styled.button`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  width: 80px;
  text-align: center;
  background-color: ${(props) => props.theme.config.colors.gray_200};
  border: none;
  outline: none;

  svg {
    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }
`;

export const Form = styled(Formik)`
  position: relative;

  input {
    width: 100%;
    height: 4.8rem;
  }
`;

export const FormBody = styled.div`
  padding: 20px 15px 40px 32px;
  margin-top: 6rem;
  margin-right: ${(props) => props.theme.config.spacing.spacing_2};
  overflow-y: scroll;
  max-height: calc(90vh - 80px);

  input[type='number'] {
    color: ${(props) => props.theme.config.colors.light};
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.primary_100};
    border-radius: 20px;
    width: 5px;
  }

  @media ${device.mobile} {
    padding: 16px 16px 40px;
    margin-top: 6rem;
    margin-right: 0;
  }
`;

export const FormSectionTitle = styled(Title)`
  margin: ${(props) => props.margin || '0'};
`;
export const AntiFraudContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_3};

  label {
    margin-right: ${(props) => props.theme.config.spacing.spacing_4};
  }
`;
