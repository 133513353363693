import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import i18n from 'i18next';

import {
  Selectors,
  Actions,
  Async,
} from 'modules/Submissions/SubmissionsSlice';
import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';
import { useURLSearchQuery } from 'modules/Core/Hooks';
import {
  getExerciseAnswersRoute,
  getSubmissionsRoute,
} from 'modules/Submissions/Utils';

export const useSubmissionQuestion = ({ questionId, testType }) => {
  const [isModalNextAnswerOpen, setIsModalNextAnswerOpen] = useState(false);
  const [isModalEndEvaluationOpen, setIsModalEndEvaluationOpen] =
    useState(false);

  const state = useSelector(Selectors.fetchListData);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { exerciseId } = useParams();
  const { queryObject, queryParams } = useURLSearchQuery();

  useEffect(() => {
    dispatch(Async.fetchSubmissionAnswer({ id: questionId }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(Actions.clearSubmissionQuestions());
    };
  }, [location.pathname]);

  const handleGradeChange = useCallback(
    (grade) => dispatch(Actions.changeSubmissionAnswersGrade(grade)),
    []
  );

  const handleCommentsChange = useCallback(
    (comments) => dispatch(Actions.changeSubmissionAnswersComments(comments)),
    []
  );

  const handleEmptyAnswersChange = useCallback(
    (isEmpty) => dispatch(Actions.changeSubmissionAnswersEmptyAnswers(isEmpty)),
    []
  );

  const handleStrengthChange = useCallback(
    (strength) => dispatch(Actions.changeSubmissionAnswersStrength(strength)),
    []
  );

  const handleRecommendationChange = useCallback(
    (recommendation) =>
      dispatch(Actions.changeSubmissionAnswersRecommendation(recommendation)),
    []
  );

  const handleMarkChange = useCallback(
    (mark) => dispatch(Actions.changeSubmissionAnswersMark(mark)),
    []
  );

  const handleAfterUpdateAnswer = useCallback(() => {
    if (!state.nextAnswerId) {
      fetchNextExercise();
      return setIsModalEndEvaluationOpen(true);
    }

    setIsModalNextAnswerOpen(true);
  }, [
    isModalNextAnswerOpen,
    isModalEndEvaluationOpen,
    state.nextAnswerId,
    state.previousAnswerId,
    state.customTestCategoryId,
    state.customTestId,
    state.categoryId,
    state.testId,
    state.scheduleStatus,
    queryObject,
  ]);

  const handleNextQuestion = useCallback(() => {
    const nextAnswerId = state.nextAnswerId;
    const currentPath = location.pathname;
    const splitCurrentPath = currentPath?.split('/');
    splitCurrentPath[splitCurrentPath.length - 1] = nextAnswerId;
    const nextAnswerPath = splitCurrentPath.join('/');

    history.push(`${nextAnswerPath}?${decodeURIComponent(queryParams)}`);

    setTimeout(
      () => dispatch(Async.fetchSubmissionAnswer({ id: nextAnswerId })),
      150
    );
  }, [state.nextAnswerId]);

  const goToAnswersPath = useCallback(() => {
    const currentPath = location.pathname;
    const splitCurrentPath = currentPath?.split('/');
    splitCurrentPath.pop();
    const answersPath = splitCurrentPath.join('/');

    history.push(answersPath);
  }, []);

  const goToSubmissionsList = useCallback(() => {
    history.push(getSubmissionsRoute());
  }, []);

  const handleUpdateAnswer = useCallback(() => {
    dispatch(
      Async.updateAnswer({
        testType,
        onSuccess: () => {
          Toast(
            i18n.t('success-messages.successfully-toast.updated-answer'),
            'success'
          );
          handleAfterUpdateAnswer();
        },
        onError: (e) => {
          defaultErrorToast(
            i18n.t('errors.error-sorry-an-error-occurred-during.update-answer')
          )(e);
        },
      })
    );
  }, [
    state.nextAnswerId,
    state.customTestCategoryId,
    state.customTestId,
    state.categoryId,
    state.testId,
    state.scheduleStatus,
    queryObject,
  ]);

  const handleUpdateEvaluator = (evaluatorId) =>
    dispatch(Actions.updateEvaluator(evaluatorId));

  const handleNextEvaluation = useCallback(() => {
    const nextEvaluationRoute = getExerciseAnswersRoute(
      state?.nextExercise?.data,
      decodeURIComponent(queryParams)
    );
    history.push(nextEvaluationRoute);
  }, [state?.nextExercise?.data?.id]);

  const handlePreviousEvaluation = useCallback(() => {
    const previousEvaluationRoute = getExerciseAnswersRoute(
      state?.previousExercise,
      decodeURIComponent(queryParams)
    );
    history.push(previousEvaluationRoute);
  }, [state?.previousExercise?.id]);

  const fetchNextExercise = () => {
    const isCustomTest = location?.pathname.includes('custom-tests');
    dispatch(
      Async.fetchNextExercise({
        id: exerciseId,
        search: queryObject?.search,
        custom_test_category_id: state?.customTestCategoryId,
        custom_test_id: isCustomTest ? queryObject?.test_id : null,
        evaluator_id: queryObject?.evaluator_id,
        schedule_status: state?.scheduleStatus,
        start_schedule_date: queryObject?.start_schedule_date,
        end_schedule_date: queryObject?.end_schedule_date,
        start_submission_date: queryObject?.start_submission_date,
        end_submission_date: queryObject?.end_submission_date,
        start_evaluated_date: queryObject?.start_evaluated_date,
        end_evaluated_date: queryObject?.end_evaluated_date,
        category_id: state?.categoryId,
        test_id: !isCustomTest ? queryObject?.test_id : null,
        status_exercise:
          queryObject?.status_exercise === 'Pending'
            ? 0
            : queryObject?.status_exercise === 'Completed'
            ? 1
            : '',
        es_evaluator: state?.data?.esEvaluator,
      })
    );
  };

  return {
    state,
    handleGradeChange,
    handleCommentsChange,
    handleEmptyAnswersChange,
    handleStrengthChange,
    handleRecommendationChange,
    handleMarkChange,
    handleUpdateAnswer,
    handleUpdateEvaluator,
    handleCancel: goToAnswersPath,
    isModalNextAnswerOpen,
    isModalEndEvaluationOpen,
    setIsModalNextAnswerOpen,
    setIsModalEndEvaluationOpen,
    handleNextQuestion,
    handleNextEvaluation,
    handlePreviousEvaluation,
    goToSubmissionsList,
  };
};
