import React from 'react';
import PropTypes from 'prop-types';
import { QuestionsSpeaking } from './QuestionsSpeaking';
import { QuestionsWriting } from './QuestionsWriting';
import ResponseDataHandleServerError from 'modules/Core/Common/ResponseDataHandle/ResponseDataHandleServerError.component';
import ResponseDataHandleEmpty from 'modules/Core/Common/ResponseDataHandle/ResponseDataHandleEmpty.component';

const hasData = (data) => {
  if (data && Object.prototype.hasOwnProperty.call(data, 'total')) {
    return !!data.total;
  }

  return !!data;
};

export const SubmissionQuestions = ({
  questionType,
  error,
  data,
  ...props
}) => {
  const QuestionType = {
    speaking: QuestionsSpeaking,
    writing: QuestionsWriting,
    default: QuestionsWriting,
  };

  const questionCategoryType =
    questionType === 'full'
      ? props?.answer?.category_tag?.toLowerCase()
      : questionType;

  const ComponentQuestions =
    QuestionType[questionCategoryType] || QuestionType.default;

  if (error) {
    return <ResponseDataHandleServerError {...props} />;
  }

  if (!hasData(data)) {
    return <ResponseDataHandleEmpty {...props} />;
  }

  return <ComponentQuestions {...props} />;
};

SubmissionQuestions.propTypes = {
  questionType: PropTypes.string.isRequired,
  error: PropTypes.string,
  data: PropTypes.any,
  answer: PropTypes.object,
};
