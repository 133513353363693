import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'modules/Core/Common';

import * as S from '../../AnswerSheet.styles';
import * as TableStyles from './Table.styles';

import { getLetterByOrder } from '../QuestionTypes.utils';

const Table = ({ alternatives }) => {
  const [tab, setTab] = useState('');
  const changeTab = useCallback((tab) => setTab(tab), [tab]);

  useEffect(() => {
    if (alternatives?.length) {
      setTab(alternatives[0].alternative_id);
    }
  }, [alternatives]);

  return (
    <TableStyles.Container>
      <TableStyles.Tabs>
        {alternatives?.map((alternative) => (
          <TableStyles.Tab
            key={alternative.alternative_id}
            onClick={() => changeTab(alternative.alternative_id)}
            className={tab === alternative.alternative_id ? 'active' : ''}
          >
            <Tooltip overlay={alternative.alternative_description}>
              <span>{alternative.alternative_description}</span>
            </Tooltip>
          </TableStyles.Tab>
        ))}
      </TableStyles.Tabs>
      <TableStyles.TabItem>
        {alternatives?.map((alternative) => {
          if (alternative?.alternative_id === tab) {
            return alternative?.alternatives_chosen_by_user?.map(
              (alternative_chosen_by_user, index) => (
                <S.AlternativeContainer
                  key={alternative_chosen_by_user?.alternative_id}
                  rightSoft={alternative_chosen_by_user?.right}
                  wrongSoft={!alternative_chosen_by_user?.right}
                >
                  <S.Alternative>
                    {getLetterByOrder(
                      alternative_chosen_by_user?.question_number,
                      index
                    )}
                  </S.Alternative>
                  <S.AlternativeText
                    dangerouslySetInnerHTML={{
                      __html:
                        alternative_chosen_by_user?.alternative_description,
                    }}
                  />
                </S.AlternativeContainer>
              )
            );
          }
        })}
      </TableStyles.TabItem>
    </TableStyles.Container>
  );
};

Table.propTypes = {
  alternatives: PropTypes.array,
};

export default Table;
