import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Answers.styles';

const ParagraphComponent = ({ paragraphs = [] }) => (
  <S.CommonContainer>
    {paragraphs.map((paragraph) => (
      <S.ParagraphContainer key={paragraph?.id}>
        <S.CommonText>{paragraph?.paragraph_text}</S.CommonText>
      </S.ParagraphContainer>
    ))}
  </S.CommonContainer>
);

ParagraphComponent.propTypes = {
  paragraphs: PropTypes.array.isRequired,
};

export { ParagraphComponent };
