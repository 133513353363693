import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Audio } from 'modules/Core/Common/Audio';
import * as S from './Answers.styles';

const SpeakingAnswer = ({ data }) => {
  if (!data?.speaking_answer) {
    return '-';
  }

  const multipleAnswers = Array.isArray(data?.speaking_answer);

  if (multipleAnswers) {
    return data?.speaking_answer?.map((answer, index) =>
      answer?.url ? (
        <S.StudentAnswerAudio key={index}>
          <Audio url={answer?.url || ''} secondary />
        </S.StudentAnswerAudio>
      ) : (
        '-'
      )
    );
  }

  return data?.speaking_answer?.url ? (
    <S.StudentAnswerAudio>
      <Audio url={data?.speaking_answer?.url || ''} secondary />
    </S.StudentAnswerAudio>
  ) : (
    '-'
  );
};

SpeakingAnswer.propTypes = {
  data: PropTypes.object,
};

const StudentAnswers = ({ data, text = '' }) => (
  <S.StudentContainer>
    <S.CommonTitle>
      {i18n.t(
        'modules.submissions.common.exercises-answers.writing.items.student-answer'
      )}
      :
    </S.CommonTitle>
    {text ? (
      <S.StudentAnswerText
        dangerouslySetInnerHTML={{
          __html: text.replaceAll('\n', '<br/>'),
        }}
      />
    ) : (
      <SpeakingAnswer data={data} />
    )}
  </S.StudentContainer>
);

StudentAnswers.propTypes = {
  data: PropTypes.object,
  text: PropTypes.string,
};

export { StudentAnswers };
