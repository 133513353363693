import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Label = ({ text, value }) => {
  return (
    <Item>
      {text}: <ItemValue>{value}</ItemValue>
    </Item>
  );
};

Label.propTypes = {
  text: PropTypes.string,
  value: PropTypes.any,
};

const Item = styled.h1`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
`;

const ItemValue = styled.span`
  border: 1px solid ${(props) => props.theme.config.colors.gray_200};
  font-weight: bold;
  border-radius: 4px;
  padding: 0.6rem 0.6rem;
  margin-left: ${(props) => props.theme.config.spacing.spacing_2};
`;

export { Label };
