import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import Tooltip from 'rc-tooltip';

import { STATUS_PENDING } from 'modules/Submissions/Common/Answers/AnswerStatus/AnswerStatus.utils';

import * as S from './AnswerMarks.styles';

export const AnswerMarks = ({ marks, answerStatus, category }) => {
  const theme = useTheme();

  const isCategoryReadingOrListening = ['READING', 'LISTENING'].includes(
    category
  );

  const tooltipMarks = useMemo(() => {
    if (!marks || !marks?.length) return '-';

    return marks?.map((mark) => (
      <S.Text key={mark.id}>
        {`${mark?.evaluator?.name || '-'}: ${mark?.mark || 'Pending'}`}
      </S.Text>
    ));
  }, [marks?.length]);

  const marksText = useMemo(() => {
    if (marks?.length && isCategoryReadingOrListening) {
      const mark = marks[0].mark >= 0 ? marks[0].mark : '-';
      const total = marks[0].total >= 0 ? marks[0].total : '-';
      return `${mark}/${total}`;
    }

    return marks?.length
      ? `${marks?.length} Evaluated`
      : answerStatus === STATUS_PENDING
      ? 'Pending'
      : 'No Score';
  }, [marks?.length]);

  return (
    <S.Container>
      <Tooltip
        overlayClassName={
          !marks || !marks?.length || isCategoryReadingOrListening ? 'hide' : ''
        }
        overlay={tooltipMarks}
        placement="bottom"
        overlayInnerStyle={{
          backgroundColor: theme.config.colors.primary,
          borderRadius: '0.4rem',
          padding: '1.6rem',
        }}
      >
        <S.TooltipItem hasItems={!!marks?.length}>{marksText}</S.TooltipItem>
      </Tooltip>
    </S.Container>
  );
};

AnswerMarks.propTypes = {
  marks: PropTypes.array,
  answerStatus: PropTypes.string,
  category: PropTypes.string,
};
