import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Selectors } from 'modules/Submissions/SubmissionsSlice';
import { getSubmissionsURLFiltersParamsByTestType } from 'modules/Submissions/Utils';
import { createQueryStringFromObject, removeNullProps } from 'modules/Utils';
import { useURLSearchQuery } from 'modules/Core/Hooks';

export const useSubmissionsRoutes = ({ testType = 'base' } = {}) => {
  const state = useSelector(Selectors.fetchListData);
  const { queryObject } = useURLSearchQuery();

  const history = useHistory();

  const backToSubmissionsListByTestType = () => {
    const queryParams = getSubmissionsListQueryParamsFromState();

    const testTypeURL =
      testType === 'practice' ? 'practice-results' : `${testType}-tests`;
    history.push(`/submissions/${testTypeURL}${queryParams}`);
  };

  const getSubmissionsListQueryParamsFromState = () => {
    const {
      page,
      perPage,
      search,
      evaluatorId,
      statusExercise,
      testId,
      testTag,
      showCertification,
      esEvaluator,
      startDate,
      endDate,
      startEvaluatedDate,
      endEvaluatedDate,
      startScheduleDate,
      endScheduleDate,
      startSubmissionDate,
      endSubmissionDate,
      sort,
    } = state.data;

    const {
      start_submission_date,
      end_submission_date,
      start_evaluated_date,
      end_evaluated_date,
      start_schedule_date,
      end_schedule_date,
    } = queryObject;

    const filterParams = getSubmissionsURLFiltersParamsByTestType({
      testType,
      evaluatorId,
      statusExercise,
      testId,
      testTag,
      esEvaluator,
      showCertification,
    });

    let params = {
      page,
      paginates_per: perPage,
      search,
      sort: sort.sortType,
      sort_by: sort.sortBy,
      ...filterParams,
    };

    if (!['invalid', 'practice'].includes(testType)) {
      params = {
        ...params,
        start_evaluated_date: start_evaluated_date || startEvaluatedDate,
        end_evaluated_date: end_evaluated_date || endEvaluatedDate,
        start_schedule_date: start_schedule_date || startScheduleDate,
        end_schedule_date: end_schedule_date || endScheduleDate,
        start_submission_date: start_submission_date || startSubmissionDate,
        end_submission_date: end_submission_date || endSubmissionDate,
      };
    } else {
      params = {
        ...params,
        start_date: startDate,
        end_date: endDate,
      };
    }

    return createQueryStringFromObject(removeNullProps(params));
  };

  return {
    backToSubmissionsListByTestType,
  };
};
