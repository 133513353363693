import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import {
  Col,
  Row,
  Grid,
  Toast,
  ConfirmDialog,
  ChangeEvaluatorModal,
  ResponseDataHandle,
  ToggleButton,
  Filters,
  ProgressBarModal,
} from 'modules/Core/Common';
import SubmissionsTotalizers from '../SubmissionsTotalizers';
import {
  ChildInformation,
  SubmissionsTable,
  SubmissionsListHeader,
  SubmissionsTableToolbarActions,
  ChangeSelectedEvaluatorModal,
} from 'modules/Submissions/Common';
import columnsPlaceholder from './SubmissionsList.placeholder';
import practicePlaceholder from 'modules/Submissions/Practice/PracticeResultsList/PracticeResultsList.placeholder';
import { formatOptions } from 'modules/Core/Common/Filters';
import { INITIAL_SUBMISSIONS_FILTERS_STATE } from 'modules/Submissions/Hooks/useSubmissionsFilters';
import {
  SubmissionIcon,
  ContentIcon,
  PendingIcon,
  UsersIcon,
  InstitutionIcon,
  LicenseIcon,
} from '@edusynch/edusynch-svg-icons';

const SubmissionsList = ({
  additionalColumns,
  tests,
  groups,
  type,
  headerTitle,
  evaluators,
  changeEvaluatorObj,
  isChangeEvaluatorModalOpen,
  isChangeSelectedEvaluatorModalOpen,
  data,
  exportFilter,
  loading,
  error,
  onNextPage,
  onChangePerPage,
  clearError,
  handleOpenChangeEvaluatorModal,
  handleChangeEvaluator,
  handleDelete,
  setIsChangeEvaluatorModalOpen,
  setIsChangeSelectedEvaluatorModalOpen,
  isConfirmDialogModalOpen,
  setIsConfirmDialogModalOpen,
  handleCancelInterviewClick,
  handleConfirmDialog,
  selectedFilters,
  onFilter,
  updateSubmissionFilter,
  clearSubmissionFilter,
  toggleProfileDropdownId,
  handleToggleProfileDropdown,
  toggleId,
  currentToggled,
  handleToggle,
  exerciseStatus,
  isAdmin,
  hasSchedule,
  handleSort,
  handleChangeExportFilter,
  handleSubmitExport,
  clearAllSubmissionFilters,
  submissionFilters,
  isDeleting,
  itemsSelected,
  onSelect,
  onSelectAll,
  onClearSelection,
  tableToolbarActions,
  onSubmitChangeSelectedEvaluator,
  isProgressBarModalOpen,
  setIsProgressBarModalOpen,
  handleCancelChangeSelectedEvaluator,
  isProcessingSelectedEvaluator,
  changeSelectedEvaluatorProgress,
  disabledChangeEvaluator,
  hasScheduleDate,
  subject,
}) => {
  useEffect(() => {
    if (error) Toast(error, 'info');
    return () => clearError();
  }, [error]);

  const actions = () => ({
    onChangeEvaluator: handleOpenChangeEvaluatorModal,
    onDelete: handleDelete,
    onShowDropdown: handleToggleProfileDropdown,
    handleToggle: handleToggle,
    toggledId: toggleId,
    isAdmin,
    evaluatorsList: evaluators,
    isDeleting,
    subject,
  });

  const formattedGroups = groups?.data?.map((group) => ({
    ...group,
    type: i18n.t('modules.submissions.common.table.columns.groups'),
    filterName: 'group_id',
  }));

  const formattedTests = tests?.data?.map((test) => ({
    ...test,
    type: i18n.t('modules.submissions.common.table.columns.test'),
    filterName: 'test_id',
  }));

  const formattedStatus = exerciseStatus?.map((status) => ({
    id: status,
    label: status,
    type: i18n.t('modules.submissions.common.table.columns.status'),
    filterName: 'status_exercise',
  }));

  const formattedEvaluators = evaluators?.data?.map((evaluator) => ({
    ...evaluator,
    type: i18n.t('modules.submissions.common.table.columns.evaluator'),
    filterName: 'evaluator_id',
  }));

  const formattedCertificates = [
    {
      id: 'ALL',
      label: i18n.t('common-words.all'),
      type: 'certification',
      filterName: 'show_certification',
    },
    {
      id: 'CERTIFICATION_AVAILABLE',
      label: i18n.t(
        'modules.submissions.common.header.filter.filter-options.certification-available'
      ),
      type: 'certification',
      filterName: 'show_certification',
    },
    {
      id: 'CERTIFICATION_NOT_AVAILABLE',
      label: i18n.t(
        'modules.submissions.common.header.filter.filter-options.certification-not-available'
      ),
      type: 'certification',
      filterName: 'show_certification',
    },
  ];

  const isFiltersLoading = evaluators?.loading || tests?.loading;

  return (
    <Grid fluid>
      <SubmissionsListHeader
        title={headerTitle}
        subtitle={i18n.t(
          'modules.submissions.common.header.subtitles.submissions'
        )}
        Icon={SubmissionIcon}
        loading={loading}
        data={data}
        groups={groups}
        tests={tests}
        exerciseStatus={exerciseStatus}
        evaluators={evaluators}
        exportFilter={exportFilter}
        onChangeExportFilter={handleChangeExportFilter}
        onSubmitExport={handleSubmitExport}
      />
      <Filters
        options={formatOptions(
          {
            items: formattedGroups,
            type: 'Group',
            Icon: InstitutionIcon,
          },
          {
            items: formattedTests,
            type: 'Test',
            Icon: ContentIcon,
          },
          {
            items: formattedStatus,
            type: 'Status',
            Icon: PendingIcon,
          },
          {
            items: formattedEvaluators,
            type: 'Evaluator',
            Icon: UsersIcon,
          },
          {
            items: formattedCertificates,
            type: 'Certificates Status',
            Icon: LicenseIcon,
          }
        )}
        defaultSearch={data?.search}
        defaultDates={
          type === 'practice'
            ? {
                start_date: data?.startDate,
                end_date: data?.endDate,
              }
            : {
                start_evaluated_date: data.startEvaluatedDate,
                end_evaluated_date: data.endEvaluatedDate,
                start_schedule_date: data.startScheduleDate,
                end_schedule_date: data.endScheduleDate,
                start_submission_date: data.startSubmissionDate,
                end_submission_date: data.endSubmissionDate,
              }
        }
        defaultFilters={[
          {
            type: 'Group',
            value: parseInt(submissionFilters.group_id) || null,
          },
          {
            type: 'Test',
            value: parseInt(submissionFilters.test_id) || null,
          },
          {
            type: 'Status',
            value: submissionFilters.status_exercise || null,
          },
          {
            type: 'Evaluator',
            value: parseInt(submissionFilters.evaluator_id) || null,
          },
          {
            type: 'Certificates Status',
            value: submissionFilters.show_certification || null,
          },
        ]}
        onFilterChange={updateSubmissionFilter}
        onClearAllFilters={() => {
          clearAllSubmissionFilters();
          onFilter({ search: '', ...INITIAL_SUBMISSIONS_FILTERS_STATE });
        }}
        onClearFilter={clearSubmissionFilter}
        onFilter={(filters) => onFilter({ ...filters, ...submissionFilters })}
        loading={isFiltersLoading}
      />
      <ConfirmDialog
        isOpen={isConfirmDialogModalOpen}
        onRequestClose={() => setIsConfirmDialogModalOpen(false)}
        title="Are you sure you want to cancel the interview?"
        confirmButtonText="Yes, I want to cancel"
        cancelButtonText="No, I wish to continue"
        loading={loading}
        isCancelHighlighted
        onConfirm={handleConfirmDialog}
      />
      <ChangeEvaluatorModal
        isOpen={isChangeEvaluatorModalOpen}
        onRequestClose={() => setIsChangeEvaluatorModalOpen(false)}
        evaluatorId={changeEvaluatorObj?.evaluatorId}
        scheduleDate={changeEvaluatorObj?.scheduleDate}
        hasSchedule={hasSchedule}
        evaluators={evaluators?.data}
        loading={loading}
        onSubmit={handleChangeEvaluator}
      />
      {!!onSubmitChangeSelectedEvaluator && (
        <>
          <ChangeSelectedEvaluatorModal
            isOpen={isChangeSelectedEvaluatorModalOpen}
            onClose={() => setIsChangeSelectedEvaluatorModalOpen(false)}
            loading={loading}
            evaluators={evaluators?.data}
            hasScheduleDate={hasScheduleDate}
            onSubmit={onSubmitChangeSelectedEvaluator}
          />

          <ProgressBarModal
            isOpen={isProgressBarModalOpen}
            onClose={() => setIsProgressBarModalOpen(false)}
            onCancel={handleCancelChangeSelectedEvaluator}
            isLoading={isProcessingSelectedEvaluator}
            progress={changeSelectedEvaluatorProgress}
          />
        </>
      )}
      <Row>
        <Col xs={12}>
          <SubmissionsTotalizers {...data} loading={loading} type={type} />
          <ResponseDataHandle
            data={data}
            loading={loading}
            iconType="submission"
            selectable={!!itemsSelected}
            columns={
              type !== 'practice' ? columnsPlaceholder : practicePlaceholder
            }
            render={() => (
              <SubmissionsTable
                data={data}
                actions={actions()}
                firstItemSize={'4rem'}
                onNextPage={onNextPage}
                type="submissions"
                toggleChild={toggleId}
                tdSpacing={'1.4rem 1.2rem'}
                thSpacing={'1.4rem 1.2rem'}
                toggleProfileDropdownId={toggleProfileDropdownId}
                toggleButton={ToggleButton}
                customQueryParams={selectedFilters}
                toggledChildren={
                  <ChildInformation
                    columnsSize={9}
                    data={currentToggled}
                    onCancelInterviewClick={handleCancelInterviewClick}
                  />
                }
                additionalColumns={additionalColumns}
                onToggleClick={onChangePerPage}
                onSort={handleSort}
                testType={type}
                itemsSelected={itemsSelected}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onClearSelection={onClearSelection}
                tableToolbarActions={
                  tableToolbarActions ? (
                    tableToolbarActions
                  ) : itemsSelected ? (
                    <SubmissionsTableToolbarActions
                      disabledChangeEvaluator={disabledChangeEvaluator}
                      onChangeEvaluator={() =>
                        setIsChangeSelectedEvaluatorModalOpen(true)
                      }
                    />
                  ) : null
                }
              />
            )}
          />
        </Col>
      </Row>
    </Grid>
  );
};

SubmissionsList.propTypes = {
  additionalColumns: PropTypes.array,
  data: PropTypes.object,
  type: PropTypes.string,
  exportFilter: PropTypes.object,
  headerTitle: PropTypes.string,
  tests: PropTypes.object,
  groups: PropTypes.object,
  evaluators: PropTypes.object,
  changeEvaluatorObj: PropTypes.object,
  isChangeEvaluatorModalOpen: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onNextPage: PropTypes.func,
  onChangePerPage: PropTypes.func,
  clearError: PropTypes.func,
  handleOpenChangeEvaluatorModal: PropTypes.func,
  handleChangeEvaluator: PropTypes.func,
  handleDelete: PropTypes.func,
  setIsChangeEvaluatorModalOpen: PropTypes.func,
  isConfirmDialogModalOpen: PropTypes.bool,
  setIsConfirmDialogModalOpen: PropTypes.func,
  handleCancelInterviewClick: PropTypes.func,
  handleConfirmDialog: PropTypes.func,
  selectedFilters: PropTypes.object,
  onFilter: PropTypes.func,
  updateSubmissionFilter: PropTypes.func,
  clearSubmissionFilter: PropTypes.func,
  toggleProfileDropdownId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleToggleProfileDropdown: PropTypes.func,
  toggleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentToggled: PropTypes.object,
  handleToggle: PropTypes.func,
  exerciseStatus: PropTypes.array,
  isAdmin: PropTypes.bool,
  hasSchedule: PropTypes.bool,
  handleSort: PropTypes.func,
  handleChangeExportFilter: PropTypes.func,
  handleSubmitExport: PropTypes.func,
  clearAllSubmissionFilters: PropTypes.func,
  submissionFilters: PropTypes.object,
  isDeleting: PropTypes.bool,
  itemsSelected: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClearSelection: PropTypes.func,
  tableToolbarActions: PropTypes.node,
  isChangeSelectedEvaluatorModalOpen: PropTypes.bool,
  setIsChangeSelectedEvaluatorModalOpen: PropTypes.func,
  onSubmitChangeSelectedEvaluator: PropTypes.func,
  isProgressBarModalOpen: PropTypes.bool,
  setIsProgressBarModalOpen: PropTypes.func,
  handleCancelChangeSelectedEvaluator: PropTypes.func,
  isProcessingSelectedEvaluator: PropTypes.bool,
  changeSelectedEvaluatorProgress: PropTypes.number,
  disabledChangeEvaluator: PropTypes.bool,
  hasScheduleDate: PropTypes.bool,
  subject: PropTypes.string,
};

export default SubmissionsList;
