import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  display: block;
`;

export const Mark = styled.div`
  background-color: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 4px;
  color: ${(props) => props.theme.config.colors.primary};
  padding: 0.8rem 1.6rem;
  width: fit-content;
`;

export const SectionContainer = styled.div`
  & + div {
    margin-top: 3rem;
  }
`;

export const GeneralContainer = styled.div`
  display: flex;
  position: relative;
  float: none !important;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: calc(50% - 0.8rem);
    transform: translate(-50%, -50%);
    width: calc(100% + 6.4rem);
    height: calc(100% + 7rem);
    background: ${(props) => props.theme.config.colors.gray_100};
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
`;

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedTr = styled.tr`
  animation: 0.3s ${slideDown} cubic-bezier(0.32, 2, 0.55, 0.27);
`;

export const ContainerTd = styled.td`
  padding: 3.2rem 3.2rem 4.5rem !important;
  text-align: left !important;
  background: ${(props) => props.theme.config.colors.gray_100};

  label {
    color: ${(props) => props.theme.config.colors.light};
  }
`;

export const AnswerQuestionDescription = styled.p`
  ${(props) => props.theme.config.typograph.xsmall};
  font-weight: normal;
  line-height: 1.6rem;
  color: ${(props) => props.theme.config.colors.text_base};

  p {
    margin-top: ${(props) => props.theme.config.spacing.spacing_3};
    margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
  }

  span {
    color: ${(props) => props.theme.config.colors.tertiary};
    font-weight: bold;
  }
`;

export const AlternativeContainer = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: ${(props) => props.theme.config.spacing.spacing_3};
  }

  p {
    .highlight {
      display: inline-block;
      font-weight: bold;
      border-radius: 0.6rem;
      padding: 0.7rem 1.2rem;
      margin: 0.8rem 1rem;
      border: 1px solid ${(props) => props.theme.config.colors.text_base};
    }

    .question-number {
      ${(props) => props.theme.config.typograph.default};
      color: ${(props) => props.theme.config.colors.text_base};
      background: ${(props) => props.theme.config.colors.light};
      margin-left: ${(props) => props.theme.config.spacing.spacing_1};
      font-weight: 700;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    }
  }

  ${(props) =>
    props.correct &&
    css`
      span {
        background: ${(props) => props.theme.config.colors.green};
        filter: none;

        svg {
          width: 1.2rem;

          path {
            fill: ${(props) => props.theme.config.colors.light};
          }
        }
      }

      p {
        font-weight: 700;
        color: ${(props) => props.theme.config.colors.green};

        .highlight {
          border-color: ${(props) => props.theme.config.colors.green};
        }
      }
    `}

  ${(props) =>
    props.right &&
    css`
      > span {
        background: ${(props) => props.theme.config.colors.green};
        color: ${(props) => props.theme.config.colors.light};
        filter: none;

        svg path {
          fill: ${(props) => props.theme.config.colors.light};
        }
      }

      p {
        .highlight {
          color: ${(props) => props.theme.config.colors.green};
          border-color: ${(props) => props.theme.config.colors.green};
        }
      }
    `}

  ${(props) =>
    props.wrong &&
    css`
      p {
        .highlight {
          color: ${(props) => props.theme.config.colors.tertiary};
          border-color: ${(props) => props.theme.config.colors.tertiary};
        }
      }
    `}

  ${(props) =>
    props.wrongChosen &&
    css`
      > span {
        background: ${(props) => props.theme.config.colors.tertiary};
        filter: none;

        svg {
          width: 1.2rem;

          path {
            fill: ${(props) => props.theme.config.colors.light};
          }
        }
      }

      p {
        font-weight: 700;
        color: ${(props) => props.theme.config.colors.tertiary};

        .highlight {
          border-color: ${(props) => props.theme.config.colors.green};
        }
      }
    `}

  ${(props) =>
    props.rightNotChosen &&
    css`
      > span {
        background: ${(props) => props.theme.config.colors.light};
        border: 1px solid ${(props) => props.theme.config.colors.green};
        filter: none;
        color: ${(props) => props.theme.config.colors.green};
      }

      p {
        .highlight {
          border-color: ${(props) => props.theme.config.colors.green};
        }
      }
    `}

    ${(props) =>
    props.rightSoft &&
    css`
      > span {
        background: ${(props) => props.theme.config.colors.light};
        border: 1px solid ${(props) => props.theme.config.colors.green};
        filter: none;
        color: ${(props) => props.theme.config.colors.green};
      }

      p {
        .highlight {
          border-color: ${(props) => props.theme.config.colors.green};
        }
      }
    `}

  ${(props) =>
    props.wrongSoft &&
    css`
      > span {
        background: ${(props) => props.theme.config.colors.light};
        border: 1px solid ${(props) => props.theme.config.colors.tertiary};
        filter: none;
        color: ${(props) => props.theme.config.colors.tertiary};
      }

      p {
        .highlight {
          border-color: ${(props) => props.theme.config.colors.green};
        }
      }
    `}
`;

export const Alternative = styled.span`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  background: ${(props) => props.theme.config.colors.light};
  display: block;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
`;

export const AlternativeText = styled.p`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  margin: 0;
  margin-left: ${(props) => props.theme.config.spacing.spacing_4};
`;

export const AnswerQuestionText = styled.p`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  margin: 0;
`;

export const Button = styled.button`
  outline: none;
  background: transparent;
  border: none;

  ${(props) =>
    props.toggleId &&
    css`
      svg path {
        fill: ${(props) => props.theme.config.colors.primary};
      }
    `}
`;

const getStyleByStatus = (status, colors) => {
  switch (status?.toLowerCase()) {
    case 'correct':
      return `
        color: ${colors.green};
        background: ${colors.green_100};
      `;
    case 'incorrect':
      return `
        color: ${colors.tertiary};
        background: ${colors.tertiary_100};
      `;
    case 'partial':
      return `
        color: ${colors.secondary};
        background: ${colors.primary_100};
      `;
    case 'pending':
      return `
        color: ${colors.text_base};
        background: ${colors.gray_100};
      `;
    case 'evaluated':
      return `
        color: ${colors.green};
        background: ${colors.green_100};
      `;
    case 'skipped':
      return `
        color: ${colors.orange};
        background: ${colors.orange_100};
      `;
  }
};

export const StatusContainer = styled.div`
  ${(props) => props.theme.config.typograph.xsmall};
  ${(props) => getStyleByStatus(props?.status, props.theme.config.colors)}
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 0.4rem;
  max-width: 9rem;
  width: 100%;
  height: 3.2rem;
  padding: 0 1.4rem !important;
`;

export const MainTitle = styled.h1`
  ${(props) => props.theme.config.typograph.quartenary};
  color: ${(props) => props.theme.config.colors.text_base};
  font-weight: 400;
  margin-bottom: 0;
`;

export const ButtonContainer = styled.div`
  width: 6rem;
`;
