import * as S from '../../AnswerSheet.styles';
import { getLetterByOrder } from '../QuestionTypes.utils';

const getAlternativeByCondition = (alternative, index) => {
  if (alternative?.alternative_right) {
    return (
      <S.Alternative>
        {getLetterByOrder(alternative?.question_number, index)}
      </S.Alternative>
    );
  }

  return (
    <S.Alternative>
      {getLetterByOrder(alternative?.question_number, index)}
    </S.Alternative>
  );
};

export { getAlternativeByCondition };
