import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../../AnswerSheet.styles';
import { replaceKeyByWord } from '../QuestionTypes.utils';
import { getAlternativeByCondition } from './QuestionTypes.base';

const OpenGapFill = ({ alternatives }) => {
  return alternatives?.map((alternative, index) => (
    <S.AlternativeContainer
      key={alternative.alternative_id}
      right={
        !!alternative?.alternative_keywords?.includes(alternative?.user_answer)
      }
      wrong={
        !alternative?.alternative_keywords?.includes(alternative?.user_answer)
      }
    >
      {getAlternativeByCondition(
        {
          ...alternative,
          chosen_by_user: false,
        },
        index
      )}
      <S.AlternativeText
        dangerouslySetInnerHTML={{
          __html: replaceKeyByWord(
            alternative?.alternative_description,
            alternative?.user_answer
          ),
        }}
      />
    </S.AlternativeContainer>
  ));
};

OpenGapFill.propTypes = {
  alternatives: PropTypes.array,
};

export default OpenGapFill;
