import PropTypes from 'prop-types';
import i18n from 'i18next';

import { TableToolbarAction } from 'modules/Core/Common';

export function SubmissionsTableToolbarActions({
  onChangeEvaluator,
  disabledChangeEvaluator,
}) {
  return (
    <>
      <TableToolbarAction
        iconName="change"
        title={i18n.t('commons.change-evaluator.change-evaluator')}
        disabled={disabledChangeEvaluator}
        onClick={onChangeEvaluator}
      />
    </>
  );
}

SubmissionsTableToolbarActions.propTypes = {
  onChangeEvaluator: PropTypes.func,
  disabledChangeEvaluator: PropTypes.bool,
};
