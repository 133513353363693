import React from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useURLSearchQuery } from 'modules/Core/Hooks';
import { Button, Icon } from 'modules/Core/Common';
import { deletePropsFromQueryString } from 'modules/Utils';
import * as S from './BackToListButton.styles';

export const BackToListButton = ({ path, noReplace }) => {
  const { push } = useHistory();
  const { queryParams } = useURLSearchQuery();

  const handleBackToList = () => {
    const pathHasQuestionMark = path?.includes('?');
    const parsedQueryParams = queryParams
      ? `${pathHasQuestionMark ? '' : '?'}${queryParams}`
      : '';
    const newLink = `${path}${parsedQueryParams}`;

    if (noReplace) {
      return push(newLink);
    }

    const newQueryParams = deletePropsFromQueryString(queryParams, [
      'page',
      'paginates_per',
    ])
      .replace('page_submission', 'page')
      .replace('paginates_per_submission', 'paginates_per')
      .replace('//', '/');

    const newLinkReplaced = `${path}${queryParams ? `${newQueryParams}` : ''}`;

    return push(newLinkReplaced);
  };

  return (
    <S.Container>
      <Button
        large
        type="button"
        data-test="submit"
        transparent
        onClick={handleBackToList}
      >
        <Icon name="chevron-left" />
        {i18n.t('commons.buttons.back-to-list')}
      </Button>
    </S.Container>
  );
};

BackToListButton.propTypes = {
  noReplace: PropTypes.bool,
  path: PropTypes.string,
};
