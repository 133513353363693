import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../../AnswerSheet.styles';
import { replaceKeyByWord } from '../QuestionTypes.utils';
import { getAlternativeByCondition } from './QuestionTypes.base';

const SummaryCompletion = ({ alternatives }) => {
  return alternatives?.map((alternative, index) => (
    <S.AlternativeContainer
      key={alternative.alternative_id}
      right={alternative.right}
      wrong={!alternative.right}
      rightNotChosen={alternative.right && !alternative.chosen_by_user}
    >
      {getAlternativeByCondition(alternative, index)}
      <S.AlternativeText
        dangerouslySetInnerHTML={{
          __html: replaceKeyByWord(
            alternative?.alternative_description,
            alternative?.user_answer
          ),
        }}
      />
    </S.AlternativeContainer>
  ));
};

SummaryCompletion.propTypes = {
  alternatives: PropTypes.array,
};

export default SummaryCompletion;
