import styled from 'styled-components';

export const Container = styled.div`
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;

  a:first-child {
    display: inline-block;
  }

  svg {
    opacity: 0.5;

    path {
      fill: ${(props) => props.theme.config.colors.text_base};
    }
  }

  > div {
    > div:nth-child(1) {
      order: 0;
    }
    > div:nth-child(2) {
      order: 2;
    }
    > div:nth-child(3) {
      order: 1;
    }
  }
`;
export const Button = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;

  > svg {
    width: 1.4rem;
    opacity: 0.5;
  }

  & + button {
    margin-left: ${(props) => props.theme.config.spacing.spacing_2};
  }
`;
