import styled, { css } from 'styled-components';

export const QuestionContainer = styled.div`
  display: inline-block;
  width: 100%;
  float: none !important;
  max-width: 50.1rem;
  margin-right: ${(props) => props.theme.config.spacing.layout_6};
`;

export const CardHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.primary_200};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
`;

export const Title = styled.h1`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  margin-right: ${(props) => props.theme.config.spacing.spacing_5};
  padding-bottom: 1.2rem;
  border-bottom: 4px solid transparent;
  transition: 0.2s ease-in;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 700;

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.config.colors.primary};
      border-bottom-color: ${(props) => props.theme.config.colors.primary};
    `}
`;

export const QuestionContent = styled.div`
  display: inline-block;
  min-height: 10rem;
  padding-right: 2rem;
  width: 100%;

  ${(props) => {
    if (!props.noScroll) {
      return css`
        max-height: 15.5rem;
        overflow: auto;
      `;
    }
  }}

  audio {
    margin-bottom: 1.5rem;
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.secondary_100};
    border-radius: 20px;
    width: 5px;
  }
`;

export const Text = styled.p`
  ${(props) => props.theme.config.typograph.xsmall};
  color: ${(props) => props.theme.config.colors.text_base};

  & + p {
    margin-top: 1rem;
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 25.5rem;
  border-radius: 0.5rem;
  margin-top: ${(props) => props.theme.config.spacing.spacing_3};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_3};
`;

export const AnswerContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  max-height: 300px;

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.secondary_100};
    border-radius: 20px;
    width: 5px;
  }
`;

export const AnswerTitle = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  font-weight: 700;
  margin-bottom: 4rem;
`;

export const AnswerWriting = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  display: block;
  margin-bottom: 4rem;
`;

export const AnswerList = styled.div`
  display: block;
`;

export const AudioContainer = styled.div`
  display: block;
  margin-bottom: 1.5rem;
`;
