import React from 'react';

import { Skeleton } from 'modules/Core/Common';

import * as S from './GradesAndComments.placeholder.styles';

export const GradesAndCommentsPlaceholder = () => {
  return (
    <S.AnswersContainer>
      <S.AnswersContainerTitle>
        <S.AnswersTitle style={{ width: '500px' }}>
          <Skeleton type="round" roundLarge isUser style={{ width: '75%' }} />
        </S.AnswersTitle>
      </S.AnswersContainerTitle>
      <S.AnswerContent>
        <S.Marks>
          <Skeleton type="round" />
          <Skeleton type="round" />
          <Skeleton type="round" />
        </S.Marks>
        <S.Comments>
          <Skeleton type="large" />
        </S.Comments>
      </S.AnswerContent>
    </S.AnswersContainer>
  );
};
