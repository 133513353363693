import styled from 'styled-components';

export const Container = styled.div``;

export const Tabs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 124px));
  border-bottom: 1px solid ${(props) => props.theme.config.colors.primary_200};
  padding-bottom: 0.8rem;
  margin-bottom: 2.4rem;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s;
  color: ${(props) => props.theme.config.colors.text_base};
  font-weight: normal;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
  }

  &.active {
    font-weight: bold;
    background: ${(props) => props.theme.config.colors.gray_100};
    border-radius: 0.6rem;
    height: 3.2rem;
  }
`;

export const TabItem = styled.div``;
