import { useEffect, useState } from 'react';

export function useIsPC() {
  const [isPC, setIsPC] = useState(false);

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );
      setIsPC(!isMobile);
    }
  }, []);

  return isPC;
}
