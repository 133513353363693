import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { QuestionContainer } from './QuestionContainer.component';

import * as S from './AnswerContainer.styles';

const AnswerContainer = ({ children, title, data, noScroll }) => {
  return (
    <>
      <QuestionContainer data={data} noScroll={noScroll} />

      <S.AnswerContainer>
        <S.AnswerTitle>
          {title || i18n.t('modules.simulations.header.titles.answer')}
        </S.AnswerTitle>
        <S.AnswerList>
          {data?.writing_answer && (
            <S.AnswerWriting>{data?.writing_answer}</S.AnswerWriting>
          )}
          {children}
        </S.AnswerList>
      </S.AnswerContainer>
    </>
  );
};

AnswerContainer.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  noScroll: PropTypes.bool,
};

export { AnswerContainer };
