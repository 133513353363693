import React from 'react';
import PropTypes from 'prop-types';
import * as S from './Answers.styles';

const CommonTextComponent = ({ title, text }) => (
  <S.CommonContainer>
    <S.CommonTitle>{title}:</S.CommonTitle>
    <S.CommonText dangerouslySetInnerHTML={{ __html: text }} />
  </S.CommonContainer>
);

CommonTextComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export { CommonTextComponent };
