import React from 'react';
import PropTypes from 'prop-types';
import * as S from './AnswersSpeaking.styles';
import {
  StudentAnswers,
  AnswersContainer,
  CommonAudioComponent,
  AnswersPlaceholder,
} from 'modules/Submissions/Common/Answers';
import { SpeakingIcon } from '@edusynch/edusynch-svg-icons';
import i18n from 'i18next';

const AnswersSpeakingContainer = ({ answers, loading, ...props }) => {
  const { passage, question, test_type: testType } = answers;

  if (loading) return <AnswersPlaceholder />;

  return (
    <AnswersContainer
      IconName={SpeakingIcon}
      title={i18n.t(
        'modules.submissions.common.exercises-answers.writing.header.speaking'
      )}
      testType={testType}
      {...props}
    >
      <S.Container>
        <CommonAudioComponent
          url={passage?.passage_audio?.url || ''}
          title={i18n.t(
            'modules.submissions.common.exercises-answers.writing.items.passages'
          )}
          image={passage?.passage_image?.url || ''}
          text={passage?.title || ''}
        />
        {passage?.paragraphs?.length > 0 && (
          <CommonAudioComponent
            url={passage?.passage_audio?.url || ''}
            title={i18n.t(
              'modules.submissions.common.exercises-answers.writing.items.paragraph-text'
            )}
            image={passage?.passage_image?.url || ''}
            paragraphs={passage?.paragraphs || ''}
          />
        )}
        {question?.title_intro_custom && (
          <CommonAudioComponent
            title={i18n.t(
              'modules.submissions.common.exercises-answers.writing.items.text-intro'
            )}
            text={question?.title_intro_custom || ''}
          />
        )}
        <CommonAudioComponent
          url={question?.question_audio?.url || ''}
          title={i18n.t(
            'modules.submissions.common.exercises-answers.writing.items.question'
          )}
          image={question?.question_image?.url || ''}
          text={question?.description || ''}
        />
        {question?.text_intro_custom && (
          <CommonAudioComponent
            title={i18n.t(
              'modules.submissions.common.exercises-answers.writing.items.intro-custom'
            )}
            text={question?.text_intro_custom || ''}
          />
        )}
        <StudentAnswers data={answers} />
      </S.Container>
    </AnswersContainer>
  );
};

AnswersSpeakingContainer.propTypes = {
  answers: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default AnswersSpeakingContainer;
