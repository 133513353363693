import React from 'react';
import PropTypes from 'prop-types';

import * as S from '../../AnswerSheet.styles';

import { getAlternativeByCondition } from './QuestionTypes.base';
import { Title } from 'modules/Core/Common';

const CompletionComprehension = ({ alternatives }) => {
  return (
    <S.Container>
      {alternatives?.map((alternative) => (
        <S.SectionContainer key={alternative?.alternative_id}>
          <Title size="default" style={{ marginBottom: '3rem' }}>
            {alternative?.alternative_title}
          </Title>
          {alternative?.sub_alternatives?.alternatives?.map(
            (subAlternative, index) => (
              <S.AlternativeContainer
                key={subAlternative.alternative_id}
                correct={subAlternative.right && subAlternative.chosen_by_user}
                wrongChosen={
                  !subAlternative.right && subAlternative.chosen_by_user
                }
                rightNotChosen={
                  subAlternative.right && !subAlternative.chosen_by_user
                }
              >
                {getAlternativeByCondition(subAlternative, index)}
                <S.AlternativeText
                  dangerouslySetInnerHTML={{
                    __html: subAlternative.alternative_description,
                  }}
                />
              </S.AlternativeContainer>
            )
          )}
        </S.SectionContainer>
      ))}
    </S.Container>
  );
};

CompletionComprehension.propTypes = {
  alternatives: PropTypes.array,
};

export default CompletionComprehension;
