import PropTypes from 'prop-types';

import SubmissionsTotalizersComponent from './SubmissionsTotalizers.component';
import { SubmissionsTotalizersPlaceholder } from './SubmissionsTotalizers.placeholder';
import {
  TimeIcon,
  SimulationIcon,
  SubmissionIcon,
} from '@edusynch/edusynch-svg-icons';
import * as S from './SubmissionsTotalizers.styles';

const SubmissionsTotalizers = ({
  totalExercises,
  totalPending,
  totalCompleted,
  loading,
  type,
}) => {
  if (loading) {
    return <SubmissionsTotalizersPlaceholder type={type} />;
  }

  return (
    <S.Container isPractice={type === 'practice'}>
      <SubmissionsTotalizersComponent
        Icon={SubmissionIcon}
        value={totalExercises}
        label="Total Exercises"
      />
      {type !== 'practice' && (
        <>
          <S.Divisor />
          <SubmissionsTotalizersComponent
            Icon={TimeIcon}
            value={totalPending}
            label="Total Pending"
          />
          <S.Divisor />
          <SubmissionsTotalizersComponent
            Icon={SimulationIcon}
            value={totalCompleted}
            label="Total Completed"
          />
        </>
      )}
    </S.Container>
  );
};

SubmissionsTotalizers.propTypes = {
  totalExercises: PropTypes.any,
  totalPending: PropTypes.any,
  totalCompleted: PropTypes.any,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

export default SubmissionsTotalizers;
