import { useDispatch, useSelector } from 'react-redux';

import { Selectors, Async } from 'modules/Submissions/SubmissionsSlice';

export const useChangeCertificateAvailable = () => {
  const dispatch = useDispatch();
  const state = useSelector(Selectors.fetchListData);

  const handleChangeCertificateAvailable = ({ exerciseId }) => {
    const action = Async.changeCertificateAvailable({
      exerciseId,
    });

    dispatch(action);
  };

  return {
    isChangingCertificateAvailable: state.isChangingCertificateAvailable,
    handleChangeCertificateAvailable,
  };
};
