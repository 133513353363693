import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Matching from './Matching.component';

const ShortAnswer = ({ alternatives }) => {
  const parsedAlternavies = useMemo(() => {
    return alternatives.map((alternative) => ({
      ...alternative,
      alternative_description: alternative.right_answer,
      alternative_chosen_by_user: alternative.user_answer,
    }));
  }, [alternatives]);
  return <Matching alternatives={parsedAlternavies} />;
};

ShortAnswer.propTypes = {
  alternatives: PropTypes.array,
};

export default ShortAnswer;
