import styled, { css, keyframes } from 'styled-components';
import { device } from 'modules/Utils';

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.config.spacing.spacing_4};
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_5};

  span {
    padding-left: ${(props) => props.theme.config.spacing.spacing_4};
    ${(props) => props.theme.config.typograph.default};
    color: ${(props) => props.theme.config.colors.primary};
  }

  @media ${device.mobile} {
    flex-direction: column;
    align-items: flex-start;
    span {
      padding-left: 0;
    }
  }
`;

export const EmptyData = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(props) => props.theme.config.spacing.spacing_5};
  padding-left: ${(props) => props.theme.config.spacing.spacing_2};

  div {
    margin-left: ${(props) => props.theme.config.spacing.spacing_4};
    color: ${(props) => props.theme.config.colors.text_base};
    ${(props) => props.theme.config.typograph.default}

    p {
      font-weight: bold;
    }
  }

  @media ${device.mobile} {
    flex-direction: column;
    padding-left: 0;
    gap: 1.6rem;

    div {
      margin-left: 0;
    }
  }
`;

// Skeleton

export const SkeletonWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const SkeletonRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.8rem;
  overflow: hidden;

  div p {
    color: ${(props) => props.theme.config.colors.text_base};
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem;
  }
`;

const getStyleByType = (type) => {
  switch (type) {
    case 'title':
      return css`
        height: 16px;
        border-radius: 1.6rem;
      `;
    case 'subtitle':
      return css`
        height: 16px;
        border-radius: 1.6rem;
        margin-bottom: 0.8rem;
      `;
    case 'data':
      return css`
        height: 5rem;
        border-radius: 0.4rem;
      `;
    default:
      return '';
  }
};

const loading = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

export const SkeletonElement = styled.div`
  width: 104px;
  position: relative;
  margin: 4px 0 0 0;
  border-radius: 4px;
  background: ${(props) => props.theme.config.colors.gray_100};
  overflow: hidden;

  ${(props) => getStyleByType(props.type)}

  &::after {
    content: '';
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    transform: translateX(-100%);
    animation: ${loading} 1.2s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      hsla(0, 0%, 100%, 0.4),
      transparent
    );
    z-index: 1;
  }
`;
