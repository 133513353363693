import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { Stack } from '@mui/material';
import { Icon, Title } from 'modules/Core/Common';

export function QuestionInfo({ variant, value, label }) {
  const theme = useTheme();

  const icon = useMemo(() => {
    switch (variant) {
      case 'correct':
        return {
          name: 'check',
          color: theme.config.colors.green,
        };
      case 'incorrect':
        return {
          name: 'close',
          color: theme.config.colors.tertiary,
        };
      case 'skipped':
        return {
          name: 'jump',
          color: theme.config.colors.orange,
        };
      case 'percentage':
        return {
          name: 'percent',
          color: theme.config.colors.primary,
        };
      default:
        return {
          name: 'percent',
          color: theme.config.colors.gray,
        };
    }
  }, [variant]);

  return (
    <Stack>
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0.8rem',
        }}
      >
        <Icon name={icon.name} color={icon.color} size="1.6rem" />
        <Title
          as="p"
          size="quartenary"
          color="gray"
          style={{ fontSize: '2rem' }}
        >
          {value}
        </Title>
      </Stack>
      <Title as="p" size="default" fontWeight="normal" color="gray_400">
        {label}
      </Title>
    </Stack>
  );
}

QuestionInfo.propTypes = {
  variant: PropTypes.oneOf(['correct', 'incorrect', 'skipped', 'percentage']),
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
