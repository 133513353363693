import React from 'react';
import PropTypes from 'prop-types';

import { ButtonDropdown } from 'modules/Core/Common';
import {
  DropdownList,
  DropdownButton,
} from 'modules/Core/Common/ButtonDropdown/ButtonDropdown.styles';

import * as S from './MarksTotal.styles';

export const MarksTotal = ({
  answerId,
  currentEditAnswerId,
  mark,
  selectedMark,
  total,
  availableMarks,
  onMarkChange,
  loading,
}) => {
  if (!!answerId && answerId === currentEditAnswerId) {
    return (
      <S.ButtonContainer>
        <ButtonDropdown title={selectedMark} disabled={loading}>
          <DropdownList>
            {availableMarks?.map((availableMark) => (
              <li key={availableMark}>
                <DropdownButton onClick={() => onMarkChange(availableMark)}>
                  {availableMark}
                </DropdownButton>
              </li>
            ))}
          </DropdownList>
        </ButtonDropdown>
      </S.ButtonContainer>
    );
  }

  if (!mark && !total) return '-';

  return (
    <S.MarkTotal>
      <strong>{mark}</strong> / {total}
    </S.MarkTotal>
  );
};

MarksTotal.propTypes = {
  answerId: PropTypes.number,
  currentEditAnswerId: PropTypes.number,
  mark: PropTypes.string,
  selectedMark: PropTypes.string,
  total: PropTypes.string,
  availableMarks: PropTypes.array,
  onMarkChange: PropTypes.func,
  loading: PropTypes.bool,
};
