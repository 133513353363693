import styled from 'styled-components';

export const AnswersContainer = styled.div`
  position: relative;
  display: block;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${(props) => props.theme.config.colors.light};
`;

export const AnswersContainerTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 2rem;
  background-color: ${(props) => props.theme.config.colors.gray_100};
  min-height: 8rem;
`;

export const AnswersTitle = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme.config.typograph.quartenary};
  color: ${(props) => props.theme.config.colors.text_base};
  margin: 0;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;

export const Separator = styled.div`
  height: 4rem;
  width: 1px;
  margin: 0 5.6rem;
  background-color: ${(props) => props.theme.config.colors.gray_300};
`;

export const AnswersType = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};

  span {
    font-weight: bold;
  }
`;

export const AnswersWordCount = styled(AnswersType)`
  padding-left: 4rem;
`;

export const AnswersTimeSpent = styled(AnswersType)`
  padding-left: 4rem;
`;

export const AnswerContent = styled.div`
  display: block;
  padding: 2.4rem 2.4rem 4rem;
  background-color: ${(props) => props.theme.config.colors.light};
`;

export const CommonTitle = styled.h1`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
`;

export const CommonContainer = styled.div`
  display: block;
  margin-bottom: ${(props) => props.theme.config.spacing.spacing_4};
`;

export const AnswerAudioContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_300};
  padding-bottom: 16px;
`;

export const StudentContainer = styled.div`
  display: block;
`;

export const CommonText = styled.p`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  line-height: ${(props) => props.theme.config.spacing.spacing_4};
`;

export const StudentAnswerText = styled.p`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.gray_700};
  background: ${(props) => props.theme.config.colors.primary_100};
  padding: 2.4rem;
  border-radius: 6px;
  margin-top: ${(props) => props.theme.config.spacing.spacing_1};
`;

export const ParagraphContainer = styled.div`
  padding: ${(props) => props.theme.config.spacing.spacing_2} 0;
`;

export const CommonImage = styled.img`
  margin: 0 auto;
  max-width: 500px;
  max-height: 500px;
`;

export const StudentAnswerAudio = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.gray_700};
  background: ${(props) => props.theme.config.colors.primary_100};
  padding: 16px;
  border-radius: 6px;
  margin-top: ${(props) => props.theme.config.spacing.spacing_2};
  width: fit-content;

  .react-player {
    display: none;
  }
`;
