import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { InfoIcon, IconRoundBox } from 'modules/Core/Common';
import * as S from './Answers.styles';
import { useTheme } from 'styled-components';

const AnswersContainer = ({
  title,
  fullWidth,
  testType,
  wordCount,
  timeSpent,
  children,
  IconName,
  rightSideComponent,
  ...props
}) => {
  const theme = useTheme();

  const formatTestType = useCallback((type) => {
    if (type === 'A2_FLYERS') {
      return 'A2 Flyers';
    }
    return type;
  }, []);

  return (
    <S.AnswersContainer {...props}>
      <S.AnswersContainerTitle>
        <S.AnswersTitle fullWidth={fullWidth}>
          <InfoIcon
            thin
            before={
              <IconRoundBox withBg bg={theme.config.colors.primary_100}>
                {IconName && (
                  <IconName color={theme.config.colors.primary} size="medium" />
                )}
              </IconRoundBox>
            }
          ></InfoIcon>
          {title}
          {rightSideComponent && rightSideComponent}
        </S.AnswersTitle>
        {!!testType && (
          <>
            <S.Separator />
            <S.AnswersType>
              <span>Type:</span> {formatTestType(testType)}
            </S.AnswersType>
          </>
        )}
        {!!wordCount && (
          <S.AnswersWordCount>
            <span>Word count:</span> {wordCount}
          </S.AnswersWordCount>
        )}
        {!!timeSpent && (
          <S.AnswersTimeSpent>
            <span>Time spent:</span> {timeSpent}
          </S.AnswersTimeSpent>
        )}
      </S.AnswersContainerTitle>
      <S.AnswerContent>{children}</S.AnswerContent>
    </S.AnswersContainer>
  );
};

AnswersContainer.propTypes = {
  title: PropTypes.string.isRequired,
  testType: PropTypes.string,
  fullWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  wordCount: PropTypes.number,
  timeSpent: PropTypes.string,
  children: PropTypes.element.isRequired,
  IconName: PropTypes.func.isRequired,
  rightSideComponent: PropTypes.object,
};

export { AnswersContainer };
