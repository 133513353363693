import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { Title } from 'modules/Core/Common';
import { QuestionInfo } from './QuestionInfo.component';

export function QuestionsInfo({
  title,
  correct = 0,
  incorrect = 0,
  skipped = 0,
  perc_correct = 0,
}) {
  return (
    <Stack
      sx={{
        gap: '0.8rem',
      }}
    >
      {!!title && (
        <Title as="p" size="default" color="gray" fontWeight="normal">
          {title}
        </Title>
      )}
      <Stack
        sx={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: {
            xs: '2.4rem',
            sm: '5.6rem',
          },
        }}
      >
        <QuestionInfo
          variant="correct"
          value={correct}
          label={i18n.t('commons.questions-info.correct')}
        />
        <QuestionInfo
          variant="incorrect"
          value={incorrect}
          label={i18n.t('commons.questions-info.incorrect')}
        />
        <QuestionInfo
          variant="skipped"
          value={skipped}
          label={i18n.t('commons.questions-info.skipped')}
        />
        <QuestionInfo
          variant="percentage"
          value={perc_correct}
          label={i18n.t('commons.questions-info.perc_correct')}
        />
      </Stack>
    </Stack>
  );
}

QuestionsInfo.propTypes = {
  title: PropTypes.string,
  correct: PropTypes.number,
  incorrect: PropTypes.number,
  skipped: PropTypes.number,
  perc_correct: PropTypes.number,
};
