import React from 'react';
import PropTypes from 'prop-types';

import { CommentsDefault } from './Default';
import { CommentsToefl } from './Toefl';
import { CommentsCambridge } from './Cambridge';

export const CommentsContainer = ({ testType, ...props }) => {
  const CommentsType = {
    toefl: CommentsToefl,
    cambridge: CommentsCambridge,
    a2_flyers: CommentsCambridge,
    default: CommentsDefault,
  };

  const CommentsComponent = CommentsType[testType] || CommentsType.default;

  return <CommentsComponent {...props} />;
};

CommentsContainer.propTypes = {
  testType: PropTypes.string,
};
