import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'modules/Core/Common';
import { UserAvatar } from 'modules/Users/Common';
import { useAuth } from 'modules/Auth/Hooks';
import i18n from 'i18next';

import * as S from './AnswerProgress.styles';

export const AnswerProgress = ({
  answerPosition,
  totalAnswersCompleted,
  totalAnswers,
  evaluatorPosition = 1,
}) => {
  const {
    authData: { user },
    isEvaluator,
  } = useAuth();

  if (!totalAnswers) return null;

  return (
    <S.AnswerProgress>
      {isEvaluator && (
        <S.Flex mobileMargin>
          <UserAvatar
            name={user?.name}
            avatar={user?.avatar?.thumb?.url || null}
          >
            <UserAvatar.Photo />
          </UserAvatar>
          <S.User>
            <strong>{user?.name}</strong>
            <span>
              {i18n.t('modules.submissions.common.progress.you-are-evaluator')}{' '}
              {evaluatorPosition}
            </span>
          </S.User>
        </S.Flex>
      )}
      <S.Flex mobileMargin>
        {totalAnswersCompleted === totalAnswers ? (
          <>
            <S.Icon done>
              <Icon name="check" />
            </S.Icon>
            <S.Question>
              {i18n.t('modules.submissions.common.progress.all')} {totalAnswers}{' '}
              {i18n.t('modules.submissions.common.progress.questions-done')}
            </S.Question>
          </>
        ) : (
          <>
            <S.Icon>
              <Icon name="report" />
            </S.Icon>
            <S.Question>
              {i18n.t('modules.submissions.common.progress.question')}{' '}
              {answerPosition}{' '}
              {i18n.t('modules.submissions.common.progress.of')} {totalAnswers}
            </S.Question>
          </>
        )}
      </S.Flex>
      <S.Progress width={(totalAnswersCompleted / totalAnswers) * 100}>
        <div />
      </S.Progress>
    </S.AnswerProgress>
  );
};

AnswerProgress.propTypes = {
  answerPosition: PropTypes.number,
  totalAnswersCompleted: PropTypes.number,
  totalAnswers: PropTypes.number,
  evaluatorPosition: PropTypes.number,
};
