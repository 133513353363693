import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import {
  ListHeader,
  ButtonDropdown,
  SelectAutocomplete,
  InputControl,
  ModalExport,
  ModalExportSuccess,
  RangeDatePickerModal,
} from 'modules/Core/Common';
import { formatDatetUTCToTimezone } from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';
import {
  DownloadIcon,
  CloseIcon,
  ContentIcon,
  CalendarIcon,
} from '@edusynch/edusynch-svg-icons';
import * as S from './SubmissionsListHeader.styles';

const SubmissionsListHeader = ({
  data,
  groups,
  tests,
  exerciseStatus,
  evaluators,
  title,
  subtitle,
  Icon,
  exportFilter,
  onChangeExportFilter,
  onSubmitExport,
}) => {
  const { userDateFormat, userTimezone } = useAuth();
  const [showEvaluatedDatePicker, setShowEvaluatedDatePicker] = useState(false);
  const [showScheduleDatePicker, setShowScheduleDatePicker] = useState(false);
  const [showSubmissionDatePicker, setShowSubmissionDatePicker] =
    useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportToEmail, setExportToEmail] = useState(false);
  const [showExportModalSucess, setShowExportModalSucess] = useState(false);

  const handleOpenReport = (response) => {
    let link;

    if (!response?.message) {
      link =
        response?.[`link_download_${exportFilter.extension}`] ||
        exportFilter.link;
    }

    link && window.open(link);
  };

  const exportSubmitCallback = (type, response) => {
    setShowExportModal(false);

    if (type === 'error') {
      setShowExportModal(true);
    } else {
      setExportToEmail(!!response?.message);
      setShowExportModalSucess(true);
      handleOpenReport(response);
    }
  };

  const handleExportSubmit = () => {
    onSubmitExport(exportSubmitCallback);
  };

  const handleChangeDate = (newDate, startDateKey, endDateKey) => {
    newDate?.start_date &&
      onChangeExportFilter(startDateKey, newDate?.start_date);
    newDate?.end_date && onChangeExportFilter(endDateKey, newDate?.end_date);
  };

  const formatDate = (startDate, endDate) =>
    `${
      startDate
        ? formatDatetUTCToTimezone(
            startDate,
            userDateFormat,
            null,
            userTimezone
          )
        : ''
    } ${
      startDate || endDate
        ? i18n.t(
            'modules.submissions.common.header.buttons.actions-dropdown.to'
          )
        : ''
    } ${
      endDate
        ? formatDatetUTCToTimezone(endDate, userDateFormat, null, userTimezone)
        : ''
    }`;

  const findNameFromArray = (array, value) => {
    const item = array?.find((arrayItem) => arrayItem?.id === value);
    return item?.label || item?.name;
  };

  const renderSelectedValue = (value, keysToErase) => {
    const handleEraseFilters = () =>
      keysToErase?.forEach((key) => onChangeExportFilter(key, ''));

    return (
      <S.ExportValue>
        {value}
        <CloseIcon
          color="#65A7EA"
          type="small"
          width={8}
          height={8}
          onClick={handleEraseFilters}
        />
      </S.ExportValue>
    );
  };

  return (
    <>
      <ListHeader
        title={title}
        subtitle={subtitle}
        IconName={Icon}
        actionsType="custom"
        alignRight
      >
        {!!data?.submissions?.length && onChangeExportFilter && (
          <S.ActionsContainer>
            <ButtonDropdown title="Actions" closeOnClickInside>
              <S.ActionsList>
                <li>
                  <S.Button onClick={() => setShowExportModal(true)}>
                    <DownloadIcon />
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.export-general'
                    )}
                  </S.Button>
                </li>
              </S.ActionsList>
            </ButtonDropdown>
          </S.ActionsContainer>
        )}
      </ListHeader>

      {onChangeExportFilter && (
        <>
          <ModalExport
            isOpen={showExportModal}
            exportFilter={exportFilter}
            setIsOpen={() => setShowExportModal(!showExportModal)}
            disabledSubmit={exportFilter.loading}
            loading={exportFilter.loading}
            noDates
            maxWidth={'680'}
            customSection={
              <S.ExportForm>
                <S.ExportSelect>
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.group'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.groupId ? (
                    renderSelectedValue(
                      findNameFromArray(groups?.data, exportFilter?.groupId),
                      ['groupId']
                    )
                  ) : (
                    <SelectAutocomplete
                      borders
                      id="export_group_search_id"
                      placeholder={i18n.t(
                        'modules.submissions.placeholders.select-group'
                      )}
                      searchPlaceholder={i18n.t(
                        'modules.submissions.placeholders.search-group'
                      )}
                      IconName={ContentIcon}
                      loading={groups?.loading}
                      options={groups?.data}
                      value={
                        exportFilter?.groupId
                          ? [Number(exportFilter?.groupId)]
                          : []
                      }
                      onChangeSelect={(id) =>
                        onChangeExportFilter('groupId', id)
                      }
                    />
                  )}
                </S.ExportSelect>

                <S.ExportSelect>
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.test'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.testId ? (
                    renderSelectedValue(
                      findNameFromArray(tests?.data, exportFilter?.testId),
                      ['testId']
                    )
                  ) : (
                    <SelectAutocomplete
                      borders
                      id="export_test_search_id"
                      placeholder={i18n.t(
                        'modules.submissions.placeholders.select-test'
                      )}
                      searchPlaceholder={i18n.t(
                        'modules.submissions.placeholders.search-test'
                      )}
                      IconName={ContentIcon}
                      loading={tests?.loading}
                      options={tests?.data}
                      value={
                        exportFilter?.testId
                          ? [Number(exportFilter?.testId)]
                          : []
                      }
                      onChangeSelect={(id) =>
                        onChangeExportFilter('testId', id)
                      }
                    />
                  )}
                </S.ExportSelect>

                <S.ExportSelect width="50%">
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.status'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.statusExercise ? (
                    renderSelectedValue(
                      findNameFromArray(
                        exerciseStatus?.map((status) => ({
                          id: status,
                          label: status,
                        })),
                        exportFilter?.statusExercise
                      ),
                      ['statusExercise']
                    )
                  ) : (
                    <SelectAutocomplete
                      borders
                      id="export_status_search_id"
                      placeholder={i18n.t(
                        'modules.submissions.placeholders.select-status'
                      )}
                      searchPlaceholder={i18n.t(
                        'modules.submissions.placeholders.search-status'
                      )}
                      IconName={ContentIcon}
                      options={exerciseStatus?.map((status) => ({
                        id: status,
                        label: status,
                      }))}
                      value={
                        exportFilter?.statusExercise
                          ? [exportFilter?.statusExercise]
                          : []
                      }
                      onChangeSelect={(id) =>
                        onChangeExportFilter('statusExercise', id)
                      }
                    />
                  )}
                </S.ExportSelect>

                <S.ExportSelect width="50%">
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.evaluator'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.evaluatorId ? (
                    renderSelectedValue(
                      findNameFromArray(
                        evaluators?.data,
                        Number(exportFilter?.evaluatorId)
                      ),
                      ['evaluatorId']
                    )
                  ) : (
                    <SelectAutocomplete
                      borders
                      id="export_evaluator_search_id"
                      placeholder={i18n.t(
                        'modules.submissions.placeholders.select-evaluator'
                      )}
                      searchPlaceholder={i18n.t(
                        'modules.submissions.placeholders.search-evaluator'
                      )}
                      IconName={ContentIcon}
                      loading={evaluators?.loading}
                      options={evaluators?.data}
                      value={
                        exportFilter?.evaluatorId
                          ? [Number(exportFilter?.evaluatorId)]
                          : []
                      }
                      onChangeSelect={(id) =>
                        onChangeExportFilter('evaluatorId', id)
                      }
                    />
                  )}
                </S.ExportSelect>

                <S.ExportSelect width="50%" isDate>
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.evaluated-date'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.startEvaluatedDate ||
                  exportFilter?.endEvaluatedDate ? (
                    renderSelectedValue(
                      formatDate(
                        exportFilter?.startEvaluatedDate,
                        exportFilter?.endEvaluatedDate
                      ),
                      ['startEvaluatedDate', 'endEvaluatedDate']
                    )
                  ) : (
                    <InputControl
                      inputType="text"
                      inputID="evaluate-date"
                      inputName="evaluate-date"
                      IconName={CalendarIcon}
                      placeholder={i18n.t(
                        'modules.submissions.common.header.buttons.actions-dropdown.add-date'
                      )}
                      onClick={() => setShowEvaluatedDatePicker(true)}
                    />
                  )}
                  <RangeDatePickerModal
                    isOpen={showEvaluatedDatePicker}
                    onClose={() => setShowEvaluatedDatePicker(false)}
                    startDate={exportFilter?.startEvaluatedDate}
                    endDate={exportFilter?.endEvaluatedDate}
                    onDateChange={(newDate) =>
                      handleChangeDate(
                        newDate,
                        'startEvaluatedDate',
                        'endEvaluatedDate'
                      )
                    }
                    onSave={() => setShowEvaluatedDatePicker(false)}
                  />
                </S.ExportSelect>

                <S.ExportSelect width="50%" isDate>
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.schedule-date'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.startScheduleDate ||
                  exportFilter?.endScheduleDate ? (
                    renderSelectedValue(
                      formatDate(
                        exportFilter?.startScheduleDate,
                        exportFilter?.endScheduleDate
                      ),
                      ['startScheduleDate', 'endScheduleDate']
                    )
                  ) : (
                    <InputControl
                      inputType="text"
                      inputID="schedule-date"
                      inputName="schedule-date"
                      IconName={CalendarIcon}
                      placeholder={i18n.t(
                        'modules.submissions.common.header.buttons.actions-dropdown.add-date'
                      )}
                      onClick={() => setShowScheduleDatePicker(true)}
                    />
                  )}
                  <RangeDatePickerModal
                    isOpen={showScheduleDatePicker}
                    onClose={() => setShowScheduleDatePicker(false)}
                    startDate={exportFilter?.startScheduleDate}
                    endDate={exportFilter?.endScheduleDate}
                    onDateChange={(newDate) =>
                      handleChangeDate(
                        newDate,
                        'startScheduleDate',
                        'endScheduleDate'
                      )
                    }
                    onSave={() => setShowScheduleDatePicker(false)}
                  />
                </S.ExportSelect>

                <S.ExportSelect width="50%" isDate>
                  <S.ExportLabel>
                    {i18n.t(
                      'modules.submissions.common.header.buttons.actions-dropdown.submission-date'
                    )}
                  </S.ExportLabel>
                  {exportFilter?.startSubmissionDate ||
                  exportFilter?.endSubmissionDate ? (
                    renderSelectedValue(
                      formatDate(
                        exportFilter?.startSubmissionDate,
                        exportFilter?.endSubmissionDate
                      ),
                      ['startSubmissionDate', 'endSubmissionDate']
                    )
                  ) : (
                    <InputControl
                      inputType="text"
                      inputID="submission-date"
                      inputName="submission-date"
                      IconName={CalendarIcon}
                      placeholder={i18n.t(
                        'modules.submissions.common.header.buttons.actions-dropdown.add-date'
                      )}
                      onClick={() => setShowSubmissionDatePicker(true)}
                    />
                  )}
                  <RangeDatePickerModal
                    isOpen={showSubmissionDatePicker}
                    onClose={() => setShowSubmissionDatePicker(false)}
                    startDate={exportFilter?.startSubmissionDate}
                    endDate={exportFilter?.endSubmissionDate}
                    onDateChange={(newDate) =>
                      handleChangeDate(
                        newDate,
                        'startSubmissionDate',
                        'endSubmissionDate'
                      )
                    }
                    onSave={() => setShowSubmissionDatePicker(false)}
                  />
                </S.ExportSelect>
              </S.ExportForm>
            }
            onChange={onChangeExportFilter}
            onSubmit={handleExportSubmit}
          />
          <ModalExportSuccess
            isOpen={showExportModalSucess}
            exportToEmail={exportToEmail}
            onExportAgain={handleOpenReport}
            onDone={() => setShowExportModalSucess(false)}
          />
        </>
      )}
    </>
  );
};

SubmissionsListHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
  Icon: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  groups: PropTypes.object,
  tests: PropTypes.object,
  exerciseStatus: PropTypes.array,
  evaluators: PropTypes.object,
  exportFilter: PropTypes.object,
  onChangeExportFilter: PropTypes.func,
  onSubmitExport: PropTypes.func,
};

export default SubmissionsListHeader;
