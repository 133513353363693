import styled, { css } from 'styled-components';
import { device } from 'modules/Utils/Devices';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const ActionsContainer = styled.div`
  display: flex;

  &:first-child {
    margin-right: 16px;
  }

  > button {
    z-index: ${ZIndexes.overlay + 1};
    width: 128px;
    padding: 0;
    padding-right: ${(props) => props.theme.config.spacing.spacing_2};
    margin-right: ${(props) => props.theme.config.spacing.spacing_3};
  }
`;

export const ActionsList = styled.ul`
  margin: 6px 0 6px 0;
  max-height: 168px;
  overflow-y: auto;

  scrollbar-color: ${(props) => props.theme.config.colors.gray_300} !important;
  scrollbar-width: thin !important;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.config.colors.light};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.config.colors.gray_300};
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.config.colors.gray_300};
  }

  li {
    & + li {
      border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.config.colors.text_base};
  padding: ${(props) => props.theme.config.spacing.spacing_3};
  ${(props) => props.theme.config.typograph.xsmall};
  transition: background 0.2s;
  ${(props) => props.spaced && `justify-content: space-between;`}

  &:hover {
    background: ${(props) => props.theme.config.colors.gray_100};
  }

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_3};
  }

  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export const ExportForm = styled.span`
  display: flex;
  flex-flow: wrap;
  margin: 0 -1.2rem;
`;

export const ExportSelect = styled.div`
  width: ${(props) => props.width || '100%'};
  padding: 0 1.2rem;
  margin-bottom: 1rem;

  ${(props) => {
    if (props.isDate) {
      return css`
        input {
          width: 100%;
          padding-left: 4rem;
          border-color: ${(props) => props.theme.config.colors.gray_300};
          cursor: pointer;
        }
      `;
    }
  }}

  @media ${device.tabletAndMobile} {
    width: 100%;
  }
`;

export const ExportLabel = styled.span`
  ${(props) => props.theme.config.typograph.default};
  line-height: 3rem;
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const ExportValue = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.primary};
  background-color: ${(props) => props.theme.config.colors.primary_100};
  border-radius: 4px;
  padding: 1.2rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }
`;
