import PropTypes from 'prop-types';
import i18n from 'i18next';
import { useTheme } from 'styled-components';
import { ConfirmPopover, Button, Icon } from 'modules/Core/Common';

export function AiEvaluationButton({ onConfirm }) {
  const theme = useTheme();

  return (
    <ConfirmPopover
      subtitle={i18n.t('modules.submissions.alerts.ai-evaluate')}
      onConfirm={onConfirm}
    >
      <Button medium transparent style={{ width: '160px' }}>
        <Icon
          name="robot"
          size="14px"
          color={theme.config.colors.primary}
          left
        />
        {i18n.t('commons.buttons.ai-evaluation')}
      </Button>
    </ConfirmPopover>
  );
}

AiEvaluationButton.propTypes = {
  onConfirm: PropTypes.func,
};
