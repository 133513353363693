import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Label, SelectAutocomplete } from 'modules/Core/Common';
import { getParsedMark } from 'modules/Submissions/Utils';
import i18n from 'i18next';

import * as S from './Grades.styles';

const getAvailableGrades = (grade) => {
  if (!grade) return [];

  if (grade?.available_grade_marks instanceof Object) {
    const availableGrades = grade.available_grade_marks;

    return Object.keys(availableGrades)
      .map((key, index) => {
        const id = parseFloat(key);
        return {
          id: isNaN(id) ? index : id,
          name: getParsedMark(availableGrades[key]),
        };
      })
      .sort((a, b) => {
        if (isNaN(a.name) || isNaN(b.name)) {
          return 0;
        }
        return a.id - b.id;
      });
  }

  if (grade?.available_grade_marks instanceof Array) {
    return grade.available_grade_marks;
  }
};

export const Grades = ({ grades, loading, onSelect }) => {
  if (!grades) return null;

  return (
    <S.Container>
      {Object.keys(grades)?.map((key) => {
        const grade = grades[key];

        return (
          <FormGroup key={key}>
            <Label
              htmlFor={key}
              tooltipPlacement="top"
              showTooltip={grade?.grade_label?.length > 30}
            >
              {grade?.grade_label}
            </Label>

            <SelectAutocomplete
              id={key}
              loading={loading}
              placeholder={i18n.t('common-words.select')}
              searchPlaceholder={i18n.t('common-words.search')}
              options={getAvailableGrades(grade)}
              showAllOption={{
                label: 'Select',
                id: -1,
              }}
              value={[getParsedMark(grade?.grade_mark)]}
              onChangeSelect={(id) => onSelect({ grade: key, mark: id })}
              noSort
            />
          </FormGroup>
        );
      })}
    </S.Container>
  );
};

Grades.propTypes = {
  answerMarks: PropTypes.array,
  grades: PropTypes.object,
  loading: PropTypes.bool,
  onSelect: PropTypes.func,
};
