import * as Yup from 'yup';
import i18n from 'i18next';

const I18N_PREFIX = 'commons.change-evaluator';

export default Yup.object().shape({
  evaluatorId: Yup.number().required(
    i18n.t(`${I18N_PREFIX}.evaluator-is-required`)
  ),
  scheduleDate: Yup.date(),
});
