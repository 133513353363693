import React from 'react';
import i18n from 'i18next';

import { Button, Icon, Skeleton } from 'modules/Core/Common';

import * as S from './SumsOverallAverages.styles';

export const SumsOverallAveragesPlaceholder = () => {
  return (
    <S.Container className="sums-overall-averages">
      <S.Sums>
        <h4>
          {i18n.t(
            'modules.submissions.common.header.sums-overall-averages.titles.sums'
          )}
          :
        </h4>
        <Skeleton type="round" roundLarge centerTd />
        <Button transparent medium disabled>
          {i18n.t('commons.buttons.interview-link')}
          <Icon name="url" right />
        </Button>
      </S.Sums>
      <S.Averages>
        <h4>
          {i18n.t(
            'modules.submissions.common.header.sums-overall-averages.titles.averages'
          )}
        </h4>
        <S.Scores>
          {[1, 2, 3, 4, 5].map((n) => (
            <S.ScoreWrapper key={n} className="skeleton-wrapper">
              <Skeleton type="round" roundLarge isUser />
            </S.ScoreWrapper>
          ))}
        </S.Scores>
      </S.Averages>
    </S.Container>
  );
};
