import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'modules/Core/Common';

import * as S from './ToggleButton.styles';

export const ToggleButton = ({
  iconName = 'double-arrow',
  toggledId,
  id,
  handleToggle,
  category,
  category_tag,
  isPractice = false,
}) => {
  const categoryTag = category_tag || category?.parent_tag;
  const isReadingOrListening =
    categoryTag?.toLowerCase() === 'reading' ||
    categoryTag?.toLowerCase() === 'listening';

  if (!isPractice && !isReadingOrListening) return null;

  return (
    <S.Button isToggled={toggledId === id} onClick={() => handleToggle(id)}>
      <Icon name={iconName} />
    </S.Button>
  );
};

ToggleButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  toggledId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  id: PropTypes.number.isRequired,
  handleToggle: PropTypes.func.isRequired,
  category_tag: PropTypes.string,
  category: PropTypes.object,
  isPractice: PropTypes.bool,
};
