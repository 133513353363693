const columnsPlaceholder = [
  {
    title: 'Student',
    key: 'string',
  },
  {
    title: 'Evaluator',
    key: 'string',
  },
  {
    title: 'Evaluated at',
    key: 'string',
  },
  {
    title: 'Test Type',
    key: 'string',
  },
  {
    title: 'Category',
    key: 'string',
  },
  {
    title: 'Submission Date',
    key: 'data',
  },
  {
    title: 'Status',
    key: 'string',
  },
  {
    title: 'Association',
    key: 'data',
  },
  {
    title: 'Actions',
    key: 'medium',
  },
];

export default columnsPlaceholder;
