import styled from 'styled-components';
import { device } from 'modules/Utils';

export const Container = styled.div``;

export const MarkContainer = styled.div`
  margin-top: 4rem;
  padding: 0 13.6rem;

  h6 {
    color: ${(props) => props.theme.config.colors.text_base};
    font-weight: bold;
    margin-bottom: 1.6rem;

    span {
      font-weight: 400;
    }
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

export const MarkPlaceholder = styled.div`
  margin-top: 4rem;
  padding: 0 13.6rem;

  span {
    margin-top: 1rem;
    width: 5rem;
    max-width: 5rem;
    height: 5rem;
  }
`;

export const MarksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: 1fr;
  }
`;

export const MarkWrapper = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
  }

  span {
    color: ${(props) => props.theme.config.colors.text_base};
    margin-left: 1.6rem;
  }
`;

export const Subtitle = styled.div`
  ${(props) => props.theme.config.typograph.default};
  color: ${(props) => props.theme.config.colors.text_base};
  text-align: center;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid ${(props) => props.theme.config.colors.gray_200};
`;
