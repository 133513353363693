import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  > div {
    & + div {
      margin-left: 0.8rem;
    }
  }
`;

export const Evaluator = styled.div`
  position: relative;
`;

export const Completed = styled.div`
  position: absolute;
  top: -2px;
  left: 23px;
  width: 1.2rem;
  height: 1.2rem;
  background-color: ${(props) => props.theme.config.colors.green};
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 0.6rem;

    path {
      fill: ${(props) => props.theme.config.colors.light};
    }
  }
`;
