import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { UserAvatar } from 'modules/Users/Common';
import { TablePagination, TableEmptyData, DateTime } from 'modules/Core/Common';
import { SubmissionEvaluators } from 'modules/Submissions/Common';
import { Actions } from './Actions';
import { useAuth } from 'modules/Auth/Hooks';
import i18n from 'i18next';

const ColumnAvatar = ({ id, student = [], onShowDropdown }) => {
  const cardRef = createRef(null);
  return (
    <UserAvatar
      id={id}
      name={student?.name}
      email={student?.email}
      avatar={student?.avatar?.thumb?.url}
      onShowDropdown={onShowDropdown}
      cardRef={cardRef}
    >
      <UserAvatar.Photo showCursorPointer />
      <UserAvatar.Name abridged />
      <UserAvatar.Card />
    </UserAvatar>
  );
};

const filteredAdditional = (additional, data) => {
  if (!data?.[0]) return additional;

  return additional.filter((column) => {
    if (column.key.includes('status')) {
      return column.render(data[0]) !== 'noProperty';
    } else {
      return true;
    }
  });
};

const getColumns = (
  additional,
  toggleButton,
  data,
  dataSort,
  onSort,
  canChangeEvaluators
) =>
  [
    toggleButton && {
      title: '',
      sort: false,
      key: 'toggle',
      render: (props) => props?.schedule_date && toggleButton(props),
    },

    {
      title: i18n.t('modules.submissions.common.table.columns.student'),
      key: 'student',
      sort:
        dataSort?.sort?.sortBy === 'student'
          ? dataSort?.sort?.sortType
          : 'DESC',
      onSort: () => onSort('student'),
      render: ColumnAvatar,
    },

    canChangeEvaluators && {
      title: i18n.t('modules.submissions.common.table.columns.evaluator'),
      key: 'evaluator',
      sort:
        dataSort?.sort?.sortBy === 'exercise_evaluator'
          ? dataSort?.sort?.sortType
          : 'DESC',
      onSort: () => onSort('exercise_evaluator'),
      render: SubmissionEvaluators,
    },

    {
      title: i18n.t('modules.submissions.common.table.columns.evaluated-at'),
      key: 'evaluated_at',
      sort: false,
      render: ({ evaluated_at }) => {
        return evaluated_at ? (
          <DateTime date={evaluated_at} />
        ) : (
          <TableEmptyData value={evaluated_at} />
        );
      },
    },

    ...filteredAdditional(additional, data),

    {
      title: i18n.t('modules.submissions.common.table.columns.actions'),
      key: 'actions',
      sort: false,
      render: Actions,
    },
  ].filter((i) => i);

const getPracticeColumns = (additional, dataSort, onSort) => [
  {
    title: i18n.t('modules.submissions.common.table.columns.session-id'),
    key: 'id',
    width: '15%',
    sort:
      dataSort?.sort?.sortBy === 'SESSION_ID'
        ? dataSort?.sort?.sortType
        : 'DESC',
    onSort: () => onSort('SESSION_ID'),
    render: ({ id }) => id || '-',
  },

  {
    title: 'Student',
    key: 'student',
    sort: dataSort?.sort?.sortBy === 'NAME' ? dataSort?.sort?.sortType : 'DESC',
    onSort: () => onSort('NAME'),
    render: ColumnAvatar,
  },

  ...additional,

  {
    title: i18n.t('modules.submissions.common.table.columns.actions'),
    key: 'actions',
    sort: false,
    render: Actions,
  },
];

const SubmissionsTable = ({
  type,
  data,
  actions,
  thSpacing,
  tdSpacing,
  onNextPage,
  toggleChild,
  toggleButton,
  firstItemSize,
  toggledChildren,
  toggleProfileDropdownId,
  additionalColumns,
  onToggleClick,
  onSort,
  testType,
  itemsSelected,
  onSelect,
  onSelectAll,
  onClearSelection,
  tableToolbarActions,
}) => {
  const { canChangeEvaluators } = useAuth();
  const isPractice = testType === 'practice';

  return (
    <TablePagination
      data={data[type]}
      tdSpacing={tdSpacing}
      thSpacing={thSpacing}
      additionalData={actions}
      toggleChild={toggleChild}
      firstItemSize={firstItemSize}
      toggledChildren={toggledChildren}
      toggleChildDataMapField="id"
      toggleProfileDropdownId={toggleProfileDropdownId}
      columns={
        !isPractice
          ? getColumns(
              additionalColumns,
              toggleButton,
              data[type],
              data,
              onSort,
              canChangeEvaluators
            )
          : getPracticeColumns(additionalColumns, data, onSort)
      }
      pagination={{
        total: data.total,
        perPage: data.perPage,
        page: data.page,
        onNextPage,
        onToggleClick: onToggleClick,
      }}
      itemsSelected={itemsSelected}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onClearSelection={onClearSelection}
      tableToolbarActions={tableToolbarActions}
    />
  );
};

SubmissionsTable.defaultProps = {
  additionalColumns: [],
};

ColumnAvatar.propTypes = {
  id: PropTypes.number,
  student: PropTypes.object,
  onShowDropdown: PropTypes.func,
};

SubmissionsTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  actions: PropTypes.object,
  toggleChild: PropTypes.number,
  onNextPage: PropTypes.func,
  toggleButton: PropTypes.func,
  tdSpacing: PropTypes.string,
  thSpacing: PropTypes.string,
  firstItemSize: PropTypes.string,
  toggledChildren: PropTypes.element,
  toggleProfileDropdownId: PropTypes.number,
  additionalColumns: PropTypes.array,
  onToggleClick: PropTypes.func,
  onSort: PropTypes.func,
  testType: PropTypes.string,
  itemsSelected: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onClearSelection: PropTypes.func,
  tableToolbarActions: PropTypes.node,
};

export default SubmissionsTable;
