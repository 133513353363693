import React from 'react';
import PropTypes from 'prop-types';

import * as S from './QuestionDescription.styles';

export const QuestionDescription = ({ description }) => {
  return <S.Description>{description}</S.Description>;
};

QuestionDescription.propTypes = {
  description: PropTypes.string,
};
