import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'camelcase';
import i18n from 'i18next';

import { Label, Switch } from 'modules/Core/Common';

import * as S from './StrengthsAndRecommendations.styles';

const booleanContainer = (data, onChangeHandler) => {
  return (
    <S.BooleanContainer key={data.id}>
      <Switch
        id={camelCase(data.description)}
        name={camelCase(data.description)}
        active={data.flag_active}
        value={data.flag_active}
        onChange={({ target }) =>
          onChangeHandler({ id: data.id, flag_active: target?.checked })
        }
      />
      <Label>{data.description}</Label>
    </S.BooleanContainer>
  );
};

export const StrengthsAndRecommendations = ({
  strengthsAndRecommendations = {},
  onChangeStrength,
  onChangeRecommendation,
}) => {
  const { strengths, recommendations } = strengthsAndRecommendations;

  const renderStrengths = React.useMemo(() => {
    if (!strengths) return null;

    return strengths.map((strength) =>
      booleanContainer(strength, onChangeStrength)
    );
  }, [strengths]);

  const renderRecommendations = React.useMemo(() => {
    if (!recommendations) return null;

    return recommendations.map((recommendation) =>
      booleanContainer(recommendation, onChangeRecommendation)
    );
  }, [recommendations]);

  return (
    <S.Container className="strengths-and-recommendations">
      <S.Strengths>
        <h6>{i18n.t('modules.submissions.common.header.strengths')}</h6>
        {renderStrengths}
      </S.Strengths>
      <S.Recommendations>
        <h6>{i18n.t('modules.submissions.common.header.recommendations')}</h6>
        {renderRecommendations}
      </S.Recommendations>
    </S.Container>
  );
};

StrengthsAndRecommendations.propTypes = {
  strengthsAndRecommendations: PropTypes.object,
  onChangeStrength: PropTypes.func,
  onChangeRecommendation: PropTypes.func,
};
