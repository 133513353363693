import { useHistory } from 'react-router-dom';
import { getExerciseAnswersRoute } from 'modules/Submissions/Utils';

export function useGoToSubmission() {
  const history = useHistory();

  const goToSubmission = (exercise, queryPrams = '') => {
    const submissionRoute = getExerciseAnswersRoute(exercise, queryPrams);
    history.push(submissionRoute);
  };

  return {
    goToSubmission,
  };
}
