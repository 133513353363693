import React from 'react';
import PropTypes from 'prop-types';
import { UserAvatar } from 'modules/Users/Common';
import i18n from 'i18next';

import * as S from './EvaluatorBar.styles';

export const EvaluatorBar = ({
  evaluatorName,
  evaluatorAvatar,
  evaluatorPosition,
}) => {
  return (
    <S.Container data-cy="evaluator-bar">
      <S.UserContainer>
        <UserAvatar name={evaluatorName} avatar={evaluatorAvatar}>
          <UserAvatar.Photo />
        </UserAvatar>
        <S.Name>{evaluatorName} -</S.Name>
        <S.Span data-cy="evaluator-bar-message">
          {i18n.t('modules.submissions.common.header.evaluator-bar.message')}{' '}
          {evaluatorPosition}
        </S.Span>
      </S.UserContainer>
    </S.Container>
  );
};

EvaluatorBar.propTypes = {
  evaluatorName: PropTypes.string,
  evaluatorAvatar: PropTypes.string,
  evaluatorPosition: PropTypes.number,
};
