import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';

import { Toast } from 'modules/Core/Common';
import { defaultErrorToast } from 'modules/Utils';

import { useConfirmDialog } from 'modules/Core/Hooks';

import { Async } from 'modules/Submissions/SubmissionsSlice';

export const useCancelInterview = ({ testType }) => {
  const [cancelInterviewId, setCancelInterviewId] = useState('');

  const dispatch = useDispatch();

  const cancelScheduledInterview = () => {
    const action = Async.cancelScheduledInterview({
      testType,
      id: cancelInterviewId,
      onSuccess: () => {
        Toast(
          i18n.t('success-messages.successfully-toast.canceled-interview'),
          'success'
        );
        dispatch(Async.fetchSubmissionsList({ testType }));
      },
      onError: (e) =>
        defaultErrorToast(
          i18n.t(
            'errors.error-sorry-an-error-occurred-during.cancel-scheduled-interview'
          )
        )(e),
    });

    dispatch(action);
  };

  const {
    isConfirmDialogModalOpen,
    setIsConfirmDialogModalOpen,
    handleConfirmDialog,
  } = useConfirmDialog({
    handleConfirm: cancelScheduledInterview,
  });

  useEffect(() => {
    if (!isConfirmDialogModalOpen) setCancelInterviewId('');
  }, [isConfirmDialogModalOpen]);

  const handleCancelInterviewClick = (testSubmissionsId) => {
    setCancelInterviewId(testSubmissionsId);
    setIsConfirmDialogModalOpen(true);
  };

  return {
    isConfirmDialogModalOpen,
    setIsConfirmDialogModalOpen,
    handleCancelInterviewClick,
    handleConfirmDialog,
  };
};
