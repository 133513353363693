import React from 'react';

import { Loading } from 'modules/Core/Common';

import * as S from './ChildInformation.styles';

const ChildInformationPlaceholder = () => {
  return (
    <S.LoadingContainer>
      <Loading position="relative" size="3.2rem" />
    </S.LoadingContainer>
  );
};

export { ChildInformationPlaceholder };
