import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ShortAnswer from './ShortAnswer.component';

const DiagramClosedInput = ({ alternatives }) => {
  const parsedAlternavies = useMemo(() => {
    return alternatives.map((alternative) => ({
      ...alternative,
      alternative_description: alternative.right_answer,
      user_answer: alternative.alternative_chosen_by_user,
    }));
  }, [alternatives]);
  return <ShortAnswer alternatives={parsedAlternavies} />;
};

DiagramClosedInput.propTypes = {
  alternatives: PropTypes.array,
};

export default DiagramClosedInput;
