import { useMemo } from 'react';

const useSubmissionUrl = ({
  category,
  custom_test_category,
  pathname,
  id,
  queryParams,
}) => {
  const testCategory = useMemo(() => {
    return (
      category?.tag?.toLowerCase() ||
      custom_test_category?.tag?.toLowerCase() ||
      'full'
    );
  }, [category, custom_test_category]);

  const replaceQueryParams = useMemo(() => {
    return queryParams
      ? `?${decodeURIComponent(queryParams)
          .replace('page', 'page_submission')
          .replace('paginates_per', 'paginates_per_submission')
          .replace('//', '/')}`
      : '';
  }, [queryParams]);

  const testSubmissionsTestRoute = useMemo(() => {
    const route = `${pathname}/${testCategory}/${id}/answers${replaceQueryParams}`;
    return route.replace('//', '/');
  }, [pathname, testCategory, id, replaceQueryParams]);

  return {
    route: testSubmissionsTestRoute,
    testCategory,
  };
};

export default useSubmissionUrl;
