import styled, { css, keyframes } from 'styled-components';
import { Row } from 'modules/Core/Common';

const slideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const AnimatedTr = styled.tr`
  animation: 0.3s ${slideDown} cubic-bezier(0.32, 2, 0.55, 0.27);
`;

export const ContainerTd = styled.td`
  padding: 28px 58px !important;
  text-align: left !important;
  background: ${(props) => props.theme.config.colors.gray_100};

  label {
    color: ${(props) => props.theme.config.colors.light};
  }
`;

export const InfoRow = styled(Row)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.6rem 0;
  position: relative;
  z-index: 1;
  padding: 1rem;
  float: none !important;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    top: calc(50% - 0.8rem);
    transform: translate(-50%, -50%);
    width: calc(100% + 10rem);
    height: calc(100% + 5.5rem);
    background: ${(props) => props.theme.config.colors.gray_100};
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
  }
`;

export const Text = styled.div`
  ${(props) => props.theme.config.typograph.xsmall}
  color: ${(props) => props.theme.config.colors.text_base};
  display: flex;
  align-items: center;

  svg {
    margin-right: ${(props) => props.theme.config.spacing.spacing_2};

    path {
      fill: ${(props) => props.theme.config.colors.text_base};

      ${(props) =>
        props.highlight &&
        css`
          background: ${(props) => props.theme.config.colors.primary};
        `};
    }
  }
`;

export const CancelInterview = styled.div`
  margin-left: auto;

  button {
    padding: 0.8rem 1.6rem;
    background: transparent;
    border: 1px solid ${(props) => props.theme.config.colors.tertiary};
    color: ${(props) => props.theme.config.colors.tertiary};

    &:focus,
    &:hover {
      background-color: ${(props) => props.theme.config.colors.tertiary_100};
    }

    &:active {
      background-color: transparent;
    }
  }
`;

export const Results = styled.span`
  ${(props) => props.theme.config.typograph.xsmall}
  color: ${(props) => props.theme.config.colors.primary};
  display: block;
  margin-left: ${(props) => props.theme.config.spacing.spacing_3};

  ${(props) =>
    props.highlight &&
    css`
      background: ${(props) => props.theme.config.colors.primary_100};
      padding: 0.6rem 0.7rem;
      border-radius: 4px;
    `};
`;

export const HourLabel = styled.span`
  display: block;
  margin-top: ${(props) => props.theme.config.spacing.spacing_1};
  color: ${(props) => props.theme.config.colors.primary_400};
  ${(props) => props.theme.config.typograph.mico};
`;
