import styled from 'styled-components';

export const getBackgroundColorFromStatus = (props) => {
  switch (props.status) {
    case 'Pending':
      return props.theme.config.colors.gray_100;
    case 'Completed':
      return props.theme.config.colors.green_100;
    case 'Scheduled':
      return props.theme.config.colors.orange_100;
    case 'Canceled':
      return props.theme.config.colors.tertiary_100;
    case 'Skipped':
      return props.theme.config.colors.gray_100;
    case 'Partial':
      return props.theme.config.colors.primary_100;
    case 'Missed':
      return props.theme.config.colors.orange_100;
    default:
      return '';
  }
};

export const getColorFromStatus = (props) => {
  switch (props.status) {
    case 'Pending':
      return props.theme.config.colors.text_base;
    case 'Completed':
      return props.theme.config.colors.green;
    case 'Scheduled':
      return props.theme.config.colors.orange;
    case 'Canceled':
      return props.theme.config.colors.tertiary;
    case 'Skipped':
      return props.theme.config.colors.text_base;
    case 'Partial':
      return props.theme.config.colors.secondary;
    case 'Missed':
      return props.theme.config.colors.orange;
    default:
      return '';
  }
};

export const Label = styled.span`
  display: inline-block;
  width: 110px;
  height: 32px;
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;
  text-align: center;
  background: ${(props) => getBackgroundColorFromStatus(props)};
  color: ${(props) => getColorFromStatus(props)};
`;
