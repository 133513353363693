import styled from 'styled-components';
import { ZIndexes } from 'modules/Utils/ZIndexes';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 4.8rem;
  left: 0;
  top: 8rem;
  text-align: center;
  color: ${(props) => props.theme.config.colors.text_base};
  background: ${(props) => props.theme.config.colors.primary_100};
  border-top: 1px solid ${(props) => props.theme.config.colors.gray_200};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${ZIndexes.evaluatorBar};
`;

export const LoadingContainerContent = styled.div`
  background: ${(props) => props.theme.config.colors.light};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.strong`
  margin-left: 8px;
`;

export const Span = styled.span`
  margin-left: 4px;
`;
