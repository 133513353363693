import { useMemo } from 'react';
import { useFormattedDate } from 'modules/Core/Hooks';

/**
 * Custom hook to determine if the activation email is blocked.
 * @param {string} activationMailSentAt - Date when the activation email was sent in UTC string.
 */
export const useActivationEmailBlocked = (activationMailSentAt) => {
  const { formattedDate } = useFormattedDate({
    date: activationMailSentAt
      ? new Date(
          new Date(activationMailSentAt).getTime() + 5 * 60 * 1000
        ).toISOString()
      : null,
    dateTimeFormat: true,
  });

  const isActivationEmailBlocked = useMemo(() => {
    if (!activationMailSentAt) return false;

    const activationSentAtUTC = new Date(activationMailSentAt);

    if (isNaN(activationSentAtUTC)) {
      console.error(
        'Invalid date format for activationMailSentAt:',
        activationMailSentAt
      );
      return false;
    }

    const fiveMinutesAgoUTC = new Date(Date.now() - 5 * 60 * 1000);

    return activationSentAtUTC > fiveMinutesAgoUTC;
  }, [activationMailSentAt]);

  return {
    isActivationEmailBlocked,
    formattedDate: activationMailSentAt ? formattedDate : null,
  };
};
