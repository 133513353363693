import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: fit-content;
`;

export const hasItems = css`
  background: ${(props) => props.theme.config.colors.primary_200};
  border: none;
  color: ${(props) => props.theme.config.colors.primary};
`;

export const zeroItems = css`
  background: ${(props) => props.theme.config.colors.gray_100};
  border: 1px solid ${(props) => props.theme.config.colors.gray_200};
  color: ${(props) => props.theme.config.colors.text_base};
`;

export const TooltipItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 37px;
  border-radius: 4px;
  outline: none;
  margin: 0 auto;
  ${(props) => (props.hasItems ? hasItems : zeroItems)}
`;

export const Text = styled.p`
  ${(props) => props.theme.config.typograph.xsmall};

  & + p {
    margin-top: 1.6rem;
  }
`;
