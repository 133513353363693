import React, { useMemo, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Selectors as AuthSelectors } from 'modules/Auth/AuthSlice';
import {
  Switch,
  Label,
  FormGroup,
  Number,
  SelectAutocomplete,
} from 'modules/Core/Common';
import { getParsedMark } from 'modules/Submissions/Utils';
import i18n from 'i18next';

import * as S from './Comments.styles';

const CommentsContext = createContext();

const useCommentsContext = () => {
  const context = useContext(CommentsContext);

  if (!context) {
    throw new Error(i18n.t('modules.submissions.common.header.text-comments'));
  }

  return context;
};

const TextArea = () => {
  const { isEvaluator, isSuperAdmin, comments, onCommentsChange } =
    useCommentsContext();
  const { user } = useSelector(AuthSelectors.selectAuthData);

  return (
    <S.CommentsBlock
      block={!isEvaluator && !isSuperAdmin && !user?.can_evaluate}
    >
      <FormGroup>
        <Label>
          {i18n.t(
            'modules.submissions.common.grades-comments.items.comments.title'
          )}
          :
        </Label>
        <S.TextArea
          value={comments && comments !== 'null' ? comments : ''}
          onChange={({ target }) => onCommentsChange(target?.value)}
          placeholder={i18n.t(
            'modules.submissions.common.grades-comments.items.comments.no-comments'
          )}
          rows="4"
          readOnly={!isEvaluator && !isSuperAdmin && !user?.can_evaluate}
        />
      </FormGroup>
    </S.CommentsBlock>
  );
};

const Shields = () => {
  const { mark, onMarkChange, availableMarks } = useCommentsContext();

  const max = availableMarks?.length
    ? availableMarks[availableMarks.length - 1]
    : 5;

  return (
    <FormGroup>
      <Label>SHIELDS</Label>
      <Number
        id="mark"
        name="mark"
        type="number"
        max={max}
        onChange={onMarkChange}
        value={mark}
      />
    </FormGroup>
  );
};

const EmptyAnswer = () => {
  const { emptyAnswer, disableEmptyAnswers, onEmptyAnswersChange } =
    useCommentsContext();

  return (
    <FormGroup>
      <S.EmptyAnswerContainer>
        <Label style={{ display: 'block' }}>
          {i18n.t('errors.empty-answer')}
        </Label>
        <Switch
          id="empty_answers"
          name="empty_answers"
          active={emptyAnswer}
          disabled={disableEmptyAnswers}
          onChange={onEmptyAnswersChange}
        />
      </S.EmptyAnswerContainer>
    </FormGroup>
  );
};

const Mark = () => {
  const { mark, onMarkChange, availableMarks, loading } = useCommentsContext();

  const formattedMarks = availableMarks?.map((item) => ({
    name: item.toString(),
    id: item,
  }));

  return (
    <FormGroup>
      <Label htmlFor="mark">
        {i18n.t('modules.overall-scoring.table.columns.mark')}
      </Label>

      <S.MarkSelect>
        <SelectAutocomplete
          id="mark"
          borders
          loading={loading}
          placeholder={i18n.t('common-words.select')}
          searchPlaceholder={i18n.t('common-words.search')}
          options={formattedMarks}
          showAllOption={{
            label: 'Select',
            id: -1,
          }}
          value={[getParsedMark(mark)]}
          onChangeSelect={(id) => onMarkChange(id)}
        />
      </S.MarkSelect>
    </FormGroup>
  );
};

export const Comments = ({
  children,
  comments,
  emptyAnswer,
  availableMarks,
  mark,
  onMarkChange,
  loading,
  isEvaluator,
  isSuperAdmin,
  disableEmptyAnswers,
  onEmptyAnswersChange,
  onCommentsChange,
}) => {
  const values = useMemo(
    () => ({
      comments,
      emptyAnswer,
      availableMarks,
      mark,
      onMarkChange,
      loading,
      isEvaluator,
      isSuperAdmin,
      disableEmptyAnswers,
      onEmptyAnswersChange,
      onCommentsChange,
    }),
    [
      comments,
      emptyAnswer,
      availableMarks,
      mark,
      onMarkChange,
      loading,
      isEvaluator,
      isSuperAdmin,
      disableEmptyAnswers,
      onEmptyAnswersChange,
      onCommentsChange,
    ]
  );

  return (
    <CommentsContext.Provider value={values}>
      <S.CommentsContainer
        isEvaluator={isEvaluator}
        isSuperAdmin={isSuperAdmin}
      >
        {children}
      </S.CommentsContainer>
    </CommentsContext.Provider>
  );
};

Comments.TextArea = TextArea;
Comments.Shields = Shields;
Comments.EmptyAnswer = EmptyAnswer;
Comments.Mark = Mark;

Comments.defaultProps = {
  emptyAnswer: false,
};

Comments.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  comments: PropTypes.string,
  emptyAnswer: PropTypes.bool,
  availableMarks: PropTypes.array,
  mark: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  disableEmptyAnswers: PropTypes.bool,
  onMarkChange: PropTypes.func,
  loading: PropTypes.bool,
  isEvaluator: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  onEmptyAnswersChange: PropTypes.func,
  onCommentsChange: PropTypes.func,
};
