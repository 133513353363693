import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { HeaderList, ChangeEvaluatorModal } from 'modules/Core/Common';
import AnswersWritingContainer from 'modules/Submissions/Common/AnswersWriting/AnswersWriting.container';
import {
  GradesAndComments,
  SumsOverallAverages,
  ScreenArchives,
  VideoArchives,
  ModalNextAnswer,
  ModalEndEvaluation,
  EvaluatorBar,
  AnswerProgress,
  BackToListButton,
} from 'modules/Submissions/Common';
import { QuestionsHeader } from '../QuestionsHeader';
import { Actions } from 'modules/Submissions/SubmissionsSlice';
import { getGradesSubtitleByTestCategoryAndType } from 'modules/Submissions/Utils';
import { useAuth } from 'modules/Auth/Hooks';
import { capitalizeWord } from 'modules/Utils';
import { useSubmissionsRoutes } from 'modules/Submissions/Hooks';
import { SubmissionIcon } from '@edusynch/edusynch-svg-icons';
import * as S from '../SubmissionQuestions.styles';

const QuestionsWriting = ({
  answer,
  grades,
  photos = [],
  videos = [],
  onGradeSelect,
  student,
  evaluator,
  canSendToOtherEvaluator,
  evaluatorPosition,
  onCommentsChange,
  onEmptyAnswersChange,
  strengthsAndRecommendations,
  onChangeStrength,
  onChangeRecommendation,
  scoresByCategory: availableMarks,
  onMarkChange,
  onCancel,
  onSave,
  loadingSubmissionQuestions,
  loadingGradesAndComments,
  loading,
  error,
  testType,
  isEvaluator,
  isSuperAdmin,
  scheduleLinkEvaluator,
  isModalNextAnswerOpen,
  setIsModalNextAnswerOpen,
  handleNextQuestion,
  isModalEndEvaluationOpen,
  setIsModalEndEvaluationOpen,
  handleNextEvaluation,
  handlePreviousEvaluation,
  nextExercise,
  previousExercise,
  exerciseId,
  changeEvaluatorObj,
  isChangeEvaluatorModalOpen,
  setIsChangeEvaluatorModalOpen,
  handleOpenChangeEvaluatorModal,
  handleChangeEvaluator,
  handleUpdateEvaluator,
  autoChangeEvaluator,
  answerPosition,
  totalAnswersCompleted,
  totalAnswers,
  ...props
}) => {
  const {
    authData: { user: loggedUser },
  } = useAuth();

  const { backToSubmissionsListByTestType } = useSubmissionsRoutes({
    testType,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [answer?.id]);

  return (
    <>
      <AnswerProgress
        answerPosition={answerPosition}
        totalAnswersCompleted={totalAnswersCompleted}
        totalAnswers={totalAnswers}
      />
      <S.Container {...props}>
        {isEvaluator && answer?.minimumEvaluation > 1 ? (
          <EvaluatorBar
            evaluatorName={loggedUser?.name}
            evaluatorAvatar={loggedUser?.avatar?.thumb?.url}
            evaluatorPosition={answer?.evaluatorPosition}
          />
        ) : null}
        <HeaderList
          error={error}
          title={i18n.t(
            'modules.submissions.common.header.titles.show-answers'
          )}
          subtitle={`${capitalizeWord(testType)} Tests`}
          Icon={SubmissionIcon}
          actionsType="custom"
          actions={
            <>
              <BackToListButton
                path={`${
                  window?.location?.pathname?.split('answers')?.[0]
                }answers`}
                noReplace
              />
            </>
          }
          alignRight
          onFilterClick={Actions.changeSubmissionsFilters}
        >
          <QuestionsHeader
            loading={loadingSubmissionQuestions}
            student={student}
            evaluator={evaluator}
            id={exerciseId}
            date={answer?.updated_at}
            category={answer?.category_tag}
            status={answer?.evaluated}
            duration={answer?.duration}
            gradingRubric={answer?.grading_rubric}
          />

          {testType === 'custom' && !isEvaluator && (
            <SumsOverallAverages
              sumsOverall={answer?.custom_marks_sum}
              averageOverall={answer?.custom_marks_average}
              averages={answer?.marks}
              loading={loadingSubmissionQuestions}
              interviewLink={scheduleLinkEvaluator}
            />
          )}

          <AnswersWritingContainer
            loading={loadingSubmissionQuestions}
            answers={answer || {}}
          />
          <GradesAndComments
            testType={answer?.test_type?.toLowerCase()}
            gradesSubtitle={getGradesSubtitleByTestCategoryAndType(
              answer?.category_tag,
              answer?.test_type
            )}
            loading={loadingSubmissionQuestions}
            loadingGradesAndComments={loading || loadingGradesAndComments}
            isEvaluator={isEvaluator}
            isSuperAdmin={isSuperAdmin}
            grades={grades}
            onGradeSelect={onGradeSelect}
            strengthsAndRecommendations={strengthsAndRecommendations}
            onChangeStrength={onChangeStrength}
            onChangeRecommendation={onChangeRecommendation}
            marks={answer?.marks}
            emptyAnswer={answer?.empty_answers}
            onEmptyAnswersChange={onEmptyAnswersChange}
            availableMarks={availableMarks}
            onMarkChange={onMarkChange}
            comments={answer?.comments}
            onCommentsChange={onCommentsChange}
            onCancel={onCancel}
            onSave={onSave}
          />
          <ScreenArchives
            loading={loadingSubmissionQuestions}
            photos={photos}
          />
          <VideoArchives loading={loadingSubmissionQuestions} videos={videos} />
        </HeaderList>
        <ModalNextAnswer
          isOpen={isModalNextAnswerOpen}
          onClose={() => setIsModalNextAnswerOpen(false)}
          onNextQuestion={handleNextQuestion}
        />
        <ModalEndEvaluation
          isOpen={isModalEndEvaluationOpen}
          onClose={() => setIsModalEndEvaluationOpen(false)}
          onNextEvaluation={handleNextEvaluation}
          onPreviousEvaluation={handlePreviousEvaluation}
          testType={testType}
          onChangeEvaluator={() => {
            setIsModalEndEvaluationOpen(false);
            !isEvaluator
              ? handleOpenChangeEvaluatorModal({
                  id: exerciseId,
                  evaluatorId: evaluator?.id,
                })
              : autoChangeEvaluator(exerciseId, () => {
                  backToSubmissionsListByTestType({ testType });
                });
          }}
          nextExercise={nextExercise?.data}
          loading={nextExercise?.loading}
          previousExercise={previousExercise}
          canSendToOtherEvaluator={canSendToOtherEvaluator}
          evaluatorPosition={evaluatorPosition}
        />
        <ChangeEvaluatorModal
          isOpen={isChangeEvaluatorModalOpen}
          onRequestClose={() => setIsChangeEvaluatorModalOpen(false)}
          evaluatorId={changeEvaluatorObj?.evaluatorId}
          shouldFetchEvaluators
          loading={loading}
          onSubmit={handleChangeEvaluator}
          onSubmitCallback={(args) => {
            backToSubmissionsListByTestType({ testType });
            handleUpdateEvaluator(args);
          }}
          canRandom
        />
      </S.Container>
    </>
  );
};

QuestionsWriting.propTypes = {
  answer: PropTypes.any,
  grades: PropTypes.object,
  photos: PropTypes.array,
  videos: PropTypes.array,
  onGradeSelect: PropTypes.func,
  student: PropTypes.object,
  evaluator: PropTypes.object,
  canSendToOtherEvaluator: PropTypes.bool,
  evaluatorPosition: PropTypes.number,
  onCommentsChange: PropTypes.func,
  onEmptyAnswersChange: PropTypes.func,
  strengthsAndRecommendations: PropTypes.object,
  onChangeStrength: PropTypes.func,
  onChangeRecommendation: PropTypes.func,
  scoresByCategory: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onMarkChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  loadingSubmissionQuestions: PropTypes.bool,
  loadingGradesAndComments: PropTypes.bool,
  error: PropTypes.string,
  testType: PropTypes.string,
  isEvaluator: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  scheduleLinkEvaluator: PropTypes.string,
  isModalNextAnswerOpen: PropTypes.bool,
  setIsModalNextAnswerOpen: PropTypes.func,
  handleNextQuestion: PropTypes.func,
  isModalEndEvaluationOpen: PropTypes.bool,
  setIsModalEndEvaluationOpen: PropTypes.func,
  handleNextEvaluation: PropTypes.func,
  handlePreviousEvaluation: PropTypes.func,
  nextExercise: PropTypes.any,
  previousExercise: PropTypes.any,
  goToSubmissionsList: PropTypes.func,
  exerciseId: PropTypes.any,
  changeEvaluatorObj: PropTypes.object,
  isChangeEvaluatorModalOpen: PropTypes.bool,
  setIsChangeEvaluatorModalOpen: PropTypes.func,
  handleOpenChangeEvaluatorModal: PropTypes.func,
  handleChangeEvaluator: PropTypes.func,
  handleUpdateEvaluator: PropTypes.func,
  autoChangeEvaluator: PropTypes.func,
  answerPosition: PropTypes.number,
  totalAnswersCompleted: PropTypes.number,
  totalAnswers: PropTypes.number,
};

export default QuestionsWriting;
