import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'modules/Core/Common';
import { UserAvatar } from 'modules/Users/Common';
import { capitalizeWord } from 'modules/Utils';
import { getExerciseStatus } from 'modules/Submissions/Utils';
import { format } from 'modules/Utils/Date';
import { useAuth } from 'modules/Auth/Hooks';
import i18n from 'i18next';

import * as S from './StudentCard.styles';

export const StudentCard = ({ exercise, isNext, onButtonClick }) => {
  const { userTimeFormatSeconds, userDateFormat } = useAuth();

  if (!exercise) {
    return (
      <S.StudentCard>
        <S.Empty>
          <div>
            <Icon name="student" />
          </div>
          {i18n.t('modules.submissions.common.modal.card.more-students')}
        </S.Empty>
      </S.StudentCard>
    );
  }

  if (!exercise) {
    return (
      <S.StudentCard>
        <S.Empty>
          <div>
            <Icon name="student" />
          </div>
          {i18n.t('modules.submissions.common.modal.card.more-students')}
        </S.Empty>
      </S.StudentCard>
    );
  }

  if (!exercise) {
    return (
      <S.StudentCard>
        <S.Empty>
          <div>
            <Icon name="student" />
          </div>
          {i18n.t('modules.submissions.common.modal.card.more-students')}
        </S.Empty>
      </S.StudentCard>
    );
  }

  return (
    <S.StudentCard onClick={onButtonClick}>
      <S.StudentAvatar>
        <UserAvatar
          name={exercise?.student?.name}
          email={exercise?.student?.email}
          avatar={exercise?.student?.avatar?.url}
        >
          <UserAvatar.Photo />
          <UserAvatar.Name />
          <UserAvatar.Card />
        </UserAvatar>
      </S.StudentAvatar>
      <S.StudentCardBody>
        <div>
          <S.Category>
            <h6>{i18n.t('modules.submissions.common.modal.card.category')}</h6>
            <p>{capitalizeWord(exercise?.category_tag || '-')}</p>
          </S.Category>
          <S.Evaluated>
            <h6>{i18n.t('modules.submissions.common.modal.card.evaluated')}</h6>
            <S.EvaluatedLabel
              status={getExerciseStatus(exercise?.status_exercise)}
            >
              {getExerciseStatus(exercise?.status_exercise)}
              <Icon
                fontSize="0.8rem"
                name={exercise?.status_exercise === 0 ? 'pending' : 'check'}
                right
              />
            </S.EvaluatedLabel>
          </S.Evaluated>
        </div>
        <S.Date>
          <h6>{i18n.t('modules.submissions.common.modal.card.date')}</h6>
          {exercise?.created_at ? (
            <p>
              {format(new Date(exercise?.created_at), userDateFormat)}&nbsp;
              <span>
                {format(new Date(exercise?.created_at), userTimeFormatSeconds)}
              </span>
            </p>
          ) : (
            <p>-</p>
          )}
        </S.Date>
      </S.StudentCardBody>
      <S.Separator />
      <button type="button" disabled={!exercise}>
        {isNext ? (
          <>
            {i18n.t('modules.submissions.common.modal.card.next')}
            <Icon fontSize="1.2rem" name="chevron-right" right />
          </>
        ) : (
          <>
            <Icon fontSize="1.2rem" name="chevron-left" left />
            {i18n.t('modules.submissions.common.modal.card.previous')}
          </>
        )}
      </button>
    </S.StudentCard>
  );
};

StudentCard.propTypes = {
  exercise: PropTypes.object,
  isNext: PropTypes.bool,
  onButtonClick: PropTypes.func,
};
