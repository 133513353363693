import React from 'react';

import { Comments } from '../Comments.component';

export const CommentsCambridge = ({ ...props }) => {
  return (
    <Comments {...props}>
      <Comments.Shields />
      <Comments.EmptyAnswer />
      <Comments.TextArea />
    </Comments>
  );
};
