import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { HeaderInfo } from 'modules/Core/Common';

export function AnswersHeader({
  loading,
  testType,
  student,
  id,
  date,
  dateLabel,
  deviceInfo,
  scores,
  categories,
  selectedCategory,
  onChangeCategory,
  isCustomTest,
  children,
}) {
  return (
    <Box
      sx={{
        mb: 3,
      }}
    >
      <HeaderInfo
        isLoading={loading}
        testType={testType}
        student={student}
        id={id}
        date={date}
        dateLabel={dateLabel}
        deviceInfo={deviceInfo}
        scoreInfo={scores}
        categories={categories}
        selectedCategory={selectedCategory}
        onChangeCategory={onChangeCategory}
        isCustomTest={isCustomTest}
      >
        {children}
      </HeaderInfo>
    </Box>
  );
}

AnswersHeader.propTypes = {
  loading: PropTypes.bool,
  testType: PropTypes.string,
  student: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.string,
  dateLabel: PropTypes.string,
  deviceInfo: PropTypes.object,
  scores: PropTypes.array,
  categories: PropTypes.array,
  selectedCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeCategory: PropTypes.func,
  isCustomTest: PropTypes.bool,
  children: PropTypes.node,
};
