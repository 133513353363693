import styled from 'styled-components';
import { device } from 'modules/Utils/Devices';

export const Container = styled.div`
  margin-top: 5rem;
  z-index: 0;

  @media ${device.mobile} {
    margin-top: 9.9rem;
  }

  .sums-overall-averages {
    margin-bottom: 4rem;
  }
`;
