import React from 'react';
import PropTypes from 'prop-types';
import * as S from '../../AnswerSheet.styles';
import { addKeyOnInitial } from '../QuestionTypes.utils';
import { getAlternativeByCondition } from './QuestionTypes.base';

const ChooseParagraph = ({ alternatives }) => {
  return alternatives?.map((alternative, index) => (
    <S.AlternativeContainer
      key={alternative.alternative_id}
      right={alternative.right}
      wrong={!alternative.right}
    >
      {getAlternativeByCondition(alternative, index)}
      <S.AlternativeText
        dangerouslySetInnerHTML={{
          __html: addKeyOnInitial(
            alternative?.right_answer?.paragraph_text,
            alternative?.right_answer?.letter
          ),
        }}
      />
    </S.AlternativeContainer>
  ));
};

ChooseParagraph.propTypes = {
  alternatives: PropTypes.array,
};

export default ChooseParagraph;
